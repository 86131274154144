import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import MenuItemImg from "../MenuItem/components/MenuItemImg";
import SearchIcon from '@mui/icons-material/Search';
import { setSelectedProd } from "store/searchProdSlice";
import { setOpenSearch, setSelectedBadge } from "store/searchProdSlice";
import CloseIcon from '@mui/icons-material/Close';
import {
    Autocomplete,
    Box,
    Card,
    CardContent,
    IconButton,
    Slide,
    Stack,
    TextField,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material"

const SearchProd = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    //const menuData = useSelector(state => state.menu);
    const categories = useSelector(state => state.menu.activeCats);
    const selectedLang = useSelector(state => state.userSettings.selectedLang);
    const selectedBadge = useSelector(state => state.searchProd.selectedBadge);
    const openSearch = useSelector(state => state.searchProd.openSearch);
    const bizData = useSelector(state => state.bizData);
    const [prodList, setProdList] = useState([])
    const [temp, setTemp] = useState([])
    const [filterData, setFilterData] = useState([])
    const [badgesList, setBadgesList] = useState([])
    const searchRef = useRef(null)

    useEffect(() => {
        dispatch(setOpenSearch(isMobile ? false : true))
    }, []);

    useEffect(() => {
        if (categories) {
            let list = []
            categories.map(cat => {
                cat?.items?.map(item => {
                    if (!list.includes(item.id))
                        list.push(item)
                })
            })

            if (list.length > 0) {
                setProdList(list)
            }
        }
    }, [categories]);

    useEffect(() => {
        if (prodList.length > 0) {
            let list = []
            prodList?.map(prod => {
                prod?.badges?.map(badge => {
                    const findBadge = list?.find(b => b.name === badge.name)
                    if (!findBadge)
                        list.push(badge)
                })
            })

            if (list.length > 0) {
                setBadgesList(list)
            }
        }
    }, [prodList]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setFilterData(temp)
        }, 500);
        return () => clearTimeout(timer);
    }, [temp])

    const filterProds = (e) => {
        const value = e?.target?.value
        if (e?.code === "Enter") {
            return
        }

        else if (value) {
            if (value === '') {
                setTemp([])
            }
            else {
                let filterList = selectedBadge.length > 0
                    ? prodList.filter(prod => checkBadges(prod.badges))
                    : [...prodList]

                setTemp(filterList.filter((val) => val.name?.toLowerCase().includes(value?.toLowerCase())))
            }
        }
    }

    const selectedProd = (value) => {
        dispatch(setSelectedProd(value))
    }

    const selectedBadges = (value) => {
        dispatch(setSelectedBadge(value))
    }

    const checkBadges = (arr) => {
        const names = arr?.map(obj => obj.name);
        const badges = selectedBadge?.map(obj => obj.name);
        return badges?.every(text => names.includes(text));
    }

    return (
        <>
            <div className="sticky w-12 h-0 top-96 z-50 lg:hidden">
                {!openSearch &&
                    <Stack
                        justifyContent='center'
                        alignItems='center'
                        border={`1px solid ${theme.palette.primary.main}`}
                        bgcolor='secondary.main'>
                        <IconButton onClick={() => dispatch(setOpenSearch(true))}>
                            <SearchIcon />
                        </IconButton>
                    </Stack>
                }
            </div>

            <Slide
                direction={selectedLang.isRtl ? "left" : "right"}
                in={openSearch}
                mountOnEnter
                unmountOnExit>

                <Card
                    bgcolor='secondary.main'
                    sx={{
                        width: "100%",
                        top: 0,
                        zIndex: 2,
                        marginBottom: 1,
                    }}>

                    <CardContent sx={{ paddingY: 1 }}>
                        <Stack spacing={1}>

                            <div className="flex justify-between items-center lg:hidden">
                                <Typography>
                                    {t('SEARCH')}
                                </Typography>
                                <IconButton onClick={() => dispatch(setOpenSearch(false))}>
                                    <CloseIcon />
                                </IconButton>
                            </div>

                            {/* input For Search Prod  */}
                            <Autocomplete
                                disablePortal
                                options={filterData}
                                getOptionLabel={(opt) => opt.name}
                                fullWidth
                                size={isMobile ? 'small' : 'medium'}
                                freeSolo
                                onChange={(e, val) => selectedProd(val)}
                                renderOption={(props, option) => {
                                    return (
                                        < Stack {...props} direction='row' gap={2}>
                                            <MenuItemImg
                                                size='4rem'
                                                hasImgs={bizData?.hasImgs}
                                                imgLogo={bizData?.imgLogo}
                                                imgUrl={option.imgUrl}
                                            />
                                            <Typography
                                                fontWeight='bold'
                                                variant="subtitle1"
                                                className="flex gap-1 -mt-2">
                                                {option?.name}
                                            </Typography>
                                        </Stack>
                                    )
                                }}
                                renderInput={(params) => {
                                    return (
                                        <TextField
                                            {...params}
                                            label={t('SEARCH')}
                                            ref={searchRef}
                                            inputProps={{
                                                ...params.inputProps,
                                                onKeyDown: (e) => {
                                                    if (e.key === 'Enter') {
                                                        e.stopPropagation();
                                                    }
                                                },
                                            }}
                                            onKeyUp={(e) => filterProds(e)}
                                        />
                                    )
                                }}
                            />

                            {/* input For Search badges  */}
                            {badgesList.length > 0 &&
                                <Autocomplete
                                    disablePortal
                                    multiple
                                    getOptionLabel={(opt) => opt.name}
                                    size="small"
                                    options={badgesList}
                                    fullWidth
                                    onChange={(e, val) => selectedBadges(val)}
                                    renderOption={(props, option) => {
                                        return (
                                            <Stack {...props} direction='row' gap={2}>
                                                <Box
                                                    sx={[{
                                                        '& svg': { fill: (theme) => theme.palette.primary.main },
                                                    },]}
                                                    dangerouslySetInnerHTML={{ __html: option.imgUrl }} />
                                                <Typography
                                                    fontWeight='bold'
                                                    variant="subtitle1"
                                                    className="flex gap-1 -mt-2">
                                                    {option?.name}
                                                </Typography>
                                            </Stack>
                                        )
                                    }}
                                    renderInput={(params) => <TextField
                                        ref={searchRef}
                                        label={selectedBadge.length === 0 ? t('FILTER_BY') : ''}
                                        {...params}
                                    />}
                                />
                            }

                        </Stack>
                    </CardContent>
                </Card >
            </Slide>

        </>

    )
}

export default SearchProd