import React, { useEffect, useState } from 'react';
import { useTheme } from '@emotion/react';
import { useSelector } from 'react-redux';
import { Box, Button, Typography } from '@mui/material';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Layout from 'containers/Layout';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Loading from 'components/utils/Loading';

const MenusList = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const { guid } = useParams();
    const navigate = useNavigate();
    const selectedLang = useSelector(state => state.userSettings.selectedLang);
    const bizData = useSelector(state => state.bizData);
    const [menuList, setMenuList] = useState([]);

    /* If we come from the menu, we scroll up so we can see the list.
       Todo: try to get rid of that, it should be a simple page without scrolling area */
    // useEffect(() => {
    //     window.scrollTo(0, 0);
    // }, []);

    /* If biz have only one menu and not timing, redirect to it automatically */
    useEffect(() => {
        if (bizData?.menus?.length === 1) {
            const isOpen = checkMenuTiming(bizData?.menus[0])

            if (isOpen) {
                navigate(`/${guid}/${bizData?.menus[0]?.id}`)
            }
        }
    }, [bizData]);

    useEffect(() => {
        getMenuList()
    }, [bizData?.menus]);

    const getMenuList = () => {
        // if (bizData?.menus.length === 1) {
        //     navigate(`/${guid}/${bizData?.menus[0]?.id}`)
        // }
        // else {
        if (bizData?.menus?.length > 0) {
            let newList = []
            bizData?.menus?.map(menu => {
                if (checkMenuTiming(menu)) {
                    // let test = checkMenuTiming(menu)
                    newList.push(menu)
                }
            })

            setMenuList(newList)
        }
    }

    /* Todo: move this to redux, jsut like the cateroies */
    const checkMenuTiming = (menu) => {
        let timeNow = new Date().getTime();
        let today = moment(new Date()).day() + 1;
        let isOpen = true;

        if (menu.timing?.length > 0) {
            //if today is not in timing arr=>biz closed
            if (!menu?.timing?.map(timing => timing?.dow)?.includes(today))
                isOpen = false

            else {
                for (let j = 0; j < menu.timing.length; j++) {
                    // if (!isOpen) {
                    if (menu?.timing[j]?.dow === today) {
                        isOpen = timeNow >= new Date(menu.timing[j]?.ftm).getTime() && timeNow <= new Date(menu.timing[j]?.ttm).getTime();
                        if (isOpen)
                            break;
                    }
                }
            }
        }
        return isOpen
    }

    if (menuList.length === 0) {
        <Loading />
    }

    return (
        <Layout>
            <div className='pt-4'>
                <Box
                    className='mx-auto max-w-lg w-full self-center p-4'
                    bgcolor="secondary.main"
                >
                    {menuList?.length > 0 &&
                        <Typography variant='h5' color='primary' className='text-center mb-4'>
                            {t('CHOOSE_MENU')}
                        </Typography>
                    }
                    <div className='flex flex-col space-y-4'>
                        {/* {bizData?.menus?.length > 0 */}
                        {menuList?.length > 0
                            // ? bizData?.menus?.map((menu) => {
                            ? menuList?.map((menu) => {
                                return (
                                    // checkMenuTiming(menu) &&
                                    <Link key={menu.id} to={`/${guid}/${menu.id}`}>
                                        <Button
                                            variant='contained'
                                            className='w-full py-3'
                                        >
                                            <Typography>
                                                {menu.langs.find(menuLang => menuLang.id === selectedLang.id)?.name}
                                            </Typography>
                                        </Button>
                                    </Link>
                                )
                            })
                            : <Typography className='text-center' variant='h5' color='primary'>
                                {t("NO_MENUS")}
                            </Typography>}
                    </div>
                </Box>
            </div>
        </Layout>
    );
}

export default MenusList;