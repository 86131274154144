import { useSelector } from 'react-redux';
import AppMenu from './AppMenu/Index';
import { Box, Typography } from '@mui/material';
import CartMobile from './Cart/CartMobile';
import { useTheme } from '@emotion/react';
import NotificationBar from './utils/NotificationBar'
import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setBizCloseTime } from 'redux/actions';
import { setHeaderHeight } from 'store/appSettingsSlice';
import { setHideTestBizAlert } from 'store/userSettingsSlice';
import moment from 'moment';
import LanguageButton from './utils/LanguageButton';

const Header = ({ setPreOrderStep, children }) => {
    let theme = useTheme();
    let dispatch = useDispatch();
    const headerDiv = useRef(null);
    const { t } = useTranslation();
    const bizData = useSelector(state => state.bizData);
    const selectedLang = useSelector(state => state.userSettings.selectedLang);
    const hideTestBizAlert = useSelector(state => state.userSettings.hideTestBizAlert);
    const hideMinPrAlert = useSelector(state => state.userSettings.hideMinPrAlert);
    // const menuWithOrder = useSelector(state => state.menu.canOrder);
    const bizCloseTime = useSelector(state => state?.bizData?.closeTime);
    const appSettings = useSelector(state => state.appSettings);
    const bizOpenInMinute = useSelector(state => state?.bizData?.nextOpenTime);

    // Recalculate header height if any notification visibility has changed
    useEffect(() => {
        dispatch(setHeaderHeight(headerDiv?.current?.clientHeight))
    }, [
        headerDiv.current?.clientHeight,
        hideTestBizAlert,
        hideMinPrAlert,
        bizCloseTime,
        children
    ])

    return (
        <>
            <Box
                ref={headerDiv}
                bgcolor='secondary.main'
                className='sticky flex flex-col items-center top-0 left-0 right-0 z-50 shadow-sm'
            >
                {!appSettings?.isIframe &&
                    <div className='w-full h-14 grid grid-cols-3 items-center px-4'>

                        <div className='flex gap-2'>
                            <AppMenu setPreOrderStep={setPreOrderStep} />
                        </div>

                        <Typography color="primary" className='text-center text-xl'>
                            {selectedLang?.bizName}
                        </Typography>

                        <div className='flex justify-end'>
                            <LanguageButton />
                        </div>
                    </div>
                }

                {bizData?.isForTest && !hideTestBizAlert &&
                    <NotificationBar color="info" onClose={() => dispatch(setHideTestBizAlert())}>
                        {t('TEST_BIZ_DESC')}
                    </NotificationBar>
                }

                {!appSettings?.isIframe && bizCloseTime > 0 && bizCloseTime <= 5 &&
                    <NotificationBar >
                        <Typography className='text-lg lg:text-xl'>
                            {`${t('BIZ_WILL_CLOSE_IN')} ${bizCloseTime} ${t('MINUTES')}`}
                        </Typography>
                    </NotificationBar>
                }

                {!appSettings?.isIframe && bizOpenInMinute > 0 && bizOpenInMinute <= 5 &&
                    <NotificationBar color={"info"} >
                        <Typography className='text-lg lg:text-xl'>
                            {`${t('BIZ_WILL_OPEN_IN')} ${bizOpenInMinute} ${t('MINUTES')}`}
                        </Typography>
                    </NotificationBar>
                }
                {children}
            </Box>
        </>
    )
}

export default Header;