import Loading from 'components/utils/Loading'
import { useForm, Controller } from "react-hook-form";
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axiosConn';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import { v4 as uuidv4 } from 'uuid';
import InfoModal from "components/utils/CustomModal";
import StepHeader from '../StepHeader';
import { STEPS } from "..";
import { setOrder } from 'store/userSettingsSlice';
import { ORDER_TYPE_ID } from "configs/consts";
import {
    TextField,
    Button,
    Typography,
    Box,
    Fade,
    Icon,
    IconButton,
    Stack
} from "@mui/material";

const AddressDetails = ({ setOpenAddr, isOrderTypeChange }) => {
    let dispatch = useDispatch();
    let theme = useTheme();
    let { t } = useTranslation();
    const sessiontoken = uuidv4();
    const { formState: { errors }, handleSubmit, control, setError, clearErrors, reset } = useForm();
    const bizData = useSelector(state => state.bizData);
    const order = useSelector(state => state.userSettings.order);
    const isBizOpen = useSelector(state => state?.bizData?.isOpen);
    const [selectedAddress, setSelectedAddress] = useState(null);
    const [errAddrMsg, setErrAddrMsg] = useState(null);
    const [loading, setLoading] = useState(false);
    const [addrData, setAddrData] = useState()
    const [addrInput, setAddrInput] = useState('');
    const [addrList, setAddrList] = useState([])
    const [isBiz, setIsBiz] = useState(order?.address?.isBiz ?? false)

    useEffect(() => {
        if (order?.address?.fullAddr)
            setAddrInput(order?.address?.fullAddr)
        setSelectedAddress({
            addr: order?.address?.fullAddr,
            placeId: order?.address?.placeId
        })
    }, [order?.address]);

    /* The user selected an address from the list */
    useEffect(() => {
        if (selectedAddress?.placeId) {
            /* Get data for the selected address */
            setLoading(true)
            setAddrList()
            axios.get(`CheckAddr/${bizData?.id}/${selectedAddress.placeId}/${sessiontoken}`)
                .then(res => {
                    setLoading(false);
                    if (res?.data?.id === 1) {
                        setAddrInput(selectedAddress.addr)
                        setAddrData({
                            ...res?.data?.data,
                            sessiontoken
                        })
                    }
                    else
                        setErrAddrMsg(t('ERR_ADDR'))
                })
                .catch(error => {
                    setLoading(false);
                    setError("addr", {
                        type: "manual",
                        message: t('NO_DELIVERY'),
                    })
                })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAddress])

    useEffect(() => {
        if (!selectedAddress?.placeId) {
            /* Limit request - wait 1 sec every time the user
                start typing before checking tghe input */
            const rquestLimit = setTimeout(checkAddrInput, 1000);
            return () => clearTimeout(rquestLimit);
        }
    }, [addrInput, isBiz]);

    const selectAddHandle = (e) => {
        if (e.target.value.length === 0)
            setSelectedAddress(null)

        setAddrInput(e.target.value)
    };

    /* Get address from server when the user start typing */
    const checkAddrInput = () => {
        setAddrList([])
        if (!addrInput?.length || addrInput?.length === 0)
            return;

        if (addrInput?.length < 2) {
            setAddrList()
            setError("addr", {
                type: "manual",
                message: t('AT_LEAST_3_CHAR'),
            })
            return;
        }

        /* Check if an address number is missing for the selected address */
        if (!/\d/.test(addrInput) && !isBiz) {
            setError("addr", {
                type: "manual",
                message: t('NO_DELIVERY_NUMBER'),
            })
        }

        else {
            if (addrList?.length === undefined || addrList?.find(item => item.addr === addrInput) === undefined) {
                clearErrors('addr')
                setLoading(true)
                axios.get(`GetAddr/${addrInput}/${sessiontoken}/${isBiz}`).then(res => {
                    if (res.data.data.length > 0) {
                        setAddrList(res.data.data)
                    }
                    else {
                        setError("addr", {
                            type: "manual",
                            message: t('NO_DATA_ADDR'),
                        })
                    }
                    setLoading(false);
                })
                    .catch(error => {
                        setLoading(false);
                    });
            }
            else
                setAddrList()
        }
    }

    const handleDeleteAddr = () => {
        setAddrInput('')
        setSelectedAddress(null)
        setAddrList()
        reset({ apt: "", floor: "", entry: "", addrNote: "", })
    }

    const onSubmit = (values) => {
        if (!selectedAddress || !selectedAddress?.placeId || !selectedAddress?.addr) {
            setError("addr", {
                type: "manual",
                message: t('REQ_ERR_ADDR'),
            })

            return;
        }

        if (setOpenAddr) {
            dispatch(
                setOrder({
                    step: STEPS.MENU_LIST,
                    typeId: ORDER_TYPE_ID.DELIVERY,
                    address: {
                        ...values,
                        ...addrData,
                        placeId: selectedAddress?.placeId,
                        sessiontoken: sessiontoken,
                        isBiz: isBiz,
                    },
                })
            )
            setOpenAddr(false)
        }
        else {
            dispatch(
                bizData?.orderSettings?.futureOrderInDays
                    ? setOrder({
                        step: !isBizOpen ? STEPS.FUTURE_ORDER : STEPS.ORDER_TIME,
                        address: {
                            ...values,
                            ...addrData,
                            placeId: selectedAddress?.placeId,
                            sessiontoken: sessiontoken,
                            isBiz: isBiz,
                        },
                    })
                    : setOrder({
                        step: STEPS.MENU_LIST,
                        address: {
                            ...values,
                            ...addrData,
                            placeId: selectedAddress?.placeId,
                            sessiontoken: sessiontoken,
                            isBiz: isBiz,
                        },
                    })
            )
        }

    }

    return (
        <>
            {!isOrderTypeChange &&
                <StepHeader
                    backBtnClick={() => dispatch(setOrder({ step: STEPS.ORDER_TYPE }))}
                    title={t('DELIVERY')}
                    subTitle={t('ADR_DETAILS')}
                />
            }
            <Fade timeout={700} in={true}>
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className='flex flex-col justify-between w-full mt-2'>
                    <div className="space-y-2">
                        <Stack direction='row' paddingY={1} spacing={1}>
                            <Button
                                size="small"
                                variant={!isBiz ? "contained" : "outlined"}
                                onClick={() => setIsBiz(false)}>
                                {t('ADDR')}
                            </Button>

                            <Button
                                size="small"
                                variant={isBiz ? "contained" : "outlined"}
                                onClick={() => setIsBiz(true)}>
                                {t('BIZ')}
                            </Button>

                        </Stack>

                        <TextField
                            fullWidth
                            autoComplete='off'
                            label={isBiz ? t('NAME_BIZ') : t('ADDR')}
                            onChange={selectAddHandle}
                            defaultValue={selectedAddress?.addr}
                            value={addrInput}
                            sx={{ input: { color: 'primary.main' } }}
                            InputProps={{
                                endAdornment: isOrderTypeChange && selectedAddress?.addr
                                    ? <IconButton onClick={handleDeleteAddr}>
                                        <Icon
                                            fontSize="large"
                                            color='clButtons'>
                                            delete_outlined
                                        </Icon>
                                    </IconButton>
                                    : null
                            }}
                        />
                        {errors.addr && <p className='text-red-600 text-sm font-semibold mt-2'>{errors.addr.message}</p>}

                        {/* Address List */}
                        <Box bgcolor="secondary.main">
                            {addrList?.map((addr) => {
                                return (
                                    <Typography
                                        key={addr.placeId}
                                        color="primary"
                                        sx={{ borderColor: "primary.main" }}
                                        className='flex justify-between border-b p-2 cursor-pointer'
                                        onClick={() => setSelectedAddress(addr)}
                                    >
                                        <span>{addr.addr}</span>
                                        <ArrowCircleLeftIcon />
                                    </Typography>
                                )
                            })}
                        </Box>

                        {selectedAddress?.placeId && selectedAddress?.addr &&
                            <>
                                <Controller
                                    name="apt"
                                    control={control}
                                    defaultValue={order?.address?.apt ?? ""}
                                    render={({ field }) =>
                                        <TextField
                                            {...field}
                                            type="number"
                                            variant="outlined"
                                            label={t('APPT')}
                                            sx={{ mt: 2, input: { color: 'primary.main' } }}
                                            fullWidth
                                            error={errors.apt !== undefined}
                                        />
                                    }
                                />
                                {errors.apt && <p className='text-red-600 text-sm font-semibold mt-2'>{errors.apt.message}</p>}

                                <Controller
                                    name="floor"
                                    defaultValue={order?.address?.floor ?? ""}
                                    control={control}
                                    render={({ field }) =>
                                        <TextField
                                            {...field}
                                            type="number"
                                            variant="outlined"
                                            label={t('FLOOR')}
                                            sx={{ mt: 2, input: { color: 'primary.main' } }}
                                            fullWidth
                                            error={errors.floor !== undefined}
                                        />
                                    }
                                />
                                {errors.floor && <p className='text-red-600 text-sm font-semibold mt-2'>{errors.floor.message}</p>}

                                <Controller
                                    name="entry"
                                    defaultValue={order?.address?.entry ?? ""}
                                    control={control}
                                    render={({ field }) =>
                                        <TextField
                                            {...field}
                                            variant="outlined"
                                            label={t('ENTRY')}
                                            sx={{ mt: 2, input: { color: 'primary.main' } }}
                                            fullWidth
                                            error={errors.entry !== undefined}
                                        />
                                    }
                                />
                                {errors.entry && <p className='text-red-600 text-sm font-semibold mt-2'>{errors.entry.message}</p>}

                                <Controller
                                    name="addrNote"
                                    defaultValue={order?.address?.addrNote ?? ""}
                                    control={control}
                                    render={({ field }) =>
                                        <TextField
                                            {...field}
                                            variant="outlined"
                                            label={t('ADDR_NOTES')}
                                            sx={{ mt: 2, input: { color: 'primary.main' } }}
                                            fullWidth
                                            error={errors.addrNote !== undefined}
                                        />
                                    }
                                />
                                {errors.addrNote && <p className='text-red-600 text-sm font-semibold mt-2'>{errors.addrNote.message}</p>}
                            </>
                        }
                    </div>

                    <Button
                        className='mt-2'
                        type={"submit"}
                        variant="contained"
                        disabled={loading}
                        style={{
                            width: '100%',
                            height: 55,
                            color: theme.palette.secondary.main
                        }}
                        size="large"
                        onClick={() => {
                            if (!selectedAddress)
                                return setError("addr", {
                                    type: "manual",
                                    message: t('REQ_ERR_ADDR'),
                                });
                        }}
                    >
                        {loading
                            ? <Loading size={48} />
                            : isOrderTypeChange
                                ? t('ACCEPT')
                                : t('NEXT')}
                    </Button>
                </form>
            </Fade>

            <InfoModal
                open={errAddrMsg !== null}
                msg={t(errAddrMsg)}
                onClose={() => setErrAddrMsg(null)}
            >
                <Button
                    variant="contained"
                    style={{ width: '100px', backgroundColor: theme.palette.secondary.main }}
                    onClick={() => setErrAddrMsg(null)}>
                    {t('CLOSE')}
                </Button>
            </InfoModal>
        </>
    )
}

export default AddressDetails;