import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axiosConn';
import { setToStorage, getFromStorage } from 'services';
import { setSelectedLang, getCart } from './userSettingsSlice';

const sliceName = 'biz';
const endpointName = sliceName;

export const getBiz = createAsyncThunk(`${sliceName}/getBiz`, async (params, { dispatch }) => {
    const { guid, langId } = params;

    if (!guid) return

    const bizDataStorage = getFromStorage(guid) ?? null;
    const updData = (new Date(bizDataStorage?.lastUpd).getTime() / 1000).toFixed()
    const response = await axios.get(`${endpointName}/${guid}/${bizDataStorage?.lastUpd && updData}`);

    if (response?.data.id === 1 || response?.data.id === 2) {
        let data = null

        if (response?.data.id === 1) {
            data = response?.data?.data;
            if (data)
                setToStorage(guid, {
                    lastUpd: data?.lastUpd,
                    data: data
                });
        }

        else if (response?.data.id === 2) {
            data = bizDataStorage?.data;
        }

        data.guid = guid;
        data.isTable = data?.table && data?.bizGuid !== data?.guid;
        //console.log('data.isTable', isTable);
        /* Check if biz have the needed criteria for ordering feautre,
           by checking if order is active, delivery or takeaway is active,
            have at least 1 order menu. */
        const hasOrderMenu = (data.isTable || data.orderSettings?.isActiveOnMainSite) &&
            (data.orderSettings?.isActive &&
                (data.orderSettings?.hasDelivery ||
                    data.orderSettings?.hasTakeAway) &&
                data.menus?.some((m) => m.typeId === 2));

        data.hasOrderMenu = hasOrderMenu;

        /* Filter menus by typeId (Orders only) so we will see only orders menu,
            depending on if the biz has orders feature */
        if (hasOrderMenu)
            data.menus = data.menus.filter(menu => (hasOrderMenu && menu.typeId === 2) ||
                (!hasOrderMenu && menu.typeId === 1));

        /* Biz default language */
        data.selectedLang = data.langs?.find(item => langId ? item?.code === langId : item?.id === data.defaultLangId ?? 1);

        /* If we don't have langId (Not Iframe mode),
            Check if user already chose a lang before, then set it as default. */
        if (!langId) {
            const userStorage = getFromStorage("user");
            const langId = userStorage?.selectedLang?.id;
            if (langId)
                data.selectedLang = data.langs?.find(l => l.id === langId) ?? data.langs[0];
        }

        dispatch(setSelectedLang(data.selectedLang))
        dispatch(getCart(data.bizGuid));
        return data;
    }

    return await -1;
});

export const bizDataSlice = createSlice({
    name: sliceName,
    initialState: null,
    reducers: {
        setLastUpd: (state, action) => { state.lastUpd = action.payload },
        setOpenTime: (state, action) => { state.openTime = action.payload },
        setNextOpenTime: (state, action) => { state.nextOpenTime = action.payload },
        setCloseTime: (state, action) => { state.closeTime = action.payload },
        setIsOpen: (state, action) => { state.isOpen = action.payload },
    },
    extraReducers: {
        [getBiz.fulfilled]: (state, action) => state = action.payload,
    }
})

export const { setLastUpd, setOpenTime, setCloseTime, setIsOpen, setNextOpenTime } = bizDataSlice.actions

export default bizDataSlice.reducer