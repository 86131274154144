import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { DaysOfWeek } from 'services';
import { Typography, Button, Box } from '@mui/material';
import { useTheme } from '@emotion/react';
import { setCart, setOrder } from 'store/userSettingsSlice';
import { STEPS } from './Orders';
import chefSleep from '../assets/CHEF_SLEEP.svg'
import CustomModal from './utils/CustomModal';
import { dateTimeFormat } from 'configs/settingsConfig';
import { ReactComponent as WarningIcon } from '../assets/errors/warning.svg';
import {
    setCloseTime,
    setIsOpen,
    setNextOpenTime,
    setOpenTime
} from 'store/bizDataSlice';

const BizClosingTime = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const orderSettings = useSelector(state => state.bizData?.orderSettings);
    const bizData = useSelector(state => state.bizData);
    const closeTimeTimer = useSelector(state => state?.bizData?.closeTime);
    const bizOpenTime = useSelector(state => state?.bizData?.openTime);
    const bizOpenInMinute = useSelector(state => state?.bizData?.nextOpenTime);
    const order = useSelector(state => state.userSettings.order);
    const [isBizClosed, setIsBizClosed] = useState(false);
    const [dataBizClosed, setDataBizClosed] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);

    /* Popup For Add Time For Test */
    const testMode = false
    const isDevelopment = process.env.NODE_ENV === "development"
    const isTesting = !isDevelopment ? false : isDevelopment && testMode
    const [timeForTest, setTimeForTest] = useState(moment().startOf('day'));
    const [openTimeTestDialog, setOpenTimeTestDialog] = useState(true);

    const AddMiniet = (num, type) => {
        setTimeForTest(time => moment(time).add(num, type))
        checkIfBizOpen()
    }
    //

    useEffect(() => {

        if (order.step === STEPS.ORDER_TYPE)
            checkIfBizOpen()
        // if ((bizData.isTable && order.step === Steps.Menus) ||
        //     order.step === Steps.OrderType) {
        //     checkIfBizOpen()
        // }
    }, [order.step, bizData]);

    /** Timer For Open Biz */
    useEffect(() => {
        const toExactMinute = 60000 - (new Date().getTime() % 60000);

        if (bizOpenInMinute === 0) {
            // if (bizData?.isTable) {
            //     dispatch(setOrder({
            //         step: Steps.Menus,
            //         typeId: ORDER_TYPE_ID.inPlace
            //     }));
            // }
            // else {
            dispatch(setOrder({ step: STEPS.ORDER_TYPE }))
            //            }

            setIsBizClosed(false)
            dispatch(setIsOpen(true))
            setOpenDialog(true)
        }

        if (!bizOpenInMinute)
            return

        const timerOpenBiz = setInterval(() => {
            dispatch(setNextOpenTime(bizOpenInMinute - 1));
        }, toExactMinute)

        return () => clearInterval(timerOpenBiz);
    }, [bizOpenInMinute]);

    /** Timer For Close Biz */
    useEffect(() => {
        const toExactMinute = 60000 - (new Date().getTime() % 60000);

        if (!closeTimeTimer) {
            // if (bizData?.isTable) {
            //     dispatch(setOrder({
            //         step: Steps.Menus,
            //         typeId: ORDER_TYPE_ID.inPlace
            //     }));
            // }
            // else {
            dispatch(setOrder({ step: STEPS.ORDER_TYPE }))
            //            }

            setIsBizClosed(true)
            dispatch(setIsOpen(false))
            return;
        }

        const timerCloseBiz = setInterval(() => {
            dispatch(setCloseTime(closeTimeTimer - 1));
        }, toExactMinute)

        return () => clearInterval(timerCloseBiz);
    }, [closeTimeTimer]);

    /** On Close Dialog Open Biz  */
    const onCloseHandler = () => {
        dispatch(setOrder());
        dispatch(setCart(null));
        setOpenDialog(false);
    }

    const checkIfBizOpen = () => {
        const res = whenBizIsOpen();
        /**  set Next Open Day To Show In Dialog */
        if (res?.nextOpenDate) {
            let isToday = null
            isTesting
                ? isToday = moment(res.nextOpenDate).isSame(moment(timeForTest), 'day')
                : isToday = moment(res.nextOpenDate).isSame(moment(), 'day');
            dispatch(setOpenTime(`
                ${isToday ? "" : t("DAY")}
                 ${isToday ? "" : t(DaysOfWeek[moment(res.nextOpenDate).day()])} 
                  ${isToday ? "" : "|"}
                   ${moment(res.nextOpenDate).format('HH:mm')}`))
        }

        /** Value For setTimeOut Open Biz */
        isTesting
            ? dispatch(setCloseTime(moment(res.nextCloseDate)?.add(1, "minutes")?.diff(moment(timeForTest), 'minutes')))
            : dispatch(setCloseTime(moment(res.nextCloseDate)?.add(1, "minutes")?.diff(moment(), 'minutes')))

        /** Value For setTimeOut Open Biz */
        isTesting
            ? dispatch(setNextOpenTime(moment(res.nextOpenDate)?.add(1, "minutes")?.diff(moment(timeForTest), 'minutes')))
            : dispatch(setNextOpenTime(moment(res.nextOpenDate)?.add(1, "minutes")?.diff(moment(), 'minutes')))

        dispatch(setIsOpen(res.isOpen))
        setIsBizClosed(!res.isOpen)
    }

    /* Check biz working times (Timing) */
    const whenBizIsOpen = () => {
        let bizTiming = {
            nextOpenDate: null,
            nextCloseDate: null,
            isOpen: true
        }

        const checkTiming = (dt) => {
            let lastDate = null;
            let timeFound = null;
            let nextCloseDt = null;
            let minTimeToOpen = null;
            let calcDow = null

            for (let index = 0; index < orderSettings?.timing.length; index++) {
                const time = orderSettings?.timing[index];
                const ftm = moment(time.ftm);
                const ttm = moment(time.ttm);
                const dtInDayWooky = moment(dt).day() + 1

                if (minTimeToOpen) {
                    calcDow = moment(dt).isoWeekday(time.dow - 1);
                }
                else {

                    /** Calculation of the number of days between the current date and the next opening date */
                    const startDay = dtInDayWooky - 1;
                    const endDay = time.dow - 1;
                    const sumDays = (endDay - startDay + 7) % 7;
                    calcDow = moment(dt).add(sumDays, "days");
                }

                let fdt = moment(calcDow).set('hour', ftm.hour()).set('minute', ftm.minutes());
                let tdt = moment(calcDow).set('hour', ttm.hour()).set('minute', ttm.minutes());

                if (tdt.isBefore(fdt))
                    tdt = moment(tdt).set('hour', ttm.hour()).set('minute', ttm.minutes()).add(1, 'days');

                lastDate = fdt;

                if (minTimeToOpen === null && fdt.isAfter(dt)) {
                    minTimeToOpen = fdt;
                }

                if ((moment(dt).isBetween(fdt, tdt) ||
                    moment(dt).isSame(fdt))) {

                    bizTiming.isOpen = true;
                    bizTiming.nextCloseDate = tdt;
                    timeFound = fdt;
                    break;
                }

                /** Check the earliest time of day */
                if (fdt.isBefore(minTimeToOpen) && fdt.isAfter(dt)) {
                    minTimeToOpen = fdt;
                }
            };

            return {
                lastDate,
                timeFound,
                minTimeToOpen,
                nextCloseDt
            }
        }

        if (orderSettings?.timing?.length > 0) {
            bizTiming.isOpen = false;
            const now = isTesting ? timeForTest : moment()
            let res = checkTiming(now);

            if (!res.minTimeToOpen) {
                res = checkTiming(res.lastDate)
            }

            bizTiming.nextOpenDate = res.minTimeToOpen;
        }

        return bizTiming;
    }

    return (
        <>
            {/* Popup For Check Close Time */}
            {isTesting
                ? <CustomModal
                    open={openTimeTestDialog}
                    onClose={() => setOpenTimeTestDialog(false)}
                    closeIcon
                >
                    <div className='flex flex-col items-center justify-center gap-3'>
                        <p className='text-xl font-bold text-blue-500'>
                            זמן נוכחי :  {timeForTest.format(dateTimeFormat)}
                        </p>

                        <p className='text-xl font-bold'>
                            יום נוכחי :  {t(DaysOfWeek[moment(timeForTest).day()])}
                        </p>

                        {isBizClosed &&
                            <p className={`text-xl font-bold`}>
                                פתיחה הבאה :  {bizOpenTime}
                            </p>
                        }

                        <p className={`text-xl font-bold ${!isBizClosed ? "text-green-600" : "text-red-600"}`}>
                            מצב העסק : {!isBizClosed ? "פתוח" : "סגור"}
                        </p>
                        <div className='flex w-72 gap-3'>
                            <Button
                                className='w-full'
                                sx={{ color: "#333", backgroundColor: "#fff" }}
                                variant={"contained"}
                                onClick={() => AddMiniet(1, "minutes")}
                            >
                                הוסף דקה
                            </Button>


                            <Button
                                className='w-full'
                                sx={{ color: "#333", backgroundColor: "#fff" }}
                                variant={"contained"}
                                onClick={() => AddMiniet(-1, "minutes")}
                            >
                                הורד דקה
                            </Button>
                        </div>

                        <div className='flex w-72 gap-3 '>
                            <Button
                                className='w-full'
                                sx={{ color: "#333", backgroundColor: "#fff" }}
                                variant={"contained"}
                                onClick={() => AddMiniet(1, "hours")}
                            >
                                הוסף שעה
                            </Button>


                            <Button
                                className='w-full'
                                sx={{ color: "#333", backgroundColor: "#fff" }}
                                variant={"contained"}
                                onClick={() => AddMiniet(-1, "hours")}
                            >
                                הורד שעה
                            </Button>
                        </div>

                        <div className='flex w-72 gap-3 '>
                            <Button
                                className='w-full'
                                sx={{ color: "#333", backgroundColor: "#fff" }}
                                variant={"contained"}
                                onClick={() => AddMiniet(1, "days")}
                            >
                                הוסף יום
                            </Button>


                            <Button
                                className='w-full'
                                sx={{ color: "#333", backgroundColor: "#fff" }}
                                variant={"contained"}
                                onClick={() => AddMiniet(-1, "days")}
                            >
                                הורד יום
                            </Button>
                        </div>

                    </div>
                </CustomModal>

                : <CustomModal
                    open={isBizClosed}
                    onClose={() => { }}
                >
                    <Box
                        className='flex flex-col justify-center items-center gap-4 rounded-lg border-solid p-4'
                        sx={{ borderColor: 'primary.main' }}
                    >

                        <img src={chefSleep} className='w-44' />

                        <Typography variant='h2'>
                            {bizData?.isClosedTodayMsg}
                        </Typography>

                        <div className='flex flex-col text-center gap-4'>
                            <Typography color="primary" variant='h4' className='font-bold'>
                                {t("BIZ_IS_CLOSED_NOW")}
                            </Typography>

                            {bizOpenTime &&
                                <div>
                                    <Typography color="primary" variant='h5' className='font-bold'>
                                        {t("YOU_CAN_ORDER_FROM")}
                                    </Typography>
                                    <Typography color="primary" variant='h6' className='font-bold'>
                                        {bizOpenTime}
                                    </Typography>
                                </div>
                            }

                        </div>

                        {dataBizClosed &&
                            <Typography color="primary">
                                {dataBizClosed?.msg}
                            </Typography>
                        }

                        <div className={`flex flex-col w-full h-full gap-2
                        ${bizData?.orderSettings?.futureOrderInDays
                                ? 'justify-between'
                                : 'justify-center'}`}>

                            <Button
                                variant="contained"
                                onClick={() => {
                                    dispatch(setOrder({ step: STEPS.MENU_LIST, typeId: null }))
                                    setIsBizClosed(false)
                                }}
                            >

                                {t('VIEW_MENU')}
                            </Button>

                            {bizData?.orderSettings?.futureOrderInDays &&
                                !bizData.isTable &&
                                <Button
                                    variant="contained"
                                    onClick={() => setIsBizClosed(false)}
                                >
                                    {t('FUTURE_ORDER')}
                                </Button>
                            }
                        </div>
                    </Box>
                </CustomModal>
            }

            {/* Popup For Open biz Affter Timer   */}
            <CustomModal
                open={openDialog}
                svgIcon={{ component: WarningIcon }}
                actionBtn={{ title: "CONFIRM", action: onCloseHandler }}
                onClose={onCloseHandler}
            >
                <div className="flex flex-col justify-center items-center m-4">
                    <Typography variant="h6" color="primary">
                        {t("OPEN_BIZ_DIALOG_TITLE")}
                    </Typography>
                    <Typography variant="subtitle1" color="primary">
                        {t("TIMER_DIALOG_DESC")}
                    </Typography>
                </div>
            </CustomModal>

        </>
    )
};

export default BizClosingTime;