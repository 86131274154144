import { useSelector } from "react-redux";
import useCommon from "common/hooks/cartModifiers"
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import useCurrency from "hooks/useCurrency";

const CartOrderButton = ({ onClick, totlePr, commissionPr }) => {
    const { t } = useTranslation();
    const { currency } = useCurrency();
    const menuWithOrder = useSelector(state => state.menu.canOrder);
    const order = useSelector(state => state.userSettings.order);
    const coupon = useSelector(state => state.sales.coupon);
    const globalSale = useSelector(state => state.sales.sale);
    const { hasOrderMenu } = useSelector(state => state.bizData);
    const orderSettings = useSelector(state => state.bizData.orderSettings);
    const { cartTotalPrice } = useSelector(state => state.cart);

    const [showMinPr, setShowMinPr] = useState(false);
    const [disabled, setDisabled] = useState(false);

    const viewOnly = !hasOrderMenu || !order.typeId;
    const showDeliveryPr = order?.address?.pr > 0 && order?.typeId > 0;

    useEffect(() => {
        if (hasOrderMenu &&
            order.typeId > 0 &&
            menuWithOrder &&
            cartTotalPrice < orderSettings?.minPr) {
            setShowMinPr(true)
        }
        else {
            setShowMinPr(false)
        }

        setDisabled(!(cartTotalPrice >= orderSettings?.minPr && cartTotalPrice > 0))
    }, [cartTotalPrice, orderSettings?.minPr, order.typeId, hasOrderMenu, menuWithOrder])

    return (
        <Box className="flex flex-col gap-2 w-full">

            {orderSettings?.estTimeInMinutes && !order?.address?.estTime
                ? <Stack
                    direction='row'
                    justifyContent='space-between'
                    borderRadius={1}
                    padding={1}
                    color="secondary.main"
                    bgcolor='primary.light'>

                    <Stack>
                        <Typography
                            fontWeight='bold'
                            variant="body2">
                            {`${t('MAKING_TIME')}`}
                        </Typography>
                    </Stack>

                    <Typography fontWeight='bold' variant="body2">
                        {`${orderSettings?.estTimeInMinutes} ${t('MINUTES')}`}
                    </Typography>
                </Stack>
                : null
            }

            {order?.address?.estTime
                ? <Stack
                    direction='row'
                    justifyContent='space-between'
                    borderRadius={1}
                    padding={1}
                    color="secondary.main"
                    bgcolor='primary.light'>

                    <Stack>
                        <Typography
                            fontWeight='bold'
                            variant="body2">
                            {`${t('DELIVERY_TIME')}`}
                        </Typography>
                    </Stack>

                    <Typography fontWeight='bold' variant="body2">
                        {`${order?.address?.estTime} ${t('MINUTES')}`}
                    </Typography>
                </Stack>
                : null
            }

            {showMinPr
                ? <Stack
                    direction='row'
                    justifyContent='space-between'
                    borderRadius={1}
                    padding={1}
                    color="error.main"
                    bgcolor='primary.light'>

                    <Stack>
                        <Typography
                            fontWeight='bold'
                            variant="body2">
                            {`${t('MIN_ORDER_TTL')}`}
                        </Typography>

                        {showDeliveryPr &&
                            <Typography fontWeight='bold' variant="body2">
                                {`${t('DELIVERY_PRICE_NOT_INCLUDED')}`}
                            </Typography>
                        }
                    </Stack>

                    <Typography fontWeight='bold' variant="body2">
                        {`${currency(orderSettings?.minPr)}`}
                    </Typography>
                </Stack>
                : null
            }

            {(showDeliveryPr ||
                coupon?.discountPercent ||
                coupon?.discountPrice ||
                commissionPr ||
                globalSale)
                ? <Stack
                    gap={1}
                    borderRadius={1}
                    paddingY={1}
                    paddingX={1}
                    bgcolor='primary.light'>

                    {cartTotalPrice > 0
                        ? <>
                            <Stack
                                direction='row'
                                justifyContent='space-between'
                                color="secondary.main">
                                <Typography fontWeight='bold'>
                                    {`${t('MIDDLE_PR')}`}
                                </Typography>
                                <Typography fontWeight='bold'>
                                    {currency(cartTotalPrice)}
                                </Typography>
                            </Stack>
                            <Divider sx={{ borderColor: 'secondary.main' }} />
                        </>
                        : null
                    }

                    {commissionPr
                        ? <Stack
                            direction='row'
                            justifyContent='space-between'
                            color="secondary.main"
                            className="flex justify-between">
                            <Typography variant="body2">{`${t('COMM_PR')}`}</Typography>
                            <Typography variant="body2">
                                {`${currency(commissionPr)}`}
                            </Typography>
                        </Stack>
                        : null
                    }

                    {showDeliveryPr
                        ? <Stack
                            direction='row'
                            justifyContent='space-between'
                            color="secondary.main"
                            className="flex justify-between">
                            <Typography variant="body2">{`${t('DELIVERY_COST')}`}</Typography>
                            <Typography variant="body2">
                                {`${currency(order?.address?.pr)}`}
                            </Typography>
                        </Stack>
                        : null
                    }

                    {showDeliveryPr && (globalSale || coupon) && <Divider />}

                    {globalSale ?
                        <Stack
                            width='100%'
                            direction='row'
                            justifyContent='space-between'
                            color="secondary.main">

                            <Stack>
                                <Typography variant="body2">
                                    {globalSale?.name}
                                </Typography>

                                {globalSale?.minOrderPrice
                                    ? <Typography fontSize={12}>
                                        {`${t('LOW_PRICE_TITLE_GLOBAL_SALE')} ${currency(globalSale.minOrderPrice)}`}
                                    </Typography>
                                    : null
                                }

                            </Stack>

                            {globalSale?.discountPercent
                                ? <Typography variant="body2">
                                    {`${globalSale.discountPercent}%`}
                                </Typography >
                                : <Typography variant="body2">
                                    {`${currency(-globalSale?.discountPrice)}`}
                                </Typography>
                            }

                        </Stack>
                        : null
                    }

                    {globalSale && coupon && <Divider />}

                    {coupon?.discountPercent || coupon?.discountPrice ?
                        <Stack
                            width='100%'
                            direction='row'
                            justifyContent='space-between'
                            color="secondary.main">

                            <Stack>
                                <Typography variant="body2">
                                    {`${t('DISCOUNT_COUPON')}`}
                                </Typography>

                                {coupon?.minOrderPrice
                                    ? <Typography fontSize={12}>
                                        {`${t('LOW_PRICE_TITLE_COUPON')} ${currency(coupon?.minOrderPrice)}`}
                                    </Typography>
                                    : null
                                }

                            </Stack>

                            {coupon?.discountPercent
                                ? <Typography variant="body2">
                                    {`${coupon?.discountPercent}%`}
                                </Typography >
                                : <Typography variant="body2">
                                    {`${currency(-coupon?.discountPrice)}`}
                                </Typography>
                            }

                        </Stack>
                        : null
                    }
                </Stack>
                : null
            }

            {
                !viewOnly ?
                    <Button
                        onClick={onClick}
                        disabled={disabled}
                        variant="contained"
                    >
                        <div className='flex justify-between w-full'>
                            <Typography>
                                {t('FINISH_ORDER')}
                            </Typography>
                            <Typography className='flex items-center gap-1 font-bold'>
                                <span>{`${t("TOTAL")}:`}</span>
                                <span>
                                    {`${totlePr < 0
                                        ? currency(0)
                                        : currency(totlePr)}`}
                                </span>
                            </Typography>
                        </div>
                    </Button>
                    :
                    <Typography
                        className='flex items-center justify-between py-1.5 px-4 font-bold'
                        color="secondary"
                        sx={{
                            backgroundColor: 'primary.main',
                            borderRadius: 1
                        }}
                    >
                        <span>{`${t("TOTAL")}`}</span>
                        <span>{`${currency(cartTotalPrice)}`}</span>
                    </Typography>
            }

        </Box >
    )
}
export default CartOrderButton;