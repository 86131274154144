import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button, Card, CardActionArea, CardMedia, IconButton, Stack, Typography } from "@mui/material";
import { setSelectedLang } from "../../store/userSettingsSlice";
import YpUtils from "../../YpUtils";
import CustomModal from "./CustomModal";

const LanguageButton = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const bizData = useSelector((state) => state.bizData);
    const selectedLang = useSelector(state => state.userSettings.selectedLang);

    const [isOpen, setIsOpen] = useState(false);
    // const sx = selectedLang.isRtl
    //     ? {
    //         right: 0,
    //         borderTopRightRadius: 0,
    //         borderTopLeftRadius: 76,
    //         borderBottomRightRadius: 0,
    //         borderBottomLeftRadius: 76,
    //     }
    //     : {
    //         left: 0,
    //         borderTopRightRadius: 76,
    //         borderTopLeftRadius: 0,
    //         borderBottomRightRadius: 76,
    //         borderBottomLeftRadius: 0,
    //     };

    return (
        bizData?.langs?.length > 1 &&
        <>
            <IconButton
                onClick={() => setIsOpen(true)}
                variant="contained"
            >
                <img
                    src={YpUtils.getImage(selectedLang?.imgUrl ?? bizData?.langs[0]?.imgUrl)}
                    className="w-10"
                />
            </IconButton>

            <CustomModal
                btnTextVariant='h3'
                title={t('CHOOSE_LANG')}
                open={isOpen}
                closeIcon
                onClose={() => { setIsOpen(false) }}
            >
                <Stack
                    maxWidth={350}
                    direction='row'
                    useFlexGap
                    flexWrap="wrap"
                    gap={4}
                    justifyContent='center'
                >
                    {
                        bizData && bizData.langs?.map((lang) => {
                            return (
                                <Card
                                    key={lang.id}
                                    className="col-span-1"
                                    elevation={0}
                                    sx={{
                                        backgroundColor: 'transparent',
                                        border: 'none'
                                    }}
                                    onClick={() => {
                                        setIsOpen(false)
                                        dispatch(setSelectedLang(lang))
                                    }}
                                >
                                    <CardActionArea>
                                        <Stack width="100%" justifyContent='center' alignItems='center' >
                                            <CardMedia
                                                className="border solid border-gray-500"
                                                component="img"
                                                height="70"
                                                image={YpUtils.getImage(lang.imgUrl)}
                                            />

                                            <Typography color='primary'>
                                                {lang.langName}
                                            </Typography>
                                        </Stack>
                                    </CardActionArea>
                                </Card>
                            )
                        })
                    }
                </Stack>
            </CustomModal>
        </>
    )
}
export default LanguageButton