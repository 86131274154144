import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import he from "i18n/he.json"
import en from "i18n/en.json"
import ru from "i18n/ru.json"
import fr from "i18n/fr.json"
import ar from "i18n/ar.json"

const resources = {
    en: {
        translation: { ...en }
    },
    he: {
        translation: { ...he }
    },
    ru: {
        translation: { ...ru }
    },
    fr: {
        translation: { ...fr }
    },
    ar: {
        translation: { ...ar }
    }
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: 'he',

        keySeparator: false,

        interpolation: {
            escapeValue: false
        }
    });

export default i18n;