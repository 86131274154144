import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axiosConn';
import { getFromStorage, setToStorage } from 'services';
import { setOpenedProduct, setOpenCat } from './cartSlice';

const sliceName = 'product';
const endpointName = 'menuItem';

export const getProduct = createAsyncThunk(`${sliceName}/getProduct`, async (params, { dispatch }) => {
    const { bizId, itemId, langId, guid, catId } = params;
    const localStorageKey = `${guid}-prods-${langId}-${bizId}-${catId}-${itemId}`;
    let storageData = getFromStorage(localStorageKey) ?? {};
    let currItemData = storageData[itemId] ?? null;
    let lastUpd = currItemData?.lastUpd ?? null;

    let prod = null;

    if (!itemId || !bizId || !catId || !langId) {
        return;
    }

    const response = await axios.get(`${endpointName}/${bizId}/${itemId}/${langId}/${catId}/${lastUpd}?timestamp=${new Date().getTime()}`)
    if (response?.data?.id === 1) {
        if (response?.data?.data) {
            let product = { ...response?.data?.data, pid: catId };
            let data = {
                lang: langId,
                lastUpd: response?.data?.data?.lastUpd,
                data: product
            }

            setToStorage(localStorageKey, { ...storageData, [itemId]: data });

            prod = product
        }
        else
            prod = currItemData?.data;
    }
    // .catch(error => {
    //     if (error?.response?.data?.id === -1) {
    //         // throw error;
    //     }
    // });

    const modifierGroups = prod.ingGrps?.map(g => {
        return (
            {
                id: g.id,
                maxQty: g.maxQty,
                minQty: g.minQty,
                langs: [{ langId: langId, name: g.name }],
                touched: false,
                seq: g.seq,
                modifiers: g.ings?.filter((f) => (f.paidQty + f.freeQty) > 0)?.map(m => {
                    return (
                        {
                            ...m,
                            langs: [{
                                langId: langId,
                                name: m.name,
                            }]
                        }
                    );
                })
            }
        )
    })
    prod = { ...prod, modifierGroups: modifierGroups.filter(f => f.modifiers?.length > 0) }
    //console.log('prod', prod);
    //dispatch(setSelectedProd(prod));
    dispatch(setOpenedProduct(prod));
    dispatch(setOpenCat({ id: catId }))
    return prod;
});

export const productSlice = createSlice({
    name: sliceName,
    initialState: null,
    extraReducers: {
        [getProduct.fulfilled]: (state, action) => state = action.payload
    }
})

export default productSlice.reducer