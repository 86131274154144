import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import YpUtils from 'YpUtils';
import { useTranslation } from 'react-i18next';
import { ReactComponent as IconDelivery } from 'assets/icon-delivery.svg';
import { ReactComponent as IconTa } from 'assets/icon-ta.svg';
import { ReactComponent as foodTray } from 'assets/tray.svg'

import RoundedBtn from 'components/RoundedBtn';
import { STEPS } from '..';
import { setOrder } from 'store/userSettingsSlice';
import { Box, Button, Fade, Slide, Stack, Zoom } from '@mui/material'
import { Link, useParams } from 'react-router-dom';
import { ORDER_TYPE_ID } from 'configs/consts';
import StepHeader from '../StepHeader';

const Index = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { guid } = useParams();
    const bizData = useSelector(state => state.bizData);
    const order = useSelector(state => state.userSettings.order);
    const isBizOpen = useSelector(state => state?.bizData?.isOpen);
    const selectedLang = useSelector(state => state.userSettings.selectedLang);

    useEffect(() => {
        if (bizData.isTable) {
            if (!bizData.table.hasSitting && !bizData.table.hasPickup) {
                dispatch(setOrder({ ...order, step: STEPS.MENU_LIST, typeId: ORDER_TYPE_ID.SITTING }))
            }
        }
    }, [bizData]);

    return (
        <Box
            className='flex flex-col justify-center items-center'
            sx={{ backgroundColor: 'secondary.main' }}
        >
            <StepHeader />

            {/* Order  types buttons */}
            <Box
                className='w-full'
                sx={{ backgroundColor: 'secondary.main' }}
            >
                <Fade timeout={700} in={true}>
                    <Stack
                        // className='py-4 sm:py-8 gap-5 xl:gap-2'
                        direction={{ xs: 'column', sm: 'row' }}
                        spacing={{ xs: 2, sm: 4, md: 6 }}
                        justifyContent={'center'}
                        alignItems={'center'}
                    >
                        {bizData.isTable ?
                            <>
                                {bizData?.table.hasSitting ?
                                    <RoundedBtn
                                        title={t("translation:SITTING")}
                                        size="xxl"
                                        component={foodTray}
                                        onClick={() => {
                                            dispatch(setOrder({
                                                step: STEPS.MENU_LIST,
                                                typeId: ORDER_TYPE_ID.SITTING
                                            }))
                                        }}
                                    />
                                    :
                                    null
                                }

                                {bizData?.table.hasPickup ?
                                    <RoundedBtn
                                        title={t("PICKUP")}
                                        size="xxl"
                                        component={IconTa}
                                        onClick={() => {
                                            dispatch(setOrder({
                                                step: STEPS.MENU_LIST,
                                                typeId: ORDER_TYPE_ID.PICKUP
                                            }))
                                        }}
                                    />
                                    :
                                    null
                                }
                            </>
                            :
                            <>
                                {/* TakeAway button */}
                                {bizData?.orderSettings?.hasTakeAway &&
                                    <RoundedBtn
                                        title={t("TAKE_AWAY")}
                                        subTitle={selectedLang?.addr ?? ''}
                                        size="xxl"
                                        component={IconTa}
                                        onClick={() => {
                                            bizData?.orderSettings?.futureOrderInDays ?
                                                dispatch(setOrder({
                                                    step: !isBizOpen ? STEPS.FUTURE_ORDER : STEPS.ORDER_TIME,
                                                    typeId: ORDER_TYPE_ID.PICKUP
                                                }))
                                                :
                                                dispatch(setOrder({
                                                    step: STEPS.MENU_LIST,
                                                    typeId: ORDER_TYPE_ID.PICKUP
                                                }))
                                        }}
                                    />
                                }

                                {/* Delivery button */}
                                {bizData?.orderSettings?.hasDelivery &&
                                    <RoundedBtn
                                        title={t("DELIVERY")}
                                        size="xxl"
                                        component={IconDelivery}
                                        onClick={() => {
                                            dispatch(setOrder({
                                                step: STEPS.ADDRESS,
                                                typeId: ORDER_TYPE_ID.DELIVERY
                                            }))
                                        }}
                                    />
                                }
                            </>
                        }
                    </Stack>
                </Fade>

                {/* View Menu button */}
                {bizData?.orderSettings?.btnShowBizMenuId &&
                    <div className='flex justify-center items-center p-2'>
                        <Link to={`/${guid}/${bizData?.orderSettings?.btnShowBizMenuId}`}>
                            <Button
                                onClick={() => {
                                    dispatch(setOrder({ step: STEPS.MENU_LIST, typeId: null }))
                                }}
                            >
                                {t('VIEW_MENU')}
                            </Button>
                        </Link>
                    </div>
                }
                {/* <NumPad variant="contained" color='secondary' /> */}
            </Box>
        </Box>
    )
}

export default Index;