import { useTheme } from '@emotion/react';
import { Divider, Stack, useMediaQuery } from '@mui/material';
import OrderTypeChange from './OrderTypeChange';
import TimingOrder from './TimingOrder';
import Coupon from './Coupon';
// import GlobalSale from './GlobalSale';
import { setSale } from 'store/salesSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

const OrderTypes = () => {
    const dispatch = useDispatch()
    const theme = useTheme();
    const xl = useMediaQuery(theme.breakpoints.up('lg'));
    const bizData = useSelector(state => state.bizData);
    const [show, setShow] = useState(false);

    useEffect(() => {
        if (bizData?.sale) {
            dispatch(setSale({
                id: bizData?.sale?.id,
                name: bizData?.sale?.name,
                tillDate: bizData?.sale?.tillDate,
                minOrderPrice: bizData?.sale?.minOrderPrice,
                discountPercent: bizData?.sale?.discountPercent,
                discountPrice: bizData?.sale?.discountPrice
            }));
        }

        if (bizData?.hasOrderMenu) {
            if (bizData?.orderSettings?.hasDelivery && bizData?.orderSettings?.hasTakeAway)
                setShow(true)
            else if (bizData.orderSettings?.futureOrderInDays)
                setShow(true)
            else if (bizData?.table?.hasPickup && bizData?.table?.hasSitting)
                setShow(true)
            else
                setShow(false)
        }
        else
            setShow(false)
    }, [bizData]);

    return (
        show ?
            <Stack
                width='100%'
                className='p-2 md:p-4 gap-2'
                border={xl && `1px solid ${theme.palette.primary.main}`}
                sx={[
                    { backgroundColor: 'secondary.main' },
                    xl ? { maxHeight: '50vh' } : {}
                ]}
                spacing={1}
            >
                <OrderTypeChange />
                <TimingOrder />
                <Coupon />
            </Stack>
            :
            null
    )
}

export default OrderTypes;