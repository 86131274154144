import React, { useState, useEffect } from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { useSelector } from 'react-redux';
import { useTheme } from '@emotion/react';
import CustomModal from './utils/CustomModal';

const AdPopUp = ({ typeId, callback }) => {
    const bizData = useSelector(state => state.bizData);
    const selectedLang = useSelector(state => state.userSettings.selectedLang);
    let [popups, setPopups] = useState([]);
    let [openModal, setOpenModal] = useState(false);
    let theme = useTheme();
    const smScreen = useMediaQuery(theme.breakpoints.down('lg'));

    const popupDesc = popups[0]?.langs?.find(lang => lang?.id === selectedLang?.id)?.desc;

    useEffect(() => {
        if (!bizData?.popUps || !typeId)
            return;

        let typePopups = bizData?.popUps?.filter(popup => popup?.typeId === typeId);
        setPopups(typePopups);

        if (typePopups?.length > 0)
            setOpenModal(true);

    }, [bizData?.popUps, typeId]);

    const handleClose = () => {
        let newPopups = [...popups]
        newPopups.shift();

        if (newPopups?.length === 0) {
            setOpenModal(false);
            callback && callback();
        }

        setPopups(newPopups);
    };

    return (
        <CustomModal
            open={openModal}
            onClose={handleClose}
            focusOnCloseButton
            closeIcon
        >
            {popups?.length > 0 &&
                <Box className='flex flex-col items-center h-full max-w-lg'>
                    {popups[0]?.media?.url &&
                        <>
                            {popups[0]?.media?.typeId === 2
                                ? <video
                                    style={{ width: smScreen ? "370px" : "535px", maxHeight: '60vh' }}
                                    src={popups[0]?.media?.url}
                                    autoPlay muted loop
                                    controls={false}
                                />
                                : <img
                                    className='w-full h-full object-contain'
                                    style={{
                                        maxWidth: smScreen ? "100%" : "535px",
                                        maxHeight: '60vh',
                                    }}
                                    src={popups[0]?.media?.url}
                                    alt='' />
                            }
                        </>
                    }

                    {popupDesc &&
                        <Typography color="primary" dangerouslySetInnerHTML={{ __html: popupDesc }} />}
                </Box>}
        </CustomModal>
    )
}

export default AdPopUp;