import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import YpUtils from 'YpUtils';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import Accessibility from 'components/Accessibility';
import { useTheme } from '@emotion/react';
import ResetOrder from 'components/Orders/ResetOrder';
import ToolsButtons from './ToolsButtons';
import MenuListItem from './MenuListItem';
import { setOrder } from 'store/userSettingsSlice';
import { STEPS } from 'components/Orders';
import PoweredBy from 'components/utils/PoweredBy';
import { KOSHER_LIST } from 'configs/consts';
import CustomModal from 'components/utils/CustomModal';
import { ReactComponent as kosherSvg } from 'assets/kosher.svg';

import {
    SwipeableDrawer,
    Button,
    lighten,
    IconButton,
    List,
    ListItem,
    Divider,
    useMediaQuery,
    Typography,
} from '@mui/material';

// import ChangeBranch from 'components/ChangeBranch';

const AppMenu = () => {
    const theme = useTheme();
    const dispacth = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const smScreen = useMediaQuery(theme.breakpoints.down('lg'));
    const bizData = useSelector(state => state.bizData);
    const selectedLang = useSelector(state => state.userSettings.selectedLang);
    const [open, setOpen] = useState(false);
    const [openKosherImg, setOpenKosherImg] = useState(false);
    const hours = selectedLang?.openingHr?.replace(/\n/g, '<br>')
    const order = useSelector(state => state.userSettings.order);
    return (
        <>
            <IconButton color='primary' onClick={() => setOpen(true)}>
                <MenuIcon />
            </IconButton>

            <SwipeableDrawer
                anchor={'left'}
                disableSwipeToOpen={true}
                open={open}
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
                PaperProps={{
                    style: {
                        borderRadius: 0,
                        width: "90%",
                        maxWidth: '400px',
                        backgroundColor: theme.palette.secondary.main,
                        zIndex: 999
                    },
                }}
            >
                <>
                    <div className='flex flex-col h-full justify-between p-2'>
                        <div className='flex flex-col'>

                            {/* Header */}
                            <div>
                                <div className='flex justify-end'>
                                    <IconButton color='primary' onClick={() => setOpen(false)} >
                                        <CloseIcon />
                                    </IconButton>
                                </div>
                                <div className='flex flex-col justify-center items-center'>
                                    <img
                                        className="max-h-28 md:max-h-40 w-40 object-contain"
                                        src={YpUtils.getImage(bizData?.imgLogo)} alt=''
                                    />
                                </div>
                            </div>

                            {/* Menu list & reset order buttons */}
                            <div className='flex flex-row items-center justify-center my-6 gap-3'>

                                {bizData?.hasOrderMenu && order?.step !== STEPS?.ORDER_TYPE &&
                                    <ResetOrder setIsBurgerOpen={setOpen}>
                                        <Button >
                                            {t('RESET_ORDER')}
                                        </Button>
                                    </ResetOrder>
                                }

                                {/* Menu list button */}
                                {bizData?.menus?.length > 1 &&
                                    <Button
                                        className="mx-4"
                                        variant='contained'
                                        onClick={() => {
                                            navigate(`/${bizData?.guid}/`);
                                            dispacth(setOrder({ step: STEPS.MENU_LIST }));
                                            setOpen(false)
                                        }}
                                    >
                                        {t('MENUS_LIST')}
                                    </Button>
                                }
                            </div>

                            <List sx={{ width: '100%', maxWidth: 500 }}>

                                {/* Opening Hours */}
                                {selectedLang?.openingHr &&
                                    <ListItem divider={<Divider />} sx={{ paddingX: 0 }}>
                                        <MenuListItem title={t('OPEN_HOURS')} icon={'query_builder'}>
                                            <Typography
                                                dangerouslySetInnerHTML={{ __html: selectedLang?.openingHr.replace(/(\r\n|\r|\n)/g, '<br>') }}
                                            >
                                            </Typography>
                                        </MenuListItem>
                                    </ListItem>
                                }

                                {/* Biz Address */}
                                {selectedLang?.addr &&
                                    <ListItem divider={<Divider />} sx={{ paddingX: 0 }}>
                                        <MenuListItem title={selectedLang?.addr} icon={'place'} />
                                    </ListItem>
                                }

                            </List>

                            {bizData.kosherTypeId ?
                                <ListItem divider={<Divider />} sx={{ paddingX: 0 }}>
                                    <MenuListItem
                                        isButton={bizData?.kosherMediaUrl ? t('SHOW_CERTIFICATE') : null}
                                        svg={kosherSvg}
                                        title={t(KOSHER_LIST.find(d => bizData.kosherTypeId === d.id)?.name)}
                                        onClick={() => bizData?.kosherMediaUrl ? setOpenKosherImg(true) : null} />
                                </ListItem>
                                : null
                            }
                            <ToolsButtons />
                        </div>

                        <Accessibility
                            borderColor={theme.palette.clButtons.main}
                            btnBgColor={lighten(theme.palette.primary.main, 0.8)}
                        />

                        {/* Footer (Powered by Molo link) */}
                        <PoweredBy />
                    </div>
                </>
            </SwipeableDrawer>

            {openKosherImg &&
                <CustomModal
                    open={openKosherImg}
                    onClose={() => setOpenKosherImg(false)}
                    title={`${t('BIZ_KOSHER_TITLE')} ${selectedLang?.bizName}`}
                    closeIcon
                    onClickOutsideToClose
                >
                    <img
                        className='w-full h-full object-contain'
                        style={{
                            maxWidth: smScreen ? "100%" : "535px",
                            maxHeight: '60vh',
                        }}
                        src={bizData.kosherMediaUrl}
                        alt='' />
                </CustomModal>
            }
        </>
    )
}

export default AppMenu;