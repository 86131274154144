import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Category from './Category';
import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ORDER_TYPE_ID } from 'configs/consts';

// export const showProduct = (p, isTable, hasOrderMenu, order) => {
//     if (isTable && hasOrderMenu) {
//         return order?.typeId === ORDER_TYPE_ID.PICKUP && p.isTablePickup ||
//             order?.typeId === ORDER_TYPE_ID.SITTING && p.isTableSitting
//     }
//     else if (order?.typeId && hasOrderMenu) {
//         return order?.typeId === ORDER_TYPE_ID.DELIVERY && p.isWebDelivery ||
//             order?.typeId === ORDER_TYPE_ID.PICKUP && p.isWebPickup

//     }
//     else if (!hasOrderMenu)
//         return true
// }

const Categories = React.forwardRef(({ categories, autoScroll, setAutoScroll }, ref) => {
    const { t } = useTranslation();
    //const menuData = useSelector(state => state.menu);
    const headerHeight = useSelector(state => state.appSettings.headerHeight);
    const selectedBadge = useSelector(state => state.searchProd.selectedBadge);
    const selectedProd = useSelector(state => state.searchProd.selectedProd);
    const [lastScrollY, setLastScrollY] = useState(window.scrollY);
    const [isAtBottom, setIsAtBottom] = useState(false);
    const [catLength, setCatLength] = useState([]);

    useEffect(() => {
        if (selectedBadge.length > 0) {
            let list = []
            categories?.map(cat => {
                cat?.items?.map(item => {
                    if (!list.includes(item.id))
                        list.push(item)
                })
            })

            setCatLength(list.filter(item => checkBadges(item.badges)))
        }
    }, [selectedBadge]);

    const onScrollHandle = e => {
        setLastScrollY(e.target.scrollTop);

        /* Check if the user reach the Bottom */
        if (ref?.current)
            setIsAtBottom(ref?.current.scrollHeight - ref?.current.scrollTop === ref?.current.clientHeight);
    };

    const checkBadges = (arr) => {
        const names = arr?.map(obj => obj.name);
        const badges = selectedBadge?.map(obj => obj.name);
        const allTextsExist = badges.every(text => names.includes(text));
        return allTextsExist;
    }

    return (
        categories?.length > 0 &&
        <Stack
            ref={ref}
            onScroll={onScrollHandle}
            paddingY={{ lg: 4 }}
            paddingX={{ xs: 1, lg: 0 }}
            paddingBottom={16}
            className='overflow-scroll scroll-hidden'
            sx={{
                height: `calc(100vh - ${headerHeight}px)`,
                scrollBehavior: 'smooth',
            }}
        >
            {(catLength.length > 0 || selectedBadge.length === 0)
                ? categories?.map((cat, idx) => {
                    //if (!cat.items.some(item => showProduct(item, isTable, hasOrderMenu, order))) return;
                    // Skip if should be hidden because of timing
                    // Todo: move it somewhere else, it calculate on every scroll !!!!
                    const parentCat = categories?.find(menuCat => menuCat.id === cat.pid);
                    if ((parentCat && !parentCat.isTimingVisible) || !cat.isTimingVisible)
                        return;

                    const hasSubCats = categories?.filter(subcat => subcat.pid === cat.id)?.length > 0;
                    const findBadges = cat?.items.filter(item => checkBadges(item.badges))
                    return (
                        (!selectedProd || cat.items.includes(selectedProd)) &&
                        (selectedBadge?.length === 0 || findBadges?.length > 0) &&
                        <Category
                            index={idx}
                            key={cat.id}
                            hasSubCats={hasSubCats}
                            selectedBadge={selectedBadge}
                            //menuId={menuData.menuId}
                            cat={cat}
                            scrollY={lastScrollY}
                            isAtBottom={isAtBottom}
                            autoScroll={autoScroll}
                            setAutoScroll={setAutoScroll}
                        />
                    )
                })
                : <Stack
                    width='100%'
                    height={100}
                    justifyContent='center'
                    alignItems='center'
                    bgcolor='secondary.main'
                    color='primary.main'>
                    <Typography variant='h6' fontWeight='bold'>
                        {t('NO_PRODS')}
                    </Typography>
                </Stack>

            }
        </Stack>
    )
})

export default Categories;