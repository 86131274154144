export const DaysOfWeek = [
    'SUNDAY',
    'MONDAY',
    'TUESDAY',
    'WEDNESDAY',
    'THURSDAY',
    'FRIDAY',
    'SATURDAY',
];

// export const checkBizTiming = (bizData, isOrders, t, dispatch) => {

//     if (!isOrders) return;

//     let timing = bizData?.orderSettings?.timing;

//     let isOpen;
//     if ((!timing || timing?.length === 0) && !isClosedDate(new Date(), bizData)) {
//         isOpen = true;
//         return setToStorage('bizOpen', true);
//     }

//     let nextOpenTime;
//     let today = moment(new Date()).day()+1;

//     let now = moment().format('HH:mm');

//     const getNextDay = (day) => {
//         counter++
//         let closedDt = bizData?.orderSettings?.closedDt;
//         let nextDate;
//         if (counter>5 && closedDt.length>0){
//             closedDt=closedDt.map((d)=>{
//                 return moment(d?.date).format('DD/MM/YY')
//             })
//             let isTommorow=moment(new Date()).add(day, 'days').format('DD/MM/YY')

//             if (closedDt.includes(isTommorow)){

//                 return getNextDay(day)

//             }
//             else {
//                 nextDate = isTommorow
//             }
//             nextOpenTime=`עסק יפתח ב ${nextDate}`
//             return

//         }

//         let nextOpenDay = timing?.filter(time => time?.dow === day + 1)[0] ?? null;
//         let addDaysNum = nextOpenDay?.dow >= today ? day - today : (day - today + 7);
//         const newDate = moment(new Date(), "DD/MM/YY").add(addDaysNum, 'days').format("DD/MM/YYYY")
//         if ((nextOpenDay && !isClosedDate(newDate, bizData)) || timing?.length === 0) {
//             nextOpenTime = `${t("IN_DAY")}${t(DaysOfWeek[day])} ${addDaysNum > 6 ? newDate : ''} ${t("AT_HOUR")} ${timing?.length === 0 ? '00:00' : moment(nextOpenDay?.ftm).format('HH:mm')}`;

//         }
//         else day === 6 ? getNextDay(0,1) : getNextDay(day + 1);


//     }

//     const currDayTiming = timing?.filter(tm => today === tm.dow);
//     if (currDayTiming?.length > 0 && !isClosedDate(new Date(), bizData)) {
//         for (let i = 0; i < currDayTiming.length; i++) {
//             if (now >= moment(currDayTiming[i]?.ftm).format('HH:mm') && now < moment(currDayTiming[i]?.ttm).format('HH:mm')) {
//                 isOpen = true;
//                 break;
//             }
//             else if (currDayTiming?.filter(tm => now < moment(tm?.ftm).format('HH:mm'))?.length > 0) {
//                 isOpen = false
//                 nextOpenTime = t("AT_HOUR") + " " + moment(currDayTiming?.filter(tm => now < moment(tm?.ftm).format('HH:mm'))[0].ftm).format('HH:mm');
//                 break;
//             }
//             else {
//                 isOpen = false
//                 getNextDay(today,1);
//             }
//         }
//     }
//     else {
//         isOpen = false
//         getNextDay(today,1);
//     }


//     setToStorage('bizOpen', isOpen);

//     !isOpen && dispatch(setBizOpenTime(nextOpenTime));
// }


const storage = 'local';//local or session

export const setToStorage = (key, value) => {
    if (typeof window !== 'undefined') {
        if (storage === 'local')
            localStorage.setItem(key, JSON.stringify(value))
        else
            sessionStorage.setItem(key, JSON.stringify(value))
    }
}

export const getFromStorage = (key) => {
    if (typeof window !== 'undefined') {
        if (storage === 'local') {
            let data = localStorage.getItem(key)
            return data ? JSON.parse(data) : ''; //change null to empty string
        }

        else {
            let data = sessionStorage.getItem(key);
            return data ? JSON.parse(data) : ''; // change null to empty string
        }
    }
}

export const removeFromStorage = (keys) => {

    if (typeof window !== 'undefined') {
        if (storage === 'local')
            keys?.map(key => localStorage.removeItem(key))
        else
            keys?.map(key => sessionStorage.removeItem(key))
    }
}