import React from 'react';
import error404 from 'assets/errors/404.jpg'

const Error404 = () => {
    return (
        <div className='flex flex-col h-screen'>
            <img
                src={error404}
                className='w-full h-full object-contain'
                alt="error"
            />
        </div>
    )
}
export default Error404;