import React from 'react';
import { useSelector } from 'react-redux';
import CallForOrder from './CallForOrder';
import ReviewDrawer from './reviews/ReviewDrawer';
import ServiceDrawer from './bizServices/ServiceDrawer';
import Share from './Share';
import Social from './Social';

const ToolsButtons = () => {
    const bizData = useSelector(state => state.bizData);

    return (
        <div className='flex flex-col p-2 pt-8 gap-6'>
            {bizData?.isTable &&
                <div className='grid grid-cols-4 gap-4'>
                    {bizData?.hasServices &&
                        <>
                            <ServiceDrawer type={1} />
                            <ServiceDrawer type={2} />
                        </>
                    }
                    <ReviewDrawer />
                    <Share />
                </div>
            }

            {!bizData?.isTable &&
                <div className='flex flex-col w-full pr-4 space-y-3'>
                    <div><Share isRow /></div>
                    <div>{bizData?.telOrder && <CallForOrder />}</div>
                </div>
            }

            <Social reviews={bizData?.reviews} />


        </div >
    );
}

export default ToolsButtons;