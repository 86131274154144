import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/zoom";
import "swiper/css/thumbs";
import "swiper/css/navigation";
import { Navigation, Thumbs } from "swiper";
import { Modal, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ImgPlaceholder from 'assets/img-placeholder.svg';
import CustomModal from 'components/utils/CustomModal';

const ImageSwiper = ({ imgUrls, theme, bizData, smScreen }) => {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const [imgModalSrc, setImgModalSrc] = useState(null);
    const [imgIndex, setImgIndex] = useState(null)

    useEffect(() => {
        if (imgModalSrc)
            setImgIndex(imgUrls.indexOf(imgModalSrc))

    }, [imgModalSrc])

    return (
        imgUrls && bizData?.hasImgs &&
        <>
            {/* Main Swiper */}
            {imgUrls?.length > 0
                ? <Swiper
                    modules={[Navigation, Thumbs]}
                    navigation={imgUrls?.length > 1}
                    className="prod-swiper cursor-zoom-in"
                    style={{ '--main-bg-color': theme.palette.secondary.main }}
                    slidesPerView={"auto"}
                    zoom={true}
                    thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null }}
                >
                    {imgUrls?.map((img, index) => {
                        let imgSrc = img ?? bizData?.imgLogo ?? ImgPlaceholder;
                        return (
                            <SwiperSlide key={index}>
                                <div className='w-full' style={{ height: '40vh' }}>
                                    <img
                                        src={imgSrc}
                                        onClick={() => setImgModalSrc(imgSrc)}
                                        onError={({ currentTarget }) => {
                                            currentTarget.onerror = null; // prevents looping
                                            currentTarget.src = bizData?.imgLogo ?? ImgPlaceholder;
                                        }}
                                        alt="product image"
                                        className='h-full w-full object-cover'
                                    />
                                </div>
                            </SwiperSlide>
                        )
                    })
                    }
                </Swiper>
                : <img
                    src={bizData?.imgLogo ?? ImgPlaceholder}
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = ImgPlaceholder;
                    }}
                    style={{ height: '40vh' }}
                    alt="product image"
                    className='h-96 w-full object-cover'
                />
            }

            {/* Thumbnails Swiper */}
            {imgUrls?.length > 1 &&
                <Swiper
                    onSwiper={setThumbsSwiper}
                    className="prod-thumb-swiper mt-1 cursor-pointer"
                    style={{ '--swiper-active-color': theme.palette.secondary.main }}
                    slidesPerView={"auto"}
                    spaceBetween={5}
                    watchSlidesProgress
                    modules={[Navigation, Thumbs]}
                >
                    {imgUrls?.map((img, index) => {
                        let imgSrc = img ?? bizData?.imgLogo ?? ImgPlaceholder;
                        return (
                            <SwiperSlide
                                key={index}
                                style={{
                                    width: smScreen ? '70px' : '100px',
                                    height: smScreen ? '70px' : '100px'
                                }}
                            >
                                <img
                                    src={imgSrc}
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; // prevents looping
                                        currentTarget.src = bizData?.imgLogo ?? ImgPlaceholder;
                                    }}
                                    alt="product image"
                                    className='h-full w-full object-cover'
                                />
                            </SwiperSlide>
                        )
                    }
                    )}
                </Swiper>
            }

            {/* <Modal
                open={imgModalSrc !== null}
                onClose={() => setImgModalSrc(null)}
                sx={{ p: 2 }}
                className='h-full flex items-center justify-center'
            >
                <Box
                    className='relative h-80 lg:h-full flex items-center justify-center p-2'
                    sx={{
                        bgcolor: 'secondary.main',
                        minWidth: smScreen ? '350px' : '50vw',
                        maxWidth: '90vw'
                    }}
                >
                    <div className='flex justify-end absolute left-0 top-0 m-2'>
                        <CloseIcon
                            onClick={() => setImgModalSrc(null)}
                            className='cursor-pointer'
                            sx={{ color: 'primary.main' }}
                        />
                    </div>

                    <img
                        src={imgUrls[imgIndex] || imgModalSrc}
                        className='object-contain'
                        style={{ maxHeight: '80vh' }}
                        onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = bizData?.imgLogo;
                        }}
                    />
                </Box>
            </Modal> */}
            <CustomModal
                open={imgModalSrc !== null}
                onClickOutsideToClose
                onClose={() => setImgModalSrc(null)}
                closeIcon
            >
                <img
                    src={imgUrls[imgIndex] || imgModalSrc}
                    className='object-contain'
                    style={{ maxHeight: '80vh', maxWidth: '90vw' }}
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = bizData?.imgLogo;
                    }}
                />
            </CustomModal>
        </>
    )
}

export default ImageSwiper;