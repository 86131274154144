import { createSlice } from '@reduxjs/toolkit'

export const sliceName = 'salesSlice';

export const appSettingsSlice = createSlice({
    name: sliceName,
    initialState: {
        coupon: null,
        sale: null,
    },
    reducers: {
        setCoupon: (state, action) => { state.coupon = action.payload },
        setSale: (state, action) => { state.sale = action.payload },
    },
})

export const { setCoupon, setSale } = appSettingsSlice.actions

export default appSettingsSlice.reducer