import { Alert } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { grey } from '@mui/material/colors';

const NotificationBar = ({
    children,
    color,
    closeBtnColor,
    onClose,
    hideWarningIcon,

}) => {
    return (
        <div className='w-full'>
            <Alert
                sx={{
                    borderRadius: 0,
                    "& .MuiAlert-icon": {
                        marginTop: .5,
                    },
                    "& .MuiAlert-message": {
                        fontSize: '1.5em',
                        textAlign: 'center',
                        width: '100%'
                    }
                }}
                color={color}
                action={
                    onClose &&
                    <IconButton onClick={onClose} >
                        <CloseIcon sx={!closeBtnColor && { color: grey[50] }} color={closeBtnColor} />
                    </IconButton >
                }
                variant="filled"
                severity={!hideWarningIcon && "warning"}
            >
                {children}
            </Alert>
        </div>
    )
}

export default NotificationBar;