import { Box, Typography } from "@mui/material"
import { useSelector } from "react-redux"
import Modifier from "./Modifier"
import { useTranslation } from "react-i18next"
import useCommon from "common/hooks/cartModifiers"
import { useEffect, useRef } from "react"

const ModifiersGroup = ({ id, langs, minQty, maxQty, modifiers, onModifyGroup, isInvalid, touched, seq }) => {
    const { t } = useTranslation();
    const { id: langId } = useSelector(state => state.userSettings.selectedLang);
    const mg = useRef(null);
    //const [invalid, setInvalid] = useState(isInvalid)
    /** 
     * modifiersGroup - creating a minified object with only the necessary properties 
     * to pass on to the add/remove function for further processing.
     * */
    const modifiersGroup = { id: id, langs: langs, minQty: minQty, maxQty: maxQty, touched: touched, seq: seq, modifiers: modifiers };
    const { getModifierGroupType, getModifierGroupRule } = useCommon();

    // const ciModifierGroups = useSelector((state) => state.cart.tempCartItem?.modifierGroups);
    // const ciGroup = ciModifierGroups?.find((g) => g.id === id);

    useEffect(() => {
        isInvalid && mg.current.scrollIntoView({ block: "start", behavior: "smooth" });
    }, [isInvalid])

    let modifierType = getModifierGroupType({ ...modifiersGroup, modifiers: modifiers });

    const rule = getModifierGroupRule(minQty, maxQty);
    const limitation = rule && `${t(rule.text, rule.params)}`;

    // useEffect(() => {
    //     validateModifierGroup(modifiersGroup, ciGroup).then(res => {
    //         setInvalid(res)
    //     });

    // }, [ciGroup])

    const onAddHandler = (modifier) => {
        onModifyGroup(modifiersGroup, modifier, 'ADD');
        //setInvalid(validateModifierGroup(ciGroup))
    }

    const onRemoveHandler = (modifier) => {
        onModifyGroup(modifiersGroup, modifier, 'REMOVE')
        //setInvalid(validateModifierGroup(ciGroup))
    }

    return (
        <div className={`flex flex-col items-center justify-center ${isInvalid && 'blink'}`}
        >
            <Box ref={mg} className="w-full flex items-start flex-col md:flex-row md:items-center px-4 py-2 gap-1"
                sx={{ backgroundColor: 'primary.main' }}
            >
                <Typography
                    className="font-bold"
                    variant="h6"
                    color='secondary.main'
                >
                    {langs?.find((lang) => lang.langId === langId)
                        ?.name
                        || ''//langs[0].name
                    }
                </Typography>

                <Typography
                    variant="h7"
                    color='secondary.main'
                >
                    {limitation}
                </Typography>
            </Box>

            <div
                className={`flex w-full 
                ${(modifierType === 'CHECKBOX')
                        || modifierType === 'RADIO'
                        ? 'flex-row p-3 flex-wrap gap-3'
                        : 'flex-col'}`}
            >
                {modifiers && modifiers.map((modifier, idx) => {
                    const label = modifier.langs?.find((lang) => lang.langId === langId)
                        ?.name
                        || '';//modifier.langs[0].name;
                    /**
                     * cartItemModifier - the modifier that has been selected to add on the product. 
                     * using it inside them Modifier component for display if it was selected and quantity if needed.
                     * */
                    return (
                        <Modifier
                            key={idx}
                            groupId={id}
                            {...modifier}
                            label={label}
                            type={modifierType}
                            //cartItemModifier={ciModifier}
                            onAdd={() => onAddHandler(modifier)}
                            onRemove={() => onRemoveHandler(modifier)}
                        />
                    )
                })
                }
            </div>
        </div>
    )

}
export default ModifiersGroup