import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useTheme } from '@emotion/react';
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const ButtonForIframe = () => {
    const isIframe = useSelector(state => state.appSettings.isIframe);
    const bizData = useSelector(state => state.bizData);
    const navigate = useNavigate();
    const { t } = useTranslation();
    let theme = useTheme();

    return (
        <>
            {isIframe && bizData?.menus?.length > 1 &&
                <div className='w-full p-2 xl:p-2 xl:mb-2 xl:rounded-t-lg' >
                    <Button
                        className="xl:mx-4"
                        variant='contained'
                        startIcon={<KeyboardArrowRightIcon />}
                        onClick={() => {
                            navigate(`/${bizData?.guid}`);
                        }}
                    >
                        {t('MENUS_LIST')}
                    </Button>
                </div>
            }
        </>
    )
}
export default ButtonForIframe;