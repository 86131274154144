import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useTheme } from "@emotion/react";
import { Box, Typography, ListItem, Divider, Zoom } from "@mui/material";
import ChangeBranch from 'components/ChangeBranch';
import StepHeader from '../StepHeader';
import { useTranslation } from 'react-i18next';
import { STEPS } from "..";

const ChooseBranch = ({ prevStep, changeOrderParams, orderParams }) => {
    let { t } = useTranslation();
    let theme = useTheme();
    const bizData = useSelector(state => state.bizData);
    const selectedLang = useSelector(state => state.userSettings.selectedLang);
    const order = useSelector(state => state.userSettings.order);

    useEffect(() => {
        if (bizData?.branches.length === 1)
            !orderParams.branch ? changeOrderParams("branch", bizData?.branches[0]) : prevStep();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Zoom in={order?.step === STEPS.BRANCHES}>
            <>
                <StepHeader title={t('CHOOSE_REST')} />

                {bizData?.branches?.filter(br => br?.sysUrl !== bizData?.guid).map((item, index) => {
                    return (
                        <ChangeBranch wantedBranch={item} key={index}>
                            {index !== 0 && <Divider />}
                            <ListItem
                                key={index}
                                square
                                id={item?.id}
                                className={`w-full p-2 h-full flex relative`}
                                style={{
                                    minWidth: 300,
                                    backgroundColor: theme.palette.primary.main,
                                }}>

                                <div className="flex w-full" >
                                    <Box
                                        className={`flex flex-col h-full w-full cursor-pointer ${selectedLang?.isRtl ? ' mr-2' : ' ml-2'}`}
                                        style={{ color: theme.palette.secondary.main }}>
                                        <div className="flex justify-between items-start">
                                            <Typography className="font-semibold text-xl">
                                                {item?.langs?.filter(lang => lang?.id === selectedLang?.id)[0]?.name}
                                            </Typography>
                                        </div>
                                        <Typography className="text-sm mt-1" dangerouslySetInnerHTML={{ __html: item?.langs?.filter(lang => lang?.id === bizData?.selectedLang?.id)[0]?.addr }} />
                                    </Box>
                                </div>
                            </ListItem>
                        </ChangeBranch>
                    )
                }
                )}
            </>
        </Zoom>
    )
}

export default ChooseBranch;