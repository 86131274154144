import React from 'react';
import { IconButton, Stack, SvgIcon } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import GoogleIcon from '@mui/icons-material/Google';
import { ReactComponent as TRIP_ADVISOR } from 'assets/TRIP_ADVISOR.svg'
import { ReactComponent as ZAP_REST } from 'assets/ZAP_REST.svg'
import { SOCIAL_ID } from 'configs/consts';

const Social = ({ reviews, color }) => {

    return (
        reviews.length > 0 &&
        <Stack direction="row" spacing={1.5} >
            {reviews.map((review, index) => {
                return (
                    <IconButton
                        key={index}
                        onClick={() => window.open(review.url)}>
                        {review.id === SOCIAL_ID?.facebook
                            ? <FacebookIcon
                                color={color ? 'inherit' : 'primary'}
                                fontSize='large' />
                            : review.id === SOCIAL_ID?.instagram
                                ? <InstagramIcon
                                    color={color ? 'inherit' : 'primary'}
                                    fontSize='large' />
                                : review.id === SOCIAL_ID?.tripAdvisor
                                    ? <SvgIcon
                                        sx={{ width: 35, height: 35 }}
                                        color={color ? 'inherit' : 'primary'}
                                        component={TRIP_ADVISOR}
                                        inheritViewBox
                                    />
                                    : review.id === SOCIAL_ID?.zapRest
                                        ? <SvgIcon
                                            sx={{ width: 35, height: 35 }}
                                            color={color ? 'inherit' : 'primary'}
                                            component={ZAP_REST}
                                            inheritViewBox
                                        />
                                        : review.id === SOCIAL_ID?.googleReviews
                                            ? <GoogleIcon
                                                color={color ? 'inherit' : 'primary'}
                                                fontSize='large' />
                                            : null
                        }
                    </IconButton>

                )
            })}

        </Stack>

    )
}

export default Social;