import { versionSys } from "configs/settingsConfig"

const { Link } = require("@mui/material")

const PoweredBy = () => {
    return (
        <Link className={`w-full flex justify-center items-center pb-2`}
            href="https://molomenu.com/"
            target="_blank"
            color="inherit"
        >
            {`Powered by Molo.menu V${versionSys}`}
        </Link>
    )
}
export default PoweredBy