import { Button, Stack, Typography } from "@mui/material";
import Loading from "components/utils/Loading";
import { useEffect, useState } from "react";

const PaymentButton = ({ label, sum, onClick, loading, type, sx, variant }) => {
    const style = { mt: 1 };

    return (
        <Button
            type={type || "button"}
            onClick={onClick}
            variant={variant || "contained"}
            sx={{ ...style, ...sx }}
            disabled={loading}
        >
            {loading
                ? <Loading size={36} />
                : <Stack className="w-full" direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                    <Typography variant='subtitle1'>
                        {label}
                    </Typography>
                    <Typography variant='h6'>
                        {sum}
                    </Typography>
                </Stack>
            }
        </Button>
    )
}
export default PaymentButton;