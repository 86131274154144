import { Button, Stack, SvgIcon, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

const RoundedBtn = ({ title, component, size, onClick, gap, subTitle }) => {
    const [dimensions, setDimentions] = useState({ icon: 32, font: 'subtitle2', btn: 32 });
    const setSize = (size) => {
        if (typeof size === 'object') {
            return { icon: 32, font: 'subtitle2', btn: 32, ...size }
        }
        else {
            switch (size) {
                case 'sm': return { icon: 24, font: 'caption', btn: 24 }
                case 'md': return { icon: 32, font: 'subtitle2', btn: 32 }
                case 'lg': return { icon: 64, font: 'subtitle1', btn: 64 }
                case 'xl': return { icon: 96, font: 'h6', btn: 96 }
                case 'xxl': return { icon: 162, font: 'h5', btn: 162 }
                default: return { icon: 32, font: 'subtitle2', btn: 32 };
            }
        }
    }

    useEffect(() => {
        setDimentions(setSize(size));
    }, [size])

    return (
        <Stack
            direction={'column'}
            alignItems={'center'}
            justifyContent={'center'}
            spacing={gap || 1}
        >
            <Button
                sx={{
                    width: dimensions.btn,
                    height: dimensions.btn,
                }}
                className='rounded-full min-w-0 p-0'
                variant='contained'
                onClick={onClick}
            >
                <SvgIcon
                    sx={{ width: dimensions.icon, height: dimensions.icon }}
                    component={component}
                    inheritViewBox
                />
            </Button>
            <Typography
                color="primary"
                variant={dimensions.font}
                onClick={onClick}
            >
                {title}
            </Typography>
            <Typography
                lineHeight={0}
                color="primary"
                fontWeight='bold'
                variant='body2'
                onClick={onClick}
            >
                {subTitle}
            </Typography>
        </Stack>
    )
}

export default RoundedBtn;