import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { setCart, setOrder } from 'store/userSettingsSlice';
import { STEPS } from 'components/Orders';
import CustomModal from './CustomModal';
import { Typography } from '@mui/material';
import { ReactComponent as WarningIcon } from '../../assets/errors/warning.svg';

const ActionDetector = () => {
    const timerInSec = 15 * 60; // 15 minutes
    let dispatch = useDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const bizData = useSelector(state => state.bizData);
    const order = useSelector(state => state.userSettings.order);
    const [timer, setTimer] = useState(timerInSec);
    const [openDialog, setOpenDialog] = useState(false);

    useEffect(() => {
        document.onkeydown = resetTimer;
        document.onclick = resetTimer;
        window.onload = resetTimer;
        document.onmousemove = resetTimer;
        document.ontouchstart = resetTimer;

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openDialog]);

    function resetTimer() {
        if (!openDialog) {
            setTimer(timerInSec);
        }
    }

    const reset = () => {
        //setTimer(timerInSec)
        dispatch(setCart(null));
        //setOpenDialog(false);
        //navigate(`/${bizData?.guid}`);
    }

    const onCloseHandler = () => {
        dispatch(setOrder());
        setOpenDialog(false);
        setTimer(timerInSec);
        navigate(`/${bizData?.guid}`);
    }

    useEffect(() => {
        if (order.step !== STEPS?.ORDER_TYPE) {
            const timerId = setInterval(() => {
                setTimer(timer - 1);
                if (timer <= 1) {
                    reset()
                    setOpenDialog(true)
                }
            }, 1000)
            // setTimeout(()=>setOpenDialog(true),60000)
            return () => clearInterval(timerId);
        }
    });

    return (
        <div>
            <CustomModal
                open={openDialog}
                RESET_ORDER
                svgIcon={{ component: WarningIcon }}
                actionBtn={{ title: "RESET_ORDER", action: onCloseHandler }}
                onClose={onCloseHandler}
            >
                <div className="flex flex-col justify-center items-center">
                    <Typography variant="h6" color="primary">
                        {t("TIMER_DIALOG_TITLE")}
                    </Typography>
                    <Typography variant="subtitle1" color="primary">
                        {t("TIMER_DIALOG_DESC")}
                    </Typography>
                </div>
            </CustomModal>
        </div>
    )
};

export default ActionDetector;