import { Box, IconButton, Typography } from "@mui/material";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { useEffect, useState } from "react";

const Counter = ({
    onAdd,
    onRemove,
    label,
    hideLabel,
    disableAdd,
    disableRemove,
    quantity,
    reversed,
    size,
}) => {
    const [dimensions, setDimentions] = useState({ icon: '1rem', label: 'subtitle1', counter: 'subtitle1' });

    const setSize = (size) => {
        if (typeof size === 'object') {
            return { icon: '1.25rem', label: 'subtitle1', counter: 'subtitle1', ...size }
        }
        else {
            switch (size) {
                case 'sm': return { icon: '1rem', label: 'subtitle2', counter: 'subtitle2' }
                case 'md': return { icon: '1.25rem', label: 'subtitle1', counter: 'subtitle1' }
                case 'lg': return { icon: '1.5rem', label: 'h6', counter: 'h6' }
                case 'xl': return { icon: '1.75rem', label: 'h5', counter: 'h5' }
                case 'xxl': return { icon: '2.1875rem', label: 'h4', counter: 'h4' }
                default: return { icon: '1.25rem', label: 'subtitle1', counter: 'subtitle1' };
            }
        }
    }

    useEffect(() => {
        setDimentions(setSize(size));
    }, [size])

    return (
        <Box className="flex items-center justify-between gap-1.5">
            {!hideLabel &&
                <Typography variant={dimensions.label}>
                    {label}
                </Typography>
            }
            <Box className={`flex ${!reversed ? 'flex-row' : 'flex-row-reverse'} items-center justify-between gap-1.5`}>

                <IconButton
                    className={disableRemove ? 'opacity-60' : ''}
                    onClick={disableRemove ? null : onRemove}
                    sx={{ width: dimensions.icon, height: dimensions.icon }}
                >
                    <RemoveCircleOutlineIcon
                        sx={{ fontSize: dimensions.icon }}
                    />
                </IconButton>

                <Typography
                    color="primary"
                    variant={dimensions.counter}>
                    {quantity || 0}
                </Typography>

                <IconButton
                    className={disableAdd ? 'opacity-60' : ''}
                    onClick={disableAdd ? null : onAdd}
                    sx={{ width: dimensions.icon, height: dimensions.icon }}
                >
                    <AddCircleOutlineIcon
                        sx={{ fontSize: dimensions.icon }}
                    />
                </IconButton>
            </Box>
        </Box>
    )
}
export default Counter;