import { Box, Divider, IconButton, Typography } from "@mui/material";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import imgPlaceholder from '../../assets/img-placeholder.svg'
import useCommon from "common/hooks/cartModifiers";
import OutOfStock from "components/utils/OutOfStock";
import useCurrency from "hooks/useCurrency";

const ModifierCounter = ({ groupId, id, label, imgUrl, pr, onAdd, onRemove, isOutOfStock, isDefault }) => {
    const { t } = useTranslation();
    const { hasModifierImgs } = useSelector((state) => state.bizData);
    const { currency } = useCurrency();
    // ciModifierGroups - the current modifierGroups from tempCartItem
    // in order to check if the specific modifier has been selected and how many times (selectedQty)
    const ciModifierGroups = useSelector((state) => state.cart.tempCartItem?.modifierGroups);
    const ciGroup = ciModifierGroups?.find((g) => g.id === groupId);
    const { modState, currencyFormat } = useCommon();
    const [mod, setMod] = useState({
        disabled: isOutOfStock,
        showPrice: false,
        totalPrice: 0,
        selectedQty: 0,
    });

    useEffect(() => {
        /** 
         * Each time the qty is changed on the specific modifier, 
         * refreshing the current displayed qty, price, checking the limitations 
         * and disabling the add button accordingly
         * */
        setMod(modState(ciGroup, id));
    }, [ciGroup])

    return (
        <Box className='flex flex-col w-full' disabled={isOutOfStock}>
            <div className='flex w-full justify-between items-center py-2 px-3'>
                <div className='flex items-center jutify-center'>
                    {hasModifierImgs &&
                        <img src={imgUrl || imgPlaceholder}
                            alt='modifier'
                            className='w-14 h-14 object-cover'
                        />
                    }
                    <IconButton
                        className={`mr-2 ${(isOutOfStock || mod.disabled) && 'opacity-50'}`}
                        onClick={() => (isOutOfStock || mod.disabled) ? null : onAdd()}
                        sx={{ width: 20, height: 20 }}
                    >
                        <AddCircleOutlineIcon />
                    </IconButton>

                    {/* Display quantity and remove button  */}
                    {(mod.selectedQty > 0) &&
                        <>
                            <Typography
                                className="mx-2 font-bold"
                                variant='subtitle1'>
                                {mod.selectedQty}
                            </Typography>

                            <IconButton
                                sx={{ width: 20, height: 20 }}
                                onClick={() => onRemove()}
                            >
                                <RemoveCircleOutlineIcon />
                            </IconButton>
                        </>
                    }

                    {(mod.selectedQty === 0 && isDefault) &&
                        <Typography
                            className={`mr-2 font-bold text-red-600`}
                            variant='subtitle1'>
                            {t('WITHOUT')}
                        </Typography>
                    }

                    <Typography
                        className={`mx-2`}
                        variant='subtitle1'>
                        {label}
                    </Typography>

                    {isOutOfStock &&
                        <OutOfStock sx={{ position: 'relative', fontSize: '1rem' }} />
                    }
                </div>
                {mod.showPrice &&
                    <Box className="flex flex-col gap-1">
                        <Typography
                            className="font-bold"
                            variant='subtitle1'
                        >
                            {mod.totalPrice > 0 && `+${currency(mod.totalPrice)}`}
                        </Typography>
                        <Typography
                            variant='subtitle2'
                        >
                            {(mod.totalPrice > pr && pr > 0) && `${currency(pr)} ${t('PER_UNIT')}`}
                        </Typography>
                    </Box>
                }

            </div>
            <Divider sx={{ borderColor: 'primary.main', opacity: 0.6 }} />
        </Box>

    )
}
export default ModifierCounter;