const cart = () => {
    const calcCommission = (aCommissions, sum) => {
        if (sum === 0)
            return 0
        let commission = 0;
        if (aCommissions?.length > 0) {
            const commissions = [...aCommissions].sort((a, b) => a.maxOrderPr - b.maxOrderPr)
            commission = commissions[0]?.pr;
            for (let i = 0; i < commissions?.length; i++) {
                if (sum > commissions[i]?.maxOrderPr) {
                    // If we aren't at the last record, take the next record commission price
                    if (i < commissions?.length - 1)
                        commission = commissions[i + 1]?.pr;

                    // Else take current record commission price
                    else
                        commission = commissions[i]?.pr;
                }
            }
        }
        return commission;
    }

    return {
        calcCommission
    };
};

export default cart;