import { useEffect } from 'react';
// import Loader from 'components/utils/Loader';
import { STEPS } from '..';
import { setCart, setOrder } from 'store/userSettingsSlice';
import { useDispatch, useSelector } from 'react-redux';
import StepHeader from '../StepHeader';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Fade } from '@mui/material';

const CcPayment = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const order = useSelector(state => state.userSettings.order);
    const { t } = useTranslation();
    // const [loading, setLoading] = useState(true);
    const messageListener = (e) => {
        if (e.data?.orderGuid) {
            dispatch(setOrder());
            dispatch(setCart([]));
            navigate(`/order-summary/${e.data?.orderGuid}`);
        }
    }

    useEffect(() => {
        window.addEventListener("message", messageListener);
        return () => window.removeEventListener("message", messageListener);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // useEffect(() => {
    //     if (iframeRef?.current) {
    //         // iframeRef.current?.onload = function () {
    //         //     // document.getElementById('payment').onload = function () {
    //         //     setReady(true);
    //         // }
    //     }
    // }, [iframeRef]);

    return (
        <>
            <StepHeader
                backBtnClick={() => dispatch(setOrder({ step: STEPS.PAYMENT }))}
                title={t('PAY_BY_CARD')}
            />
            <Fade timeout={500} in={true}>
                <iframe
                    title='payment'
                    src={order.ccIframeUrl}
                    // onload={() => setLoading(false)}
                    className='w-full h-auto'
                    style={{ minHeight: '420px' }}
                >
                </iframe>
            </Fade>
        </>
    )
}

export default CcPayment;