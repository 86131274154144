import React, { useState, useEffect, createRef } from 'react';
import Layout from 'containers/Layout';
import CategoryListNav from 'components/Menu/Categories/CategoryListNav';
import Categories, { showProduct } from 'components/Menu/Categories';
import Loading from '../utils/Loading'
import { useSelector, useDispatch } from 'react-redux';
import ProdDetail from 'components/Menu/MenuItem/ProdDetail';
import AdPopUp from 'components/AdPopUp';
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { getMenu, setActiveCats } from 'store/menuSlice';
import InfoModal from 'components/utils/CustomModal';
import Cart from 'components/Cart/Cart';
import CartMobile from 'components/Cart/CartMobile';
import { setOrder, setSelectedCat } from 'store/userSettingsSlice';
import SearchProd from './SearchProd';
import { Stack, useMediaQuery } from '@mui/material';
import { useTheme } from '@emotion/react';
import { STEPS } from 'components/Orders';
import { ORDER_TYPE_ID } from 'configs/consts';

const Menu = () => {
    const { menuId } = useParams();
    const navigate = useNavigate();
    const theme = useTheme();
    const catContainerRef = createRef(null);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const selectedLang = useSelector(state => state.userSettings.selectedLang);
    const bizData = useSelector(state => state.bizData);
    const orderTypeId = useSelector(state => state.userSettings?.order?.typeId);
    const { activeCats, loadingProduct, cats } = useSelector(state => state.menu);
    const isLoading = useSelector(state => state.menu.isLoading);
    const isIframe = useSelector(state => state.appSettings.isIframe);
    const selectedBadge = useSelector(state => state.searchProd.selectedBadge);
    const [autoScroll, setAutoScroll] = useState(false);
    const xl = useMediaQuery(theme.breakpoints.up('xl'));

    const deviceType = () => {
        if (typeof window === 'undefined') return
        const ua = navigator.userAgent;
        if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua))
            return 4//"tablet";
        else if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua))
            return 4//"mobile";
        return 3//"desktop";
    };

    useEffect(() => {
        // const platformId = platform === 'kiosk' ? 5 : deviceType();

        /* Todo:// do we need it? 
         if we don't have menuId in Url Params, get the first menu from bizData */
        // const menu = menuId ?? bizData?.menus[0]?.id;

        const findMenu = bizData?.menus.find(menu => menu?.id === parseInt(menuId))
        if (findMenu) {
            dispatch(getMenu({
                langId: selectedLang?.id,
                menuId: menuId,
                platformId: deviceType()
            }));
        }
        else {
            dispatch(setOrder({ step: STEPS.MENU_LIST }));
            navigate(`/${bizData?.guid}`);
        }
    }, []);

    const showProduct = (product) => {
        if (!bizData?.hasOrderMenu || isIframe || !orderTypeId)
            return true
        else if (bizData?.isTable) {
            return orderTypeId === ORDER_TYPE_ID.PICKUP && product.isTablePickup ||
                orderTypeId === ORDER_TYPE_ID.SITTING && product.isTableSitting
        }
        else {
            return orderTypeId === ORDER_TYPE_ID.DELIVERY && product.isWebDelivery ||
                orderTypeId === ORDER_TYPE_ID.PICKUP && product.isWebPickup
        }
    }

    useEffect(() => {
        if (cats?.length > 0) {
            const filteredCats = cats
                ?.map((category) => {
                    return {
                        ...category,
                        items: category?.items?.filter((prod) => {
                            return showProduct(prod)
                        })
                    }
                })
                .filter(f => f.items.length > 0 || f.hasSubCats)

            dispatch(setActiveCats(filteredCats))
        }
    }, [orderTypeId, cats])

    useEffect(() => {
        if (activeCats?.length > 0)
            dispatch(setSelectedCat(activeCats[0]))
    }, [activeCats]);

    const checkBadges = (arr) => {
        const names = arr?.map(obj => obj.name);
        const badges = selectedBadge?.map(obj => obj.name);
        return badges?.every(text => names.includes(text));
    }

    return (
        <Layout
            headerChildren={
                <div className='block lg:hidden w-full'>
                    <CategoryListNav
                        checkBadges={checkBadges}
                        setAutoScroll={setAutoScroll}
                        categories={activeCats}
                        catContainerRef={catContainerRef}
                    />

                    <SearchProd />
                </div>
            }
        >
            {loadingProduct
                ? <Stack
                    width={'100vw'}
                    height={'100vh'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    position={'absolute'}
                    top={0}
                    right={0}
                    bgcolor={'rgba(0,0,0,0.33)'}
                    zIndex={999}
                >
                    <Loading />
                </Stack>
                : null
            }
            <div className={`flex flex-col h-full w-full overflow-hidden container mx-auto pt-4`}>
                {(activeCats && !isLoading) ?
                    <>
                        {activeCats.length > 0 ?
                            <>
                                <AdPopUp typeId={1} />

                                <div className='grid grid-cols-12 gap-4 xl:gap-8'>
                                    <div className='col-span-12 lg:col-span-3 xl:col-span-3 hidden lg:block'>

                                        <CategoryListNav
                                            checkBadges={checkBadges}
                                            setAutoScroll={setAutoScroll}
                                            categories={activeCats}
                                            catContainerRef={catContainerRef}
                                        />
                                    </div>

                                    <div className='col-span-12 lg:col-span-9 xl:col-span-5'>
                                        <Categories
                                            categories={activeCats}
                                            autoScroll={autoScroll}
                                            setAutoScroll={setAutoScroll}
                                            ref={catContainerRef}
                                        />
                                    </div>

                                    {isIframe
                                        ? null
                                        :
                                        xl ?
                                            <div className='col-span-12 lg:col-span-3 xl:col-span-4'>
                                                <Cart />
                                            </div>
                                            :
                                            null
                                    }

                                    <ProdDetail />
                                </div>
                            </>
                            :
                            <InfoModal
                                open={true}
                                msg={t('NO_MENUS')}
                            />
                        }

                    </>
                    : <div className='flex items-center justify-center w-full h-full'>
                        <Loading />
                    </div>
                }
            </div>
            {isIframe
                ? null
                :
                xl
                    ? null
                    :
                    <div className='sticky bottom-0'>
                        <CartMobile />
                    </div>
            }
        </Layout>
    )
}
export default Menu;