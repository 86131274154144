import { useSelector } from 'react-redux';
import { useTheme } from '@emotion/react';
import CartItems from './CartItems';
import { useTranslation } from 'react-i18next';
import { Box, Card, Stack, Typography } from '@mui/material';
import OrderTypes from '../OrderTypes';

const Cart = (CloseDrawer) => {
    let theme = useTheme();
    const { t } = useTranslation();
    const hasOrderMenu = useSelector(state => state.bizData.hasOrderMenu);
    const menuWithOrder = useSelector(state => state.menu.canOrder);
    const order = useSelector(state => state.userSettings.order);

    return (
        <Stack
            alignItems='center'
            paddingTop={2}
            gap={2}
        >

            <OrderTypes />

            <Stack
                alignItems='center'
                bgcolor='secondary.main'
                width='100%'
                padding={2}
                border={`1px solid ${theme.palette.primary.main}`}
            >

                {/* Cart Title */}
                <Typography variant="h5" color="primary" className='font-bold'>
                    {hasOrderMenu && menuWithOrder && order.typeId > 0
                        ? t('CART')
                        : t('FAVORITES_LIST')
                    }
                </Typography>

                {/* Cart Items */}
                <Box className='w-full' >
                    <CartItems CloseDrawer={CloseDrawer} />
                </Box>
            </Stack>
        </Stack>
    )
}

export default Cart;