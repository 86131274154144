import { Button, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from 'react-router-dom';
import chefDish from 'assets/CHEF_DISH.svg'
import { useTranslation } from "react-i18next";
import { setOrder } from 'store/userSettingsSlice';
import { STEPS } from "./Orders";

const { default: CustomModal } = require("./utils/CustomModal")

const Overload = ({ open, setOpen }) => {
    const dispacth = useDispatch()
    const { guid } = useParams();
    let { t } = useTranslation();
    const bizData = useSelector(state => state.bizData);

    return (
        <CustomModal
            open={open}
            onClose={() => { }}
        >
            <div className='flex flex-col items-center gap-4'>

                <img src={chefDish} className='w-44' />

                <Typography color='primary' variant='h5' className='font-bold'>
                    {t("BIZ_IS_OVERLOAD")}
                </Typography>
                <Typography color='primary' variant='h6' className='font-bold'>
                    {t("ORDER_LITTLE_LATER")}
                </Typography>

                <div className={`flex w-full justify-center mt-10`}>

                    {/* View Menu button */}

                    <Button
                        variant="contained"
                        onClick={() => {
                            dispacth(setOrder({ step: STEPS.MENU_LIST, typeId: null }))
                            setOpen(false)
                        }}
                    >
                        {t('VIEW_MENU')}
                    </Button>
                </div>
            </div>
        </CustomModal>
    )
}
export default Overload