import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setCoupon } from "store/salesSlice";
import { setSelectedBadge, setSelectedProd } from "store/searchProdSlice";
import { setCart, setOrder, setSelectedCat } from "store/userSettingsSlice";

export const useMenu = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const guid = useSelector(state => state?.bizData?.guid);

    const resetMenu = (bizGuid) => {
        dispatch(setSelectedBadge([]));
        dispatch(setSelectedCat(null));
        dispatch(setSelectedProd(null));
        dispatch(setCart(null));
        dispatch(setOrder());
        dispatch(setCoupon(null));
        navigate(`/${bizGuid || guid}`);
    };

    return { resetMenu };
}