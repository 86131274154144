import React, { useState } from 'react';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { useTheme } from '@emotion/react';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import ReceiptIcon from '@mui/icons-material/Receipt';
import Service from './Service';
import { useTranslation } from 'react-i18next';

const ServiceDrawer = ({ iconSize, type }) => {
    let theme = useTheme();
    let { t } = useTranslation();
    const [open, setOpen] = useState(false);

    const CloseHandle = async () => setOpen(false);

    return (
        <>
            <div
                className='flex flex-col justify-center items-center cursor-pointer space-y-2'
                onClick={() => setOpen(true)}
            >
                {type === 1 ?
                    <NotificationsActiveIcon
                        fontSize={iconSize ?? 'medium'}
                    /> :
                    <ReceiptIcon
                        fontSize={iconSize ?? 'medium'}
                    />
                }
                <p className='text-xs'>{type === 1 ? t('ASK_FOR_WAITER') : t('ASK_FOR_BILL')}</p>
            </div>

            <SwipeableDrawer
                anchor='bottom'
                disableSwipeToOpen
                open={open}
                onClose={() => { }} // Just to make the errorin console go away (mandatory attribute)
                onOpen={() => setOpen(true)}
                PaperProps={{
                    sx: { background: 'primary.main', height: '100%' },
                }}
            >
                {/* Run service only if Swiper is open
                    (we need that so it won't ask for permission every time it load) */}
                {open &&
                    <Service onClose={CloseHandle} requestType={type} />
                }
            </SwipeableDrawer>
        </>
    )
}

export default ServiceDrawer;