import { useTheme } from "@emotion/react";
import { Box, Stack, Typography, useMediaQuery } from "@mui/material";
import ImgPlaceholder from 'assets/img-placeholder.svg';
import { forwardRef, useEffect, useRef } from "react";
import { useSelector } from "react-redux";

const CategoryListItem = forwardRef(({ selected, catImg, id, name, onClick }, ref) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    // const catRef = useRef();
    const bizData = useSelector(state => state.bizData);
    const mdScreen = useMediaQuery(theme.breakpoints.down('md'));

    // useEffect(() => {
    //     if (selected)
    //         catRef.current.scrollIntoView({ behavior: 'smooth' })
    // }, [selected]);

    return (
        <Stack
            direction={{ lg: "row" }}
            alignItems='center'
            width={mdScreen ? '130px' : 'auto'}
            className={`cursor-pointer`}
            sx={{
                backgroundColor: selected
                    ? theme.palette.clButtons.main
                    : theme.palette.secondary.main
                ,
                border: `1px solid ${mdScreen ? theme.palette.primary.main : theme.palette.secondary.main}`
            }}
            key={id}
            // ref={catRef}
            ref={ref}
            onClick={onClick}
        >
            {/* Category Image */}
            <div className='h-20 w-32 lg:w-44 lg:h-24'>
                <img
                    className='h-full w-full object-cover sticky'
                    src={catImg ?? bizData?.imgLogo ?? ImgPlaceholder}
                    alt="category image"
                />
            </div>

            {/* Category Name */}
            <Typography
                variant="subtitle1"
                fontWeight={isMobile ? 'bold' : 900}
                color={selected ? 'secondary' : 'primary'}
                // sx={{
                //     color: currCategory
                //         ? theme.palette.secondary.main
                //         : theme.palette.primary.main,
                //     fontWeight: currCategory
                //         ? 'bold'
                //         : '600'
                // }}
                className='block truncate text-center lg:text-start p-2 w-full'
            >
                {name}
            </Typography>
        </Stack>
    )
})
export default CategoryListItem;