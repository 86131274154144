import MenuItem from "components/Menu/MenuItem";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux"
import { setActiveUpsale, setProducts, setUpsalesList } from "store/upsalesSlice";
import { UPSALE_TYPE_ID } from "configs/consts";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material"

const Upsale = ({ finishOrder }) => {
    const theme = useTheme()
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const { products, list, activeUpsale, isOpen } = useSelector(state => state.upsales);
    const menu = useSelector(state => state.menu);
    const selectedLang = useSelector(state => state.userSettings.selectedLang);
    const cartItems = useSelector(state => state.cart.cartItems);
    const [upsaleItems, setUpsaleItems] = useState([]);

    useEffect(() => {
        if (list?.length > 0) {
            if (products?.typeId === UPSALE_TYPE_ID.ADD_TO_CART) {
                upsaleData(products, products?.typeId)
            }
            else if (products?.typeId === UPSALE_TYPE_ID.COMPLETE_ORDER) {
                if (cartItems?.length > 0) {
                    const upsales = list.filter(upsale => upsale.linkedItems?.length === 0 && !upsale.isShow);
                    let itemsFound = [];

                    upsales?.forEach(u => {
                        cartItems.forEach(ci => {
                            const foundItem = u.items.find(f => f.id === ci.id && f.catId === ci.catId)
                            if (foundItem)
                                itemsFound.push(foundItem)
                        })
                    });

                    const res = checkUpsaleIncartItems()

                    if (upsales.length > 0 && itemsFound.length === 0) {

                        dispatch(setActiveUpsale(upsales[0]))
                        let updatedUpsales = [...list]?.filter(sale => sale?.id !== upsales[0].id)
                        updatedUpsales.push({ ...upsales[0], isShow: true })
                        dispatch(setUpsalesList(updatedUpsales))

                        const items = upsales[0].items?.map((prod, idx) => {
                            const itm = findProductInCat(prod);
                            return (itm
                                ? <MenuItem key={idx} product={itm} catId={itm.catId} />
                                : null
                            )
                        });
                        setUpsaleItems(items);
                    }
                    else if (res) {
                        upsaleData(res, products?.typeId)
                    }
                    else {
                        resetUpsale()
                    }

                }
                // if (cartItems?.length > 0) {
                //     const res = checkUpsaleIncartItems()
                //     if (res) {
                //         upsaleData(res, products?.typeId)
                //     }
                //     else {
                //         resetUpsale()
                //     }
                // }
            }
        }
        else {
            if (products?.typeId === UPSALE_TYPE_ID.COMPLETE_ORDER) {
                resetUpsale()
            }
        }
    }, [products])

    const checkUpsaleIncartItems = () => {
        let prod = null
        for (let index = 0; cartItems.length > index; index++) {
            const item = cartItems[index];
            const allUpsaleFinish = checkFinishUpsale(item, products?.typeId)
            if (!allUpsaleFinish)
                prod = item
        }

        return prod
    }

    const checkFinishUpsale = (prod, typeId) => {
        let finish = true

        for (let i = 0; i < list.length; i++) {
            const upsale = list[i]
            const prodsIds = upsale?.items?.map(item => { return (item?.id) })

            if (typeId === upsale?.typeId && !upsale?.isShow) {
                if (typeId === UPSALE_TYPE_ID.COMPLETE_ORDER && prod === null) {
                    finish = false
                }

                if (prodsIds?.includes(prod?.id)) {
                    finish = false
                }
            }
        }
        return finish
    }

    const upsaleData = (prod, typeId) => {
        for (let i = 0; i < list.length; i++) {
            const upsale = list[i]
            const upsaleTriggers = upsale?.linkedItems?.map(item => { return (item?.id) })
            if (typeId === upsale?.typeId && !upsale?.isShow) {
                if (upsale.linkedItems?.length <= 0) {
                    console.log('no linked items')
                }
                else if (upsaleTriggers?.includes(prod?.id)) {
                    dispatch(setActiveUpsale(upsale))

                    if (typeId === UPSALE_TYPE_ID.COMPLETE_ORDER) {
                        const arr = [...list]
                        let newList = arr?.filter(sale => sale?.id !== upsale?.id)
                        newList.push({ ...upsale, isShow: true })
                        dispatch(setUpsalesList(newList))
                    }

                    const items = upsale?.items?.map((prod, idx) => {
                        const itm = findProductInCat(prod);
                        return (itm
                            ? <MenuItem key={idx} product={itm} catId={itm.catId} />
                            : null
                        )
                    });
                    setUpsaleItems(items);
                    break;
                }
            }
        }
        // for (let i = 0; i < list.length; i++) {
        //     const upsale = list[i]
        //     const prodsIds = upsale?.items?.map(item => { return (item?.id) })

        //     if (typeId === upsale?.typeId && !upsale?.isShow) {
        //         if (prodsIds?.includes(prod?.id)) {
        //             if (upsale?.linkedItems?.length > 0) {
        //                 dispatch(setActiveUpsale(upsale))

        //                 if (typeId === UPSALE_TYPE_ID.COMPLETE_ORDER) {
        //                     const arr = [...list]
        //                     let newList = arr?.filter(sale => sale?.id !== upsale?.id)
        //                     newList.push({ ...upsale, isShow: true })
        //                     dispatch(setUpsalesList(newList))
        //                 }

        //                 const linkedItems = upsale?.linkedItems.map((item, idx) => {
        //                     const itm = findProductInCat(item);
        //                     return (itm
        //                         ? <MenuItem key={idx} product={itm} catId={itm.catId} />
        //                         : null
        //                     )
        //                 });
        //                 setUpsaleItems(linkedItems);
        //             }
        //             break;
        //         }
        //     }
        // }
    }

    const findProductInCat = (prod) => {
        const findCat = menu?.cats?.find(cat => cat?.id === prod?.catId)
        if (findCat) {
            return {
                ...findCat?.items?.find(p => p?.id === prod?.id),
                catId: findCat.id
            };
        }

        //        for (const cat of menu?.cats) {
        //     const prod = cat.items?.find(f => f.id === prodId);
        //     if (prod) {
        //         return {
        //             ...prod,
        //             catId: cat.id
        //         };
        //     }
        // }
    }

    const onCloseHandler = () => {
        if (products.typeId === UPSALE_TYPE_ID.COMPLETE_ORDER) {
            if (cartItems?.length > 0) {
                const res = checkUpsaleIncartItems()
                if (res) {
                    upsaleData(res, products?.typeId)
                }
                else {
                    resetUpsale()
                }
            }
        }
        else {
            resetUpsale()
        }
    }

    const resetUpsale = () => {
        dispatch(setProducts(null))
        dispatch(setActiveUpsale(null))

        if (products.typeId === 2)
            finishOrder()
    }

    return (
        <Dialog
            sx={{ padding: 0 }}
            fullScreen={isMobile ? true : false}
            open={isOpen} >
            {/* <Stack
            // sx={{ border: (theme) => `2px solid ${theme.palette.clButtons.main}`, margin: 2 }}
            > */}
            <DialogTitle>
                <Stack minWidth='60%' direction='row' justifyContent='center'>
                    <Typography variant={isMobile ? "subtitle1" : "h5"} fontWeight={900}>
                        {activeUpsale?.langs?.length > 0
                            ? activeUpsale?.langs?.find(lng => lng?.id === selectedLang?.id)?.name
                            : null
                        }
                    </Typography>
                </Stack>
            </DialogTitle>

            <DialogContent>
                <Stack
                    sx={(theme) => theme.scrollBar}
                    maxHeight={isMobile ? '80%' : '80%'}
                    minWidth={isMobile ? '100%' : '500px'}>
                    {upsaleItems}
                </Stack>
            </DialogContent>

            <DialogActions >
                <Stack width='100%' paddingY={1} alignItems='center'>
                    <Button size="large" onClick={() => onCloseHandler()} >
                        {t('NEXT')}
                    </Button>
                </Stack>
            </DialogActions>
            {/* </Stack > */}
        </Dialog >
    )
}
export default Upsale