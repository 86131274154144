import Lottie from "react-lottie-player";
import lottieJson from "assets/loading.json";

const Loading = ({ size }) => {
  return (
    <div
      className={`flex items-center justify-center rounded-full w-20 h-20 bg-white`}
      style={
        size && { width: size, height: size }
      }
    >
      <Lottie loop animationData={lottieJson} play className="w-full h-full" />
    </div>
  );
};

export default Loading;