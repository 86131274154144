import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getBiz } from 'store/bizDataSlice';
import { setIsIframe } from 'store/appSettingsSlice';
import { setUpsalesList } from 'store/upsalesSlice';

function ApiDataProvider({ children }) {
    const dispatch = useDispatch();
    const bizData = useSelector(state => state.bizData);
    const { guid } = useParams();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        if (bizData?.guid != null)
            return;

        /** Biz not found */
        if (bizData === -1)
            navigate('/');

        const langUrlParam = searchParams.get('lang');

        if (bizData?.guid == null) {
            dispatch(getBiz({
                guid: guid,
                langId: langUrlParam?.toLowerCase()
            })).then(data => {
                dispatch(setUpsalesList(data.payload?.upsales))
            });

        }

        if (searchParams.get('iframe'))
            dispatch(setIsIframe(true));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (children)
}

export default ApiDataProvider;