import settingsConfig, { versionSys } from "configs/settingsConfig";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setShowErorUpdatePopup } from "store/updateAppSlice";
import CustomModal from "./utils/CustomModal";
import { t } from "i18next";

const signalR = require("@microsoft/signalr");
const SignalRUpdateApp = () => {
    const dispatch = useDispatch()
    const showErorUpdatePopup = useSelector(state => state?.updateApp?.showErorUpdatePopup);
    const bizData = useSelector(state => state.bizData);
    const [hubConn, setHubConn] = useState()

    useEffect(() => {
        createHub();
        return () => closeConn();
    }, []);

    const closeConn = async () => {
        if (hubConn)
            hubConn.close();
        setHubConn(null);
    }

    const reload = () => {
        window.location.reload();
    }

    const createHub = async () => {
        try {
            const conn = new signalR.HubConnectionBuilder()
                .withUrl(settingsConfig.apiUrl + "systemHub")
                .withAutomaticReconnect()
                .build();
            setHubConn(conn);
            await conn.start();

            conn?.on("sysCheckVer", async (data) => {
                const findBo = data?.find(d => d?.id === 'ClientVer')
                const oldVer = versionSys.split('.')
                const newVer = findBo?.value?.split('.')

                if (parseInt(newVer[0]) === parseInt(oldVer[0])) {
                    localStorage.removeItem('appVerCheck')
                    dispatch(setShowErorUpdatePopup(false));
                }

                if ((parseInt(newVer[0]) > parseInt(oldVer[0]) ||
                    parseInt(newVer[1]) > parseInt(oldVer[1]))) {
                    let boVerCheckFromLs = localStorage.getItem('appVerCheck')
                    boVerCheckFromLs = parseInt(boVerCheckFromLs)
                    localStorage.setItem('appVerCheck', boVerCheckFromLs ? boVerCheckFromLs + 1 : 1)

                    if (boVerCheckFromLs <= 3) {
                        reload();
                    }
                    else if (boVerCheckFromLs >= 2) {
                        dispatch(setShowErorUpdatePopup(true));
                    }
                }
            });
        }
        catch (e) {
            console.error('SignalR Hub Connections Error', e);
        }
    }

    return (
        showErorUpdatePopup
            ? <CustomModal
                open={showErorUpdatePopup}
                img={bizData?.imgLogo}
                msg={t('ERR')}
                actionBtn={{ title: t('ACCEPT'), action: () => reload() }}
            />
            : null

    )
}

export default SignalRUpdateApp