import { Button, Checkbox } from "@mui/material"
import { useSelector } from "react-redux"
//import { CHECKBOX } from "../../utils/constants";
import { useEffect, useState } from "react";
import imgPlaceholder from '../../assets/img-placeholder.svg'
import { useTranslation } from "react-i18next";
import OutOfStock from "components/utils/OutOfStock";
import useCurrency from "hooks/useCurrency";

const ModifierButton = ({ groupId, id, label, imgUrl, pr, onAdd, onRemove, isOutOfStock, isDefault, freeQty }) => {
    const { t } = useTranslation();
    const { currency } = useCurrency();
    const { hasModifierImgs } = useSelector((state) => state.bizData);
    // ciModifierGroups & ciGroup - the current tempCartItem modifiers info
    // to determine if the displayed modifier should be selected
    const ciModifierGroups = useSelector((state) => state.cart.tempCartItem?.modifierGroups);
    const ciGroup = ciModifierGroups?.find((g) => g.id === groupId);
    const [selected, setSelected] = useState(false);
    //const variant = selected ? "contained" : "outlined"

    //const variant = isOutOfStock ? "contained" : "outlined"
    //const [otherSelected, setOtherSelected] = useState(false);
    useEffect(() => {
        setSelected(false);
        ciGroup?.modifiers?.forEach((mod) => {
            if (mod.id === id && mod.qty === 1) {
                setSelected(true);
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ciGroup])

    const onClickHandler = () => {
        selected ? onRemove() : onAdd();
    }

    return (
        <Button
            variant={"outlined"}
            //className={`rounded-sm pr-2 ${selected && 'border-4'}`}
            className={`rounded-sm w-full md:w-auto justify-between md:justify-center pr-2 ${isOutOfStock && 'h-16'}`}
            endIcon={
                <>
                    {(pr > 0 && freeQty === 0) && `${currency(pr)}`}
                </>
            }
            onClick={onClickHandler}
            disabled={isOutOfStock}
        >
            <div className="flex gap-2 items-center">
                <Checkbox
                    disabled={isOutOfStock}
                    checked={selected}
                    onChange={onClickHandler}
                    sx={{ padding: 0, '& .MuiSvgIcon-root': { fontSize: 32 } }}
                />
                {hasModifierImgs &&
                    <img src={imgUrl || imgPlaceholder} alt={label} className={`w-14 h-14 object-cover`} />
                }
                {(isDefault &&
                    !selected &&
                    ciGroup?.modifiers?.findIndex((mod) => mod.id === id) > -1) &&
                    <span className={`font-bold text-red-600`} >{t('WITHOUT')}</span>
                }
                <span>{label}</span>
            </div>
            {isOutOfStock &&
                <OutOfStock sx={{ bottom: '3px', right: '3px' }} />
            }
        </Button>
    )
}
export default ModifierButton;