import React, { useState, useEffect, useRef } from "react";
import { Typography, IconButton, Card, Stack, useTheme, useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import EditIcon from '@mui/icons-material/Edit';
import { getProduct } from 'store/productSlice';
import { editCartItem, updateCartItem } from 'store/cartSlice';
import useCommon from "common/hooks/cartModifiers";
import { useTranslation } from "react-i18next";
import Counter from "components/utils/Counter";
import { Box } from "@mui/system";
import { UPSALE_TYPE_ID } from "configs/consts";
import { setProducts } from "store/upsalesSlice";
import useCurrency from "hooks/useCurrency";

const CartItem = ({ product, removeFromFav, CloseDrawer, bg, animate }) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { currency } = useCurrency();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const bizData = useSelector(state => state.bizData);
    const selectedLang = useSelector(state => state.userSettings.selectedLang);
    const ciRef = useRef();
    const [blink, setBlink] = useState(false);

    const onScrollEnd = () => {
        const timer = setTimeout(() => {
            setBlink(true)
        }, 300);
        return () => clearTimeout(timer);
    }

    useEffect(() => {
        setBlink(false);
        if (animate && ciRef.current) {
            ciRef.current.scrollIntoView({
                behavior: "smooth",
                block: 'start',
                onscrollend: onScrollEnd()
            });
        }
    }, [animate, product]);

    const openItemDetails = () => {
        window.history.pushState('product', null, window.location.href);
        dispatch(getProduct({
            bizId: bizData?.id,
            itemId: product?.id,
            langId: selectedLang?.id,
            guid: bizData?.guid,
            catId: product?.catId
        }));
        dispatch(editCartItem({ product: null, cartItem: product }));
        CloseDrawer && CloseDrawer();
    }

    // const duplicateItem = (e) => {
    //     dispatch(addCartItem(product));
    //     dispatch(setCart([]));
    // }

    const onRemoveHandler = () => {
        if (product.qty === 1)
            removeFromFav()
        else {
            const updatedCartItem = { ...product, qty: product.qty - 1 }
            dispatch(updateCartItem(updatedCartItem));
        }
    }

    const onAddHandler = () => {
        const updatedCartItem = { ...product, qty: product.qty + 1 }
        dispatch(updateCartItem(updatedCartItem));
        dispatch(setProducts({ ...product, typeId: UPSALE_TYPE_ID.ADD_TO_CART }))
    }
    return (
        <Box
            sx={{ scrollMarginTop: '0.5rem' }}
            ref={ciRef}
        >
            <Card
                id={`cart-item-${product.guid}`}
                className={`flex flex-col w-full 
                my-2 p-2 bg-transition border ${blink ? 'blink' : ''}
            `}
                elevation={0}
                sx={{
                    color: 'primary.main',
                    border: '1px solid',
                    borderColor: 'primary.main',
                    backgroundColor: 'secondary.main',
                }}
            >

                {/* Cart item name & buttons & total price */}
                <div className="flex justify-between items-start w-full h-full">
                    <Typography
                        className="w-full"
                        variant="subtitle1"
                        fontWeight={isMobile ? 'bold' : 900}>
                        {product?.numInMenu && <span>{product.numInMenu}.</span>}
                        {/* {`${product?.name} ${product.qty}x `} */}
                        {`${product?.name}`}
                    </Typography>

                    {/* Delete, Add, Edit, Duplicate and buttons */}
                    <div className={`flex`}>
                        {!product?.isSimple &&
                            <IconButton
                                aria-label="edit"
                                size="small"
                                onClick={openItemDetails}>
                                <EditIcon />
                            </IconButton>
                        }

                        <Counter
                            size='lg'
                            quantity={product.qty}
                            onRemove={onRemoveHandler}
                            onAdd={onAddHandler}
                        />
                    </div>
                </div>

                <div className="flex justify-start flex-col">
                    {/* {bizData?.hasImgs &&
                    <div className=' w-1/4'>
                        <img
                            src={product?.imgUrl ?? bizData?.imgLogo ?? ImgPlaceholder}
                            className='w-14 h-14 object-cover border border-slate-300 m-2 '
                            loading="lazy"
                            // style={{ width: 40, height: 40 }}
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = bizData?.imgLogo ?? ImgPlaceholder;
                            }} />
                    </div>
                } */}
                    <div className="w-full px-3">
                        {product?.modifierGroups?.map((group, idx) => {
                            return (
                                <div key={idx} className="text-sm" >
                                    <span className="font-bold">{group?.langs[0]?.name}: </span>

                                    {/* <span className="font-bold">{group?.langs.name}: </span> */}
                                    {group?.modifiers?.map((modifier, idx) => {
                                        return (
                                            <span key={modifier.id}>
                                                {modifier?.qty === 0 &&
                                                    <span className={`font-bold text-red-600`} >{t('WITHOUT')} </span>
                                                }
                                                {modifier?.qty > 1 &&
                                                    <span>x{modifier?.qty} </span>
                                                }
                                                <span>{`${modifier?.name}${group?.modifiers?.length - 1 > idx ? ', ' : ''}`} </span>
                                            </span>
                                        )
                                    }
                                    )}
                                </div>
                            )
                        }

                        )}
                    </div>

                    <Stack
                        direction='row'
                        justifyContent={product?.belongsTo ? 'space-between' : 'end'}>
                        {product?.belongsTo &&
                            <Typography
                                className="truncate"
                                fontSize={16}
                                fontWeight='bold'>
                                {`${t('BELONGS_TO')} - ${product?.belongsTo}`}
                            </Typography>
                        }
                        {product?.totalPrice > 0 &&
                            <Typography variant="h6">
                                {`${currency(product?.totalPrice)}`}
                            </Typography>
                        }
                    </Stack>
                </div>
            </Card >
        </Box>
    )
}

export default CartItem;