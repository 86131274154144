import React from 'react';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import { useSelector } from 'react-redux';
import { useTheme } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

const Share = ({ isRow = false }) => {
    const guid = useSelector(state => state.bizData?.guid);
    let theme = useTheme();
    let { t } = useTranslation();

    const OnShareHandle = () => {
        /* Will work properly on HTTPS only!
           (Won't work properly on local dev, unless we have HTTPS environment) */
        window.navigator.share({
            title: t('SHARE'),
            text: t('SHARE_MSG'),
            url: window.location.origin + '/' + guid
        })
    }

    return (
        window.navigator.share &&
        <div
            onClick={OnShareHandle}
            className={`flex ${isRow ? 'flex-row' : 'flex-col justify-center space-y-2'} cursor-pointer items-center `}
        >
            <ShareOutlinedIcon
                style={{ color: theme.palette.primary.main }}
            />
            <Typography color="primary" variant='subtitle1' className='mr-2'>{t('SHARE')}</Typography>
            {/* 
            <p
                className={`text-xs ${isRow && 'mr-2'}`}
                style={{ color: theme.palette.primary.main }}
            >
                {t('SHARE')}
            </p> */}
        </div>
    )
}

export default Share;