import { createSlice } from '@reduxjs/toolkit';

let initialState = {
	showErorUpdatePopup: false,
};

const updateApp = createSlice({
	name: 'tabletNotSyncedCount',
	initialState,
	reducers: {
		setShowErorUpdatePopup: (state, action) => { state.showErorUpdatePopup = action.payload },
	}
});

export const { setShowErorUpdatePopup } = updateApp.actions;

export default updateApp.reducer;