import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getFromStorage, setToStorage, removeFromStorage } from 'services';
import { changeMenuLang } from './menuSlice';
import { timeInMins } from 'configs/settingsConfig';
import { STEPS } from 'components/Orders';
import i18n from 'i18n';
import { clearCart, setCartItems } from './cartSlice';

const sliceName = 'userSettings';
const userStorageKey = `user`;

const saveUserToStorage = (params) => {
    const userStorage = getFromStorage(userStorageKey);

    setToStorage(userStorageKey, {
        selectedLang: userStorage?.selectedLang,
        reviewsStamps: userStorage?.reviewsStamps,
        ...params,
    });
}

const setUser = createAsyncThunk(`${sliceName}/setUser`, async (params, { getState }) => {
    return { ...params }
});

export const setReviewsStamps = createAsyncThunk(`${sliceName}/setReviewStamp`, async (params, { dispatch, getState }) => {
    const state = getState();

    const reviewsStamps = [];
    if (state.userSettings?.reviewsStamps)
        reviewsStamps.push(state.userSettings.reviewsStamps);

    reviewsStamps.push(params);

    saveUserToStorage({ reviewsStamps: reviewsStamps });

    return reviewsStamps
});

export const setSelectedLang = createAsyncThunk(`${sliceName}/setSelectedLang`, async (lang, { dispatch }) => {

    saveUserToStorage({
        selectedLang: lang
        // selectedLang: {
        //     id: lang.id,
        //     code: lang.code,
        //     isRtl: lang.isRtl,
        //     langName: lang.langName,
        //     imgUrl: lang.imgUrl,
        // }
    });

    dispatch(changeMenuLang(lang.id));

    document.body.setAttribute('dir', lang.isRtl ? 'rtl' : 'ltr');
    i18n.changeLanguage(lang.code.slice(0, 2));

    return lang
});

export const getCart = createAsyncThunk(`${sliceName}/getCart`, async (guid, { dispatch }) => {
    let cartItems = [];
    if (guid) {
        const cartStorageKey = `${guid}-cart`;
        const cartItemsStorage = getFromStorage(cartStorageKey);
        if (cartItemsStorage) {
            if (cartItemsStorage?.items?.length > 0) {
                let diff = Math.abs(new Date(cartItemsStorage?.date) - new Date());
                var diffInMinutes = Math.floor((diff / 1000) / 60);

                if (diffInMinutes > timeInMins)
                    removeFromStorage([cartStorageKey])
                else if (cartItemsStorage?.items?.length > 0) {
                    dispatch(setCartItems(cartItemsStorage.items));
                }
            }
        }
    }
    return cartItems;
});

export const setCart = createAsyncThunk(`${sliceName}/setCart`, async (cartItems, { getState, dispatch }) => {
    const state = getState();
    const guid = state.bizData?.guid;
    const items = state.cart.cartItems;
    if (guid) {
        const cartStorageKey = `${guid}-cart`;
        removeFromStorage([cartStorageKey])
        if (items?.length > 0 && cartItems !== null) {
            setToStorage(cartStorageKey, {
                date: new Date().toJSON(),
                items: items
            });
        }
    }
    if (cartItems === null) {
        dispatch(clearCart());
    }
    return cartItems
});

export const setOrder = createAsyncThunk(`${sliceName}/setOrder`, async (order, { getState }) => {
    const state = getState();
    const guid = state.bizData?.guid;

    let updatedOrder = { ...state.userSettings.order, ...order };

    if (guid) {
        const orderStorageKey = `${guid}-order`;
        if (order) {
            setToStorage(orderStorageKey, {
                date: new Date().toJSON(),
                data: updatedOrder
            });
        }
        else {
            updatedOrder = { step: STEPS.ORDER_TYPE }
            removeFromStorage([orderStorageKey])
        }
    }
    return updatedOrder
});

export const loadUserSettingsFromStorage = createAsyncThunk(`${sliceName}/loadOrderFromStorage`, async (params, { getState, dispatch }) => {
    const state = getState();
    const guid = state.bizData?.guid;

    /* Get only what we need from user settings storage
        and load them to redux */
    const userStorage = getFromStorage(userStorageKey);
    if (userStorage) {
        dispatch(setUser({
            selectedLang: userStorage?.selectedLang,
            reviewsStamps: userStorage?.reviewsStamps
        }))
    }

    if (guid) {
        const orderStorageKey = `${guid}-order`;
        const orderStorage = getFromStorage(`${guid}-order`);
        if (orderStorage) {
            let diff = Math.abs(new Date(orderStorage?.date) - new Date());
            var diffInMinutes = Math.floor((diff / 1000) / 60);

            if (diffInMinutes && diffInMinutes > timeInMins)
                removeFromStorage([orderStorageKey])
            else
                dispatch(setOrder(orderStorage?.data))
        }
    }
});

export const userSettingsSlice = createSlice({
    name: sliceName,
    initialState: {
        selectedLang: {
            id: 1,
            code: 'he',
            isRtl: true
        },
        selectedCat: null,
        selectedProd: null,
        hideTestBizAlert: false,
        hideMinPrAlert: false,
        reviewsStamps: null,
        order: {
            step: STEPS.ORDER_TYPE,
            typeId: null,
            prevTypeId: null,
            future: null,
            total: 0,
        },
        cart: [],
    },
    reducers: {
        setSelectedCat: (state, action) => { state.selectedCat = action.payload },
        setSelectedProd: (state, action) => { state.selectedProd = action.payload },
        setHideTestBizAlert: (state) => { state.hideTestBizAlert = true },
        setHideMinPrAlert: (state, action) => { state.hideMinPrAlert = action.payload },
    },
    extraReducers: {
        [setSelectedLang.fulfilled]: (state, action) => { state.selectedLang = action.payload },
        [setCart.fulfilled]: (state, action) => { state.cart = action.payload },
        [getCart.fulfilled]: (state, action) => { state.cart = action.payload },
        [setOrder.fulfilled]: (state, action) => { state.order = action.payload },
        [setReviewsStamps.fulfilled]: (state, action) => { state.reviewsStamps = action.payload },
        [setUser.fulfilled]: (state, action) => {
            state.reviewsStamps = action.payload.reviewsStamps;
            state.selectedLang = action.payload.selectedLang;
        },
        [loadUserSettingsFromStorage.fulfilled]: (state, action) => { },
    },
})

export const {
    setSelectedCat,
    setSelectedProd,
    setHideTestBizAlert,
    setHideMinPrAlert,
} = userSettingsSlice.actions

export default userSettingsSlice.reducer