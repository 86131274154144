import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
//import { useTheme } from '@emotion/react';
import { useSelector, useDispatch } from 'react-redux';
//import { setCart, setOrder } from 'store/userSettingsSlice';
//import { useNavigate } from "react-router-dom";
import CustomModal from 'components/utils/CustomModal';
//import resetOrder from '../../assets/RESET_ORDER.svg'
import LocalDiningIcon from '@mui/icons-material/LocalDining';
//import { ReactComponent as ResetOrderIcon } from '../../assets/RESET_ORDER.svg'
import { useMenu } from 'hooks/useMenu';
import { setUpsalesList } from 'store/upsalesSlice';

const ResetOrder = ({ children, setDrawerOpen, setIsBurgerOpen }) => {
    let dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const { list } = useSelector(state => state.upsales);
    const { t } = useTranslation();
    const { resetMenu } = useMenu();
    const bizData = useSelector(state => state.bizData);
    // const navigate = useNavigate();

    // const handleClose = (event, reason) => {
    //     if (reason && reason == "backdropClick") return;
    //     setOpen(false);
    // };

    const handleOpen = () => setOpen(true);

    const reset = () => {
        if (list) {
            const resetUpsale = list.map(upsale => { return { ...upsale, isShow: false } })
            dispatch(setUpsalesList(resetUpsale))
        }

        setOpen(false);
        setDrawerOpen && setDrawerOpen(false);
        setIsBurgerOpen && setIsBurgerOpen(false)
        resetMenu();
    }

    return (
        <>
            <div className='flex' onClick={handleOpen}>{children}</div>
            <CustomModal
                open={open}
                onClose={() => setOpen(false)}
                closeBtn={"CANCEL"}
                actionBtn={{ title: "RESET_ORDER", action: reset }}
                //img={resetOrder}
                svgIcon={{ component: LocalDiningIcon }}
                buttonCol
                focusOnCloseButton
            >
                <Box className="flex flex-col justify-center items-center" sx={{ minWidth: 220 }} >
                    <Typography variant="h6" color="primary">
                        {t('RESET_ORDER_QUESTION')}
                    </Typography>
                    <Typography variant="subtitle1" color="primary">
                        {t('RESET_ORDER_INFO')}
                    </Typography>
                </Box>
            </CustomModal>
        </>
    )
}

export default ResetOrder;