import { useEffect } from "react";
import { useQrReader } from 'hooks/useQrReader';

const QRCodeScanner = ({ style, onScanSuccess, setPermissionStatus }) => {

    const { ref, stopQrReader, startQrReader, permissionStatus } = useQrReader({
        onResult(result) {
            onScanSuccess(result.getText());
        },
    });

    useEffect(() => {
        setPermissionStatus(permissionStatus);
    }, [permissionStatus]);

    useEffect(() => {
        startQrReader();

        return () => {
            stopQrReader();

            if (ref.current) {
                ref.current.pause();
                ref.current.src = null;
            }
        }
    }, []);

    return (
        <video
            ref={ref}
            style={{ ...style }}
        />
    )
}

export default QRCodeScanner