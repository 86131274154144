import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axiosConn';
import moment from 'moment';
import { getFromStorage, setToStorage } from 'services';
import { setCart } from './userSettingsSlice';

const sliceName = 'menu';
const endpointName = sliceName;

export const getMenu = createAsyncThunk(`${sliceName}/getMenu`, async (params, { getState, dispatch }) => {
    const { langId, menuId, platformId } = params;
    const state = getState();
    if (!state.bizData?.guid || !menuId) return

    dispatch(setIsLoading(true));
    const localStorageKey = `${state.bizData.guid}-menu-${menuId}-${langId}`;
    let storageData = getFromStorage(localStorageKey);
    let lastUpd = storageData?.lastUpd ?? null;
    // const response = await axios.get(`${endpointName}/${state.bizData.guid}/${langId ?? 1}/${menuId}/${platformId}/${lastUpd}?timestamp=${new Date().getTime()}`);
    const response = await axios.get(`${endpointName}/${state.bizData.guid}/${langId ?? 1}/${menuId}/${platformId}/null`);
    if (response?.data?.id === 1) {
        const state = getState();
        let cats = [];
        if (response?.data?.data) {

            const allCats = response.data.data?.cats;

            /* Filter main category and sort them by seq (sequence) */
            const mainCats = allCats?.filter(cat => !cat.pid)
                /* Fix sorting with zero */
                /* Todo: Start seq with 1 instead of 0 in (BO),
                    update every cat and prod in DB, then do a simple sort */
                .sort((a, b) => (a.seq > b.seq) ? 1 : ((b.seq > a.seq) ? -1 : 0));

            /* Sort main and sub-categoris, filter empty categories*/
            mainCats.forEach(mainCat => {
                /* Get sub-categories and sort them by seq (sequence) */
                let subCats = allCats?.filter(subcat => subcat.pid === mainCat.id)
                    /* Fix sorting with zero */
                    /* Todo: Start seq with 1 instead of 0 in (BO),
                        update every cat and prod in DB, then do a simple sort */
                    .sort((a, b) => (a.seq > b.seq) ? 1 : ((b.seq > a.seq) ? -1 : 0));
                /* If category is empty we ignore it */
                if (mainCat?.items?.length === 0 && subCats?.length === 0)
                    return;

                /* Push main category to list */
                cats.push({
                    ...mainCat,
                    hasSubCats: subCats?.length > 0,
                    items: mainCat.items?.map(item => ({
                        ...item,
                        pid: mainCat.id
                    }))
                });

                /* If main category doesn't have any sub-category, we ignore it */
                if (subCats.length === 0)
                    return;

                /* Push sub-category to list right after it's parent category */
                subCats.forEach(subCat => {
                    /* If sub-category is empty, we ignore it */
                    if (subCat.items.length === 0)
                        return;

                    cats.push({
                        ...subCat,
                        isSubCat: true,
                        items: subCat.items?.map(item => {
                            return ({
                                ...item,
                                pid: subCat.id
                            })
                        })
                    })
                });
            });

            const data = {
                lang: langId,
                menuId: menuId,
                lastUpd: response?.data?.data?.lastUpd,
                data: cats
            }

            setToStorage(localStorageKey, data);//data
        }
        else
            cats = storageData?.data;


        /* Update cart items names */
        const cartItems = state?.userSettings?.cart;
        if (cartItems?.length > 0) {
            let newCartItems = [];
            cartItems.map(item => {
                const cat = cats.find(cat => cat.id === item.pid);
                const prod = cat?.items?.find(prod => prod.id === item.id);
                const newItem = {
                    ...item,
                    name: prod?.name,
                }
                newCartItems.push(newItem)
            });

            dispatch(setCart(newCartItems));
        }

        const catsWithTiming = await dispatch(checkCatsTiming(cats));

        dispatch(setIsLoading(false));
        return {
            menuId: menuId,
            canOrder: state?.bizData?.menus?.find((menu) => menu.id == menuId)?.typeId === 2,
            cats: [...catsWithTiming?.payload],
        }
    }
});

export const changeMenuLang = createAsyncThunk(`${sliceName}/changeMenuLang`, async (langId, { getState, dispatch }) => {
    const state = getState();
    const params = {
        guid: state.bizData?.guid,
        langId: langId,
        menuId: state.menu?.menuId,
        platformId: null,
    };

    dispatch(getMenu(params))
});

export const checkCatsTiming = createAsyncThunk(`${sliceName}/checkCatsTiming`, async (cats, { getState }) => {
    const state = getState();


    if (!cats && !state.menu?.cats)
        return;

    const menuCats = cats ? cats : [...state.menu?.cats];

    let timeNow = new Date().getTime();
    let today = moment(new Date()).day() + 1;

    menuCats?.map(cat => {
        cat.isTimingVisible = true;

        // Check if category have timing, if not category is always visible
        if (cat.catTiming?.length > 0) {

            // Check if category should be visible today, if not we filter it from the menu
            if (!cat.catTiming.some(time => time.dow === today &&
                timeNow >= new Date(time.ftm).getTime() &&
                timeNow <= new Date(time.ttm).getTime())) {

                cat.isTimingVisible = false;
            }
        }
    });

    return menuCats;
});

const menuInitialState = {
    menuId: null,
    cats: null,
    activeCats: null,
    canOrder: false,
    isLoading: false,
    loadingProduct: false,
}

export const menuSlice = createSlice({
    name: sliceName,
    initialState: menuInitialState,
    reducers: {
        setIsLoading: (state, action) => { state.isLoading = action.payload },
        setLoadingProduct: (state, action) => { state.loadingProduct = action.payload },
        setActiveCats: (state, action) => {
            state.activeCats = action.payload;
            return state
        }
    },
    extraReducers: {
        [getMenu.fulfilled]: (state, action) => {
            /** 
             * Orginal categories from server to use for filtering according to various display settings 
             * e.g.: According to order type id if menu has orders
             * */
            state.cats = action.payload?.cats;
            /** 
             * The actual categories array used to diplsy filtered categories and items (products) 
             * When getting menu from server both cats and active cats are the same
             * active cats are filtered from cats according to various display settings as mentioned above.
             * */
            //state.activeCats = action.payload?.cats;
            state.canOrder = action.payload?.canOrder;
            state.menuId = action.payload?.menuId;
        },
        [checkCatsTiming.fulfilled]: (state, action) => { state.cats = action.payload }
    }
})

export const { setIsLoading, setLoadingProduct, setActiveCats } = menuSlice.actions

export default menuSlice.reducer