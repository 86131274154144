import { useEffect, useState } from 'react';
import YpUtils from 'YpUtils';
import Header from 'components/Header';
import InfoModal from 'components/utils/CustomModal';
import HeightGetter from 'components/utils/HieghtGetter';
import ErrorBoundary from 'components/ErrorBoundary';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';
import maintenanceImg from 'assets/errors/maintenance.jpg';
import { Box } from '@mui/material';
// import Loader from 'components/utils/Loader'

function Layout(props) {
    const { children, setPreOrderStep, headerChildren } = props;
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const theme = useTheme();
    const bizData = useSelector(state => state.bizData);
    const headerHeight = useSelector(state => state.appSettings.headerHeight);
    const [errDialogMsg, setErrDialogMsg] = useState(null);
    const [imgBgErr, setImgBgErr] = useState(false);

    useEffect(() => {
        if (bizData?.hasWebApp == false)
            return setErrDialogMsg(t('WEBAPP_IS_NOT_ACTIVATED'))
        else
            return setErrDialogMsg(null)

    }, [bizData?.hasWebApp, bizData?.hasOrderMenu]);

    // Todo: do we still need this?
    // Send the height to the Iframe so it can resize to right height
    function resize(height) {
        window.parent.postMessage(["setHeight", height], "*");
    }

    // if (!bizData) {
    //     return (
    //         <div className='h-screen w-full flex justify-center items-center'>
    //             <Loader size={120} />
    //         </div>
    //     )
    // }

    return (
        <>
            {!errDialogMsg
                ? <HeightGetter onHeightReady={resize}>
                    <ErrorBoundary error={props.error}>
                        {bizData?.isUnderConstruction
                            ? <div className='flex h-screen'>
                                <img
                                    src={maintenanceImg}
                                    alt='Site is under maintenance'
                                    className='w-full h-full object-contain'
                                />
                            </div>
                            :
                            <>
                                <Header setPreOrderStep={setPreOrderStep}>
                                    {headerChildren}
                                </Header>

                                <Box
                                    sx={{
                                        // marginTop: '56px',
                                        height: `calc(100vh - ${headerHeight}px)`,
                                        //height: '100vh',
                                        //backgroundColor: 'secondary.main'
                                        backgroundColor: bizData?.theme?.bgType === 2 ? 'background.main' : 'none'
                                    }}
                                >
                                    {bizData?.theme?.imgBg && !imgBgErr &&
                                        <img src={YpUtils.getImage(bizData?.theme?.imgBg)}
                                            onError={() => setImgBgErr(true)}
                                            style={{ width: '100vw', height: '100vh' }}
                                            className='fixed top-0 inset -z-10 object-cover' />}
                                    {children}
                                </Box>
                            </>
                        }
                    </ErrorBoundary>
                </HeightGetter>
                : <InfoModal open={errDialogMsg !== null} msg={errDialogMsg} />
            }
        </>
    )
}

export default Layout;