import React from 'react'

const modifiersGroup = {
    id: 0,
    minQty: 0,
    maxQty: 0,
    seq: 0,
    modifiers: [],
}
const dModifier = {
    freeQty: 0,
    id: 0,
    imgUrl: null,
    isDefault: false,
    isOutOfStock: false,
    name: null,
    paidQty: 0,
    pr: 0,
    qty: 0,
    seq: 0
}
const dGroup = { id: 0, minQty: 0, maxQty: 0 };
const dPayload = { group: dGroup, modifier: dModifier, actionType: '' };

const cartModifiers = () => {

    const sortBy = (array, prop) => {
        return array.sort((a, b) => {
            if (a[prop] < b[prop]) { return -1; }
            if (a[prop] > b[prop]) { return 1; }
            return 0;
        })
    }

    const isObject = (object) => {
        return object != null && typeof object === 'object';
    }

    const deepEqual = (object1, object2) => {
        const keys1 = Object.keys(object1);
        const keys2 = Object.keys(object2);

        if (keys1.length !== keys2.length) {
            return false;
        }

        for (const key of keys1) {
            const val1 = object1[key];
            const val2 = object2[key];
            const areObjects = isObject(val1) && isObject(val2);
            if (
                areObjects && !deepEqual(val1, val2) ||
                !areObjects && val1 !== val2
            ) {
                return false;
            }
        }

        return true;
    }

    // const currencyFormat = (value, symbol = '₪', maximumFractionDigits = 0) => {
    //     // Add thousands separator
    //     const val = value.        
    //     const formattedNumber = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    //     // Format the number as a currency string
    //     return `${symbol}${formattedNumber}`;
    // }

    const currencyFormat = (value, maximumFractionDigits = 2, locale = { code: "he-IL", currency: "ILS" }) => {
        return value?.toLocaleString(locale.code, {
            style: "currency",
            currency: locale.currency,
            maximumFractionDigits: maximumFractionDigits// Number.isInteger(value) ? 0 : maximumFractionDigits
        })
    }

    // const currencyFormat = (value, symbol) => {
    //     return value?.toLocaleString({
    //         maximumFractionDigits: 2,
    //     });
    // }

    /** 
     * tci - Temporary Cart Item 
     * the object which is currently being edited
     * */
    const validateBeforeAddToCart = (product, tciModifierGroups, forceValidation = false) => {
        /**
         * product - the default product values on which the function relay for validation.
         * tciModifierGroups - the modifier groups in cart item that is currently being edited
         * forceValidation - ignore the modifiers group "touched" property to force validation
         * even if group was not edited by the user. - should be done only before AddToCart action
         */
        return new Promise((resolve, reject) => {
            try {
                let invalids = [];
                product.modifierGroups?.forEach((modGroup) => {
                    if (modGroup.touched || forceValidation) {
                        const { minQty, maxQty, id } = modGroup;
                        const tciModGroup = tciModifierGroups.find((g) => g.id === id);
                        /**
                         * Check that the minimum amount of modifiers is selected from a certain group
                         * according to limitations
                         * */
                        const rule = getModifierGroupRule(minQty, maxQty);
                        if (minQty > 0) {
                            if (tciModGroup) {
                                let qty = 0;
                                tciModGroup.modifiers.forEach((mod) => { qty += mod.qty });
                                if (qty < minQty) {
                                    invalids.push({
                                        id: id,
                                        langs: tciModGroup?.langs,
                                        name: tciModGroup?.langs ?? tciModGroup?.name,
                                        error: rule
                                    });
                                }
                            }
                            /**
                             * Show one error at a time instead of all errors together 
                             * Remove condition to display all errors at once
                             * */
                            else if (invalids.length === 0) {
                                invalids.push({ id: id, langs: modGroup.langs ?? modGroup.name, error: rule });
                            }
                        }
                    }
                });

                resolve(invalids);
            }
            catch {
                reject('EXCEPTION');
            }
        }
        )
    };

    /** CURRENTLY NOT IN USE */
    const validateModifierGroup = (modGroup, tciModifierGroup) => {
        return new Promise((resolve, reject) => {
            try {
                let invalid = false;
                const { minQty, maxQty, id } = modGroup;
                /**
                 * Check that the minimum amount of modifiers is selected from a certain group
                 * according to limitations
                 * */
                if (minQty > 0) {
                    if (tciModifierGroup && tciModifierGroup.modifiers.length >= minQty) {
                        /** All good with this group */
                    }
                    else {
                        invalid = true
                    }
                }

                resolve(invalid);
            }
            catch {
                reject('EXCEPTION');
            }
        }
        )
    };

    const calcTempCartItemPrice = (ci) => {
        let sum = 0;
        sum = ci.price;
        ci.modifierGroups.forEach((g) => {
            g.modifiers.forEach((m) => {
                if (m.qty > m.freeQty) {
                    sum = sum + m.pr * (m.qty - m.freeQty);
                }
            })
        })
        sum = sum * ci.qty;
        return sum;
    }

    const getProductDefaultGroups = (product) => {
        let defaultGroups = [];
        product?.modifierGroups?.forEach(mGroup => {
            let defaultModifiers = [];
            mGroup.modifiers.forEach(mod => {
                if (mod.isDefault)
                    defaultModifiers.push({ ...mod, qty: 1 });
            });
            if (defaultModifiers.length > 0)
                defaultGroups.push({ ...mGroup, modifiers: defaultModifiers });
        })
        return defaultGroups;
    }

    const getModifierGroupType = (mg = modifiersGroup) => {
        const { minQty, maxQty, modifiers } = mg;
        let type = 'COUNTER';
        if (((maxQty === -1 && (minQty === -1 || minQty > 0)) || (maxQty > minQty && (minQty > 0 || minQty === -1))))
            type = 'COUNTER';
        if (maxQty === 1 && minQty === 1)
            type = 'RADIO';
        if ((maxQty !== 1 && modifiers?.filter(m => (m.paidQty + m.freeQty) > 1)?.length === 0) || (maxQty === 1 && minQty <= 0))
            type = 'CHECKBOX';
        return type;
    }

    const getModifierGroupRule = (minQty, maxQty) => {
        if (maxQty === -1 && minQty === -1) {
            return null
        }
        else if (maxQty === 1 && minQty === 1) {
            return { text: 'REQUIRE_ONLY_ONE', params: null }
        }
        else if (maxQty === 1 && minQty === 0) {
            return { text: 'ALLOWED_ONLY_ONE', params: null }
        }
        else if ((maxQty > minQty) && (minQty > 0)) {
            return { text: 'SELECT_BETWEEN', params: { minimum: minQty, maximum: maxQty } }
        }
        else if ((maxQty === -1) && (minQty > 0)) {
            return { text: 'MUST_ATLEAST_MINIMUM', params: { minimum: minQty, maximum: null } }
        }
        else if ((maxQty === minQty) && (minQty > 0)) {
            return { text: 'REQUIRED_AMOUNT', params: { minimum: minQty, maximum: null } }
        }
        else if ((maxQty > minQty) && (minQty <= 0)) {
            return { text: 'ALLOWED_MAX', params: { minimum: null, maximum: maxQty } }
        }
    }

    /**
     * Each time the qty is changed on the specific modifier, 
     * refreshing the current displayed qty, checking the limitations 
     * and disabling the add button accordingly
     * */
    const modState = (ciGroup, modId) => {
        let res = {
            disabled: false,
            selectedQty: 0,
            showPrice: false,
            totalPrice: 0,
        };
        let totalQty = 0;
        ciGroup?.modifiers?.forEach((mod) => {
            totalQty += mod.qty;

            if (mod.id === modId) {
                res.selectedQty = mod.qty;

                res.disabled = (mod.qty === mod.freeQty + mod.paidQty) || mod.isOutOfStock;

                if (mod.qty > mod.freeQty && mod.pr > 0) {
                    res.showPrice = true;
                    res.totalPrice = (mod.qty - mod.freeQty) * mod.pr
                }
            }

            if (totalQty >= ciGroup.maxQty && ciGroup.maxQty !== -1) {
                res.disabled = true
            }
        })
        return res;
    }

    const modifyTempCartItemGroup = (tempCartItem, payload = dPayload) => {
        let { group, modifier, actionType } = payload;

        /** group - the default product modifers group */
        const { id, minQty, maxQty, modifiers } = group;
        let outTempCartItem = tempCartItem;
        const ciModifierGroup = outTempCartItem?.modifierGroups?.find((ciMGroup) => ciMGroup.id === id);
        const ciModfier = ciModifierGroup?.modifiers?.find((ciMod) => ciMod.id === modifier.id);
        if (actionType === 'ADD') {
            if (ciModifierGroup) {
                /** Check group limitations and if able to add the selected modifier */
                // if (maxQty === 1 && minQty === 1 && !ciModfier) {
                const defaultsAmount = ciModifierGroup.modifiers.filter((m) => m.qty === 0).length;

                if (maxQty === 1 && minQty <= 1 && !ciModfier) {
                    /** 
                     * In case only 1 modifier is allowed in group, 
                     * replace group content with the selected modifier
                     * */
                    if (!modifier.qty) {
                        modifier = { ...modifier, qty: 1 }
                    }
                    const outModifierGroup = { ...ciModifierGroup, modifiers: [modifier] };
                    const outGroups = outTempCartItem?.modifierGroups?.filter((ciMGroup) => ciMGroup.id !== id);
                    outTempCartItem = { ...outTempCartItem, modifierGroups: [...outGroups, outModifierGroup] };
                }
                else if ((ciModifierGroup.modifiers.filter((m) => m.qty > 0).length < maxQty || maxQty === -1) && !ciModfier) {
                    /** Allow multiple modifiers from same group */
                    if (!modifier.qty) {
                        modifier = { ...modifier, qty: 1 }
                    }
                    let outModifierGroup = { ...ciModifierGroup, modifiers: [...ciModifierGroup.modifiers, modifier] };

                    const mods = outModifierGroup.modifiers.filter((m) => m.qty > 0);
                    if (mods?.length >= defaultsAmount) {
                        outModifierGroup = { ...ciModifierGroup, modifiers: [...mods] }
                    }

                    const outGroups = outTempCartItem?.modifierGroups.filter((ciMGroup) => ciMGroup.id !== id);
                    outTempCartItem = { ...outTempCartItem, modifierGroups: [...outGroups, outModifierGroup] };
                }
                else if (ciModfier) {
                    /**  Add more from the same modifier */
                    if (ciModfier.qty < ciModfier.freeQty + ciModfier.paidQty) {
                        const outMod = { ...ciModfier, qty: ciModfier.qty + 1 }
                        const outMods = ciModifierGroup.modifiers.filter((mod) => mod.id !== modifier.id);
                        const outModifierGroup = { ...ciModifierGroup, modifiers: [...outMods, outMod] };
                        const outGroups = outTempCartItem?.modifierGroups.filter((ciMGroup) => ciMGroup.id !== id);
                        outTempCartItem = { ...outTempCartItem, modifierGroups: [...outGroups, outModifierGroup] };
                    }
                }
                else {
                    /** Error */
                    console.error('could not add more modifiers from this group');
                }
            }
            else {
                /** Adding a new group with the selected modifier to tempCartItem */
                outTempCartItem = {
                    ...outTempCartItem,
                    modifierGroups: [
                        ...outTempCartItem?.modifierGroups,
                        {
                            ...group,
                            modifiers: [{ ...modifier, qty: 1 }]
                        }]
                }
            }
        }

        if (actionType === 'REMOVE') {
            let outGroups = [];
            let outModifiers = [];
            /** Filter the MODIFIERS who were not updated */
            outModifiers = ciModifierGroup?.modifiers?.filter((ciMod) => ciMod.id !== modifier.id);
            if (ciModfier.qty > 0) {
                /** Reducing the selected modifier quantity */
                const outMod = { ...ciModfier, qty: ciModfier.qty - 1 };
                /** Updating the MODIFIERS GROUP with the selected modifier */
                let outGroup = {};
                /** Filter the GROUPS who were not updated */
                outGroups = outTempCartItem.modifierGroups.filter((ciMGroup) => ciMGroup.id !== id);
                /** Adding the updated modifiers group back to the array */
                if (outMod.qty === 0 && !ciModfier.isDefault) {
                    /** Removing the updated modifier if none left */
                    if (outModifiers.length === 0)
                        outModifiers = modifiers.filter((m) => m.isDefault).map((m) => { return { ...m, qty: 0 } });
                    outGroup = { ...ciModifierGroup, modifiers: [...outModifiers] }
                }
                else {
                    /** Re-adding the updated modifier */

                    outModifiers = [...outModifiers, outMod]
                    outGroup = { ...ciModifierGroup, modifiers: outModifiers }
                }
                outGroups = [...outGroups, outGroup]
            }
            // else {
            //     outModifiers = ciModifierGroup?.modifiers?.filter((ciMod) => ciMod.id !== modifier.id);
            // }
            if (outModifiers.length === 0) {
                /** Remove the group if there are no modifiers left in it */
                outGroups = outTempCartItem.modifierGroups.filter((ciMGroup) => ciMGroup.id !== id)
            }
            /** Update TempCartItem with the new changes */
            outTempCartItem = { ...outTempCartItem, modifierGroups: outGroups };
        }
        /** 
         * Updating the original tempCartItem with all add/remove changes
         * and calculating totalPrice accordingly 
         * */
        return {
            ...outTempCartItem,
            totalPrice: calcTempCartItemPrice(outTempCartItem)
        }
    }

    const prepareOrderItems = (cartItems) => {
        let postCartItems = [];
        cartItems.forEach((ci) => {
            let postItem = {
                id: ci.id,
                qty: ci.qty,
                catId: ci.catId,
                grps: [],
                belongsTo: ci.belongsTo,
                remarks: ci.remarks
            };

            if (ci.id === 0) {
                postItem = { ...postItem, name: ci.name, pr: ci.price }
            }

            const groups = sortBy([...ci.modifierGroups], 'seq');
            groups.forEach((cimg) => {
                let postGroup = { id: cimg.id, ings: [] };
                const modifiers = sortBy([...cimg.modifiers], 'seq');
                modifiers.forEach((cim) => {
                    let postMod = { id: cim.id, qty: cim.qty };
                    postGroup.ings.push(postMod);
                })
                postItem.grps.push(postGroup);
            })
            postCartItems.push(postItem);
        });
        return postCartItems;
    }

    return {
        isObject,
        deepEqual,
        currencyFormat,
        getModifierGroupType,
        getModifierGroupRule,
        modState,
        modifyTempCartItemGroup,
        calcTempCartItemPrice,
        getProductDefaultGroups,
        validateBeforeAddToCart,
        validateModifierGroup,
        prepareOrderItems
    };
};

export default cartModifiers;