import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedCat } from 'store/userSettingsSlice';
import MenuItem from '../MenuItem';
import { useTheme } from '@emotion/react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { ORDER_TYPE_ID } from 'configs/consts';
//import { showProduct } from '.';
// import { useMediaQuery } from '@mui/material';

const Category = ({ cat, scrollY, autoScroll, setAutoScroll, isAtBottom, index, hasSubCats, selectedBadge }) => {
    const dispatch = useDispatch();
    let theme = useTheme();
    const headerHeight = useSelector(state => state.appSettings.headerHeight);
    //const { isTable, hasOrderMenu } = useSelector(state => state.bizData);

    // const smScreen = useMediaQuery(theme.breakpoints.down('lg'));
    const selectedProd = useSelector(state => state.searchProd.selectedProd);
    const selectedCat = useSelector(state => state.userSettings.selectedCat);
    const order = useSelector(state => state.userSettings.order);
    const mdScreen = useMediaQuery(theme.breakpoints.down('md'));
    const elemId = `cat-${cat?.id}`;
    const categoryRef = useRef();
    // useEffect(() => {
    //     if (selectedCat && (selectedCat.id === cat.id || selectedCat.pid === cat.id)) {
    //         categoryRef.current.scrollIntoView({ behavior: 'smooth' })
    //         setAutoScroll(false);
    //     }
    // }, [selectedCat]);

    /* Checking what category is in view than we set it as selected. */
    useEffect(() => {
        /* If user has clicked on a category, we return until the category is in view */
        if (autoScroll)
            return;

        const element = document.getElementById(elemId);

        if (!element)
            return;

        if (isAtBottom) {
            dispatch(setSelectedCat(cat));
        }
        else {
            const rect = element.getBoundingClientRect()

            /* Check if category is inside the selecting area/viewport */
            const selectingCatRectStart = headerHeight;
            const selectingCatRectEnd = headerHeight + 200;

            const visible = {
                top: rect.top >= selectingCatRectStart && rect.top < selectingCatRectEnd,
                bottom: rect.bottom >= selectingCatRectStart && rect.bottom < selectingCatRectEnd,
            };
            if (visible.top || visible.bottom) {
                //window.history.replaceState(null, null, `/${guid}/${menuId}/${cat.id}`)
                dispatch(setSelectedCat(cat));
            }
        }
    }, [scrollY, isAtBottom]);

    const checkBadges = (arr) => {
        const names = arr?.map(obj => obj.name);
        const badges = selectedBadge?.map(obj => obj.name);
        return badges?.every(text => names.includes(text))
    }

    // const showProduct = (p) => {
    //     if (isTable && hasOrderMenu) {
    //         return order?.typeId === ORDER_TYPE_ID.PICKUP && p.isTablePickup ||
    //             order?.typeId === ORDER_TYPE_ID.SITTING && p.isTableSitting
    //     }
    //     else if (order?.typeId && hasOrderMenu) {
    //         return order?.typeId === ORDER_TYPE_ID.DELIVERY && p.isWebDelivery ||
    //             order?.typeId === ORDER_TYPE_ID.PICKUP && p.isWebPickup

    //     }
    //     else if (!hasOrderMenu)
    //         return true
    // }

    if (!cat.isTimingVisible)
        return null

    return (

        <div
            className='w-full'
            ref={categoryRef}
            id={elemId}
        >
            {/* Category Name */}
            <Box
                className={`${cat.pid || (index === 0 && !mdScreen) ? 'mt-0' : 'mt-4 lg:mt-8'} ${hasSubCats ? 'pt-2 px-2' : 'p-2'}`}
                sx={{
                    backgroundColor: 'secondary.main',
                    color: 'primary.main',
                }}
            >
                <Box className='flex flex-col sm:flex-row gap-2 sm:items-end'>
                    {cat?.name &&
                        <Typography color='clCatTitle.main' variant={`${cat.pid ? 'h6' : 'h5'}`} className="font-bold">
                            {`${cat.pid ? '-' : ''} ${cat.name}`}
                        </Typography>
                    }

                    {/* Category Notes */}
                    {cat?.catNote &&
                        <Typography variant='subtitle1'>
                            {cat.catNote}
                        </Typography>
                    }
                </Box>
                {/* Menu Category Items */}
                {
                    cat?.items
                        //?.filter(p => showProduct(p, isTable, hasOrderMenu, order))
                        ?.map((product) => {
                            const findBadges = checkBadges(product?.badges)
                            return (
                                (!selectedProd || selectedProd?.id === product?.id) &&
                                (selectedBadge?.length === 0 || findBadges) &&
                                <MenuItem
                                    key={`i-${product.id}-${cat.id}`}
                                    product={product}
                                    catId={cat.id}
                                />
                            )
                        })
                }
            </Box>
        </div >

    )
}

export default Category;