import React from 'react';
import { Modal, Typography, Button, Box, IconButton, SvgIcon } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

const CustomModal = ({
    open,
    children,
    onClose,
    closeBtn,
    actionBtn,
    msg,
    closeIcon,
    focusOnCloseButton,
    title,
    img,
    imgWidth,
    imgHeight,
    svgIcon,
    onClickOutsideToClose,
}) => {
    const { t } = useTranslation();

    return (
        <Modal
            open={open}
            onClose={onClickOutsideToClose ? onClose : () => { }}
            className='flex flex-col justify-center items-center'
        >
            <Box
                sx={{ backgroundColor: 'secondary.main' }}
                className='flex flex-col justify-center items-center rounded-md p-4 mx-2'
            >
                {/* Title And Close Icon */}
                {(title || closeIcon) &&
                    <div className={`flex w-full items-center mb-4`}>

                        {title &&
                            <Typography
                                width='100%'
                                textAlign='center'
                                variant='h6'
                                color='primary'>
                                {t(title)}
                            </Typography>
                        }

                        {onClose && closeIcon &&
                            <div className='flex justify-end'>
                                <IconButton onClick={onClose}>
                                    <CloseIcon color='primary' />
                                </IconButton>
                            </div>
                        }

                    </div>}

                <div className="flex flex-col w-full items-center gap-4">
                    {img &&
                        <img
                            className='object-contain'
                            src={img}
                            alt="advertise"
                            style={{
                                maxWidth: '100%',
                                width: imgWidth ? `${imgWidth}px` : "170px",
                                height: imgHeight ? `${imgHeight}px` : "106px"
                            }} />
                    }

                    {svgIcon &&
                        <SvgIcon
                            component={svgIcon.component}
                            sx={{ width: 72, height: 72, ...svgIcon.sx }}
                            color={svgIcon.color || "primary"}
                            inheritViewBox
                        />
                    }

                    <div>
                        {children}
                    </div>
                    {msg &&
                        <Typography
                            // className='text-center'
                            color='primary'
                            sx={{ textAlign: "center" }}
                            variant='h6'>
                            {t(msg)}
                        </Typography>
                    }

                </div>

                {(actionBtn || closeBtn) &&
                    < div className='flex w-full gap-4 pt-4'>
                        {/* View Menu button */}
                        {actionBtn &&
                            <Button
                                className='w-full'
                                // sx={!enableThemeColors && {
                                //     color: !focusOnCloseButton ? "#fff" : "#333",
                                //     backgroundColor: !focusOnCloseButton ? "#333" : "#fff",
                                //     border: "1px",
                                //     borderStyle: "solid",
                                //     borderColor: "#333"
                                // }}
                                variant={!focusOnCloseButton ? "contained" : "outlined"}
                                onClick={actionBtn.action}
                            >
                                <Typography color={focusOnCloseButton ? 'primary' : 'secondary'}>{t(actionBtn.title)}</Typography>
                            </Button>
                        }

                        {closeBtn &&
                            <Button
                                className='w-full'
                                // sx={!enableThemeColors && {
                                //     color: focusOnCloseButton ? "#fff" : "#333",
                                //     backgroundColor: focusOnCloseButton ? "#333" : "#fff",
                                //     border: "1px",
                                //     borderStyle: "solid",
                                //     borderColor: "#333"
                                // }}
                                variant={focusOnCloseButton ? "contained" : "outlined"}
                                onClick={onClose}
                            >
                                <Typography color={focusOnCloseButton ? 'secondary' : 'primary'}> {t(closeBtn)}</Typography>

                            </Button>
                        }
                    </div>}
            </Box>
        </Modal >
    )
}
export default CustomModal;

