import React, { useState, useEffect } from "react";
import { TextField, Typography, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import useCurrency from "hooks/useCurrency";

const Tips = ({ total, setTip, theme, tip }) => {
    let { t } = useTranslation();
    const bizData = useSelector(state => state.bizData);
    const selectedLang = useSelector(state => state.userSettings.selectedLang);
    const { getCurrencySymbol } = useCurrency();
    let [inputTip, setInputTip] = useState(false)
    const [hendleTip, setHandleTip] = useState()

    const types = {
        Percent: '%',
        currency: getCurrencySymbol(),
        Other: 'OTHER'
    }

    let values = [
        { id: 1, value: 10, type: types.currency },
        { id: 2, value: 15, type: types.currency },
        { id: 2, value: 20, type: types.currency },
        { id: 2, value: 25, type: types.currency },
        { id: 3, value: 10, type: types.Percent },
        { id: 4, value: 15, type: types.Percent },
        { id: 4, value: 20, type: types.Percent },
        { id: 5, value: 0, type: types.Other },
    ]

    const UpdateTotal = (val) => {
        if (val.id != null) {
            setHandleTip('')
        }

        if (val.id === 5) {
            setInputTip(true)
        }

        if (val.type === "%") {
            setTip((val.value / 100) * total)
            setInputTip(false)
        }

        else {
            setTip(val.value)
        }
    }

    return (
        <div className="mt-1 mb-2">
            <div className="grid grid-cols-4 gap-3">
                {values?.map((val, index) => {
                    let tipValue;
                    if (val.type === '%')
                        tipValue = total * (val.value / 100)
                    else
                        tipValue = val.value

                    // let isSelected=Math.round(tipValue)===parseInt(localStorage.getItem('tip'));

                    let selected = tip === tipValue

                    return (
                        <Button
                            key={index}
                            size="large"
                            variant="outlined"
                            onClick={() => { UpdateTotal(val); }}
                            className={`flex items-center justify-center p-1`}
                            style={{
                                border: selected
                                    ? `2px solid${theme.palette.primary.main}`
                                    : `1px solid${theme.palette.secondary.main}`
                            }}

                        >
                            <Typography
                                sx={{ color: 'primary.main', py: 1 }}
                                className={`flex ${selectedLang?.isRtl && val.type == "%" && 'flex-row-reverse'}`}
                            >
                                {val.id !== 5 && <span>{val.value}</span>}
                                <span>{val.id === 5 ? t(val.type) : val.type}</span>
                            </Typography>
                        </Button>
                    )
                })}
            </div>

            {inputTip &&
                <TextField
                    label={t('ENTER_SUM')}
                    fullWidth
                    value={hendleTip}
                    onChange={e => {
                        if (/^\d+$/.test(e.target.value) || e.target?.value?.length === 0) {
                            setHandleTip(e.target.value);
                            UpdateTotal({
                                id: null,
                                value: e.target.value?.length > 0
                                    ? parseInt(e.target.value)
                                    : 0,
                                type: bizData?.currency
                            })
                        }
                    }}
                    sx={{ mt: 1, input: { color: 'primary.main' } }}
                    InputProps={{
                        endAdornment:
                            <Typography sx={{ color: 'secondary.main' }}>
                                {bizData?.currency}
                            </Typography>
                    }}
                />
            }
        </div>
    )
}

export default Tips;