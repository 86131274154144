import Loading from 'components/utils/Loading'
import { useDispatch, useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';
import Layout from 'containers/Layout';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';
import ChooseOrderType from 'components/Orders/preOrder/ChooseOrderType';
import ChooseOrderTime from 'components/Orders/preOrder/ChooseOrderTime';
import FutureOrder from 'components/Orders/preOrder/FutureOrder';
import ChooseBranch from 'components/Orders/preOrder/ChooseBranch';
import AddressDetails from 'components/Orders/preOrder/AddressDetails';
import ClientDetails from 'components/Orders/checkout/ClientDetails';
import PaymentSettings from 'components/Orders/checkout/PaymentSettings';
import CcPayment from 'components/Orders/checkout/CcPayment';
import { setOrder } from 'store/userSettingsSlice';
import { useNavigate, useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { ORDER_TYPE_ID } from 'configs/consts';
import StepHeader from './StepHeader';

export const STEPS = {
    ORDER_TYPE: 1,
    ORDER_TIME: 2,
    ADDRESS: 3,
    FUTURE_ORDER: 4,
    BRANCHES: 5,
    MENU_LIST: 6,
    CLIENT_DETAILS: 7,
    PAYMENT: 8,
    CC_PAYMENT: 9,
}

const PreOrder = () => {
    const navigate = useNavigate()
    let dispacth = useDispatch();
    let theme = useTheme();
    const { guid } = useParams();
    let { t } = useTranslation();
    const bizData = useSelector(state => state.bizData);
    const order = useSelector(state => state.userSettings.order);
    const [openDialog, setOpenDialog] = useState(false);

    useEffect(() => {
        if (bizData?.orderSettings?.isOverload)
            setOpenDialog(true)

        // if (bizData?.menus?.length === 1)
        //     setCurrentMenuId(bizData?.menus[0]?.id)

    }, [bizData]);

    // useEffect(() => {
    //     /* If the client is sitting at a table */
    //     console.log('bizData?.isTable', bizData?.isTable);
    //     if (order?.step === Steps.OrderType && bizData?.isTable)
    //         dispacth(setOrder({
    //             step: Steps.Menus,
    //             typeId: ORDER_TYPE_ID.inPlace, // sitting typeId
    //         }));

    // }, [bizData?.isTable, order?.step]);

    return (
        <Layout>
            {bizData
                ? <div className='xl:pt-4'>
                    <Box
                        className='flex flex-col max-w-lg items-center mx-auto overflow-scroll hide-scrollbar lg:p-4 p-2'
                        bgcolor="secondary.main"
                    >
                        <div className='w-full'>
                            {/* PreOrder */}
                            {order?.step === STEPS.ORDER_TYPE ?
                                <ChooseOrderType />
                                : order?.step === STEPS.ADDRESS ?
                                    <AddressDetails bizId={bizData?.id} />
                                    : order?.step === STEPS.BRANCHES ?
                                        <ChooseBranch />
                                        : order?.step === STEPS.ORDER_TIME ?
                                            <ChooseOrderTime />
                                            : order?.step === STEPS.FUTURE_ORDER ?
                                                <FutureOrder />
                                                /* Checkout */
                                                : order?.step === STEPS.CLIENT_DETAILS ?
                                                    <ClientDetails />
                                                    : order?.step === STEPS.PAYMENT ?
                                                        <PaymentSettings />
                                                        : order?.step === STEPS.CC_PAYMENT ?
                                                            <CcPayment />
                                                            : null
                            }
                        </div>
                    </Box>
                </div>
                : <div className='h-full min-h-screen w-full flex justify-center items-center'>
                    <Loading />
                </div>
            }

        </Layout >
    )
}

export default PreOrder;