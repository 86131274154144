import { TextField, Typography, Box } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { celIsraeli, lettersOnly } from 'configs/validations';
import { useSelector } from "react-redux";
import TelApproveModal from './TelApprovePopup';
// import ExtrasQty from './ExtrasQty';
import axios from 'axiosConn';
import YpUtils from "YpUtils";
import InfoModal from 'components/utils/CustomModal';
import { Checkbox, Modal, Fade } from '@mui/material'
import PrivacyPolicy from 'assets/pdfs/מדיניות-הפרטיות.pdf';
import TermsOfUseRestaurants from 'assets/pdfs/תנאי-השימוש-מסעדות.pdf';
import TermsOfUseStores from 'assets/pdfs/תנאי-השימוש-חנויות.pdf';
import StepHeader from "../StepHeader";
import { useDispatch } from 'react-redux';
import { setOrder } from 'store/userSettingsSlice';
import { STEPS } from "..";
import PaymentButton from "./PaymentButton";
import Counter from "components/utils/Counter";
import { BUSINESS_TYPE_ID, ORDER_TYPE_ID } from "configs/consts";
import useCurrency from "hooks/useCurrency";


const ClientDetails = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { currency } = useCurrency();
    const { formState: { errors }, handleSubmit, control } = useForm();
    const bizData = useSelector(state => state.bizData);
    const selectedLang = useSelector(state => state.userSettings.selectedLang);
    // const orderSettings = useSelector(state => state.bizData?.orderSettings);
    const order = useSelector(state => state.userSettings.order);
    const [openTelModal, setOpenTelModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errDialogMsg, setErrDialogMsg] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [dinners, setDinners] = useState(1);
    const [dialogContent, setDialogContent] = useState();
    const [notes, setNotes] = useState('')
    const [termsBiz, setTermsBiz] = useState(null)
    const [oldTel, setOldTel] = useState("");

    const idForTermsBiz = [335, 334, 332, 209, 317, 316, 326, 333]

    /** Do not delete this (future use) */
    // let [counts, setCounts] = useState({
    //     numOfDiners: { title: 'DINERS_QTY', qty: 1, main: true },
    //     numOfCutlery: orderSettings?.hasNumOfCutlery ? { title: 'CUTLERY_QTY', qty: 0, main: false } : false,
    //     numOfChopsticks: orderSettings?.hasNumOfChopsticks ? { title: 'CHOPSTICKS_QTY', qty: 0, main: false } : false,
    //     numOfCups: orderSettings?.hasNumOfCups ? { title: 'CUPS_QTY', qty: 0, main: false } : false,
    // })

    useEffect(() => {
        dispatch(setOrder({ guid: null }))
    }, []);

    useEffect(() => {
        const pdfUrl = `https://molo-menu.s3.eu-central-1.amazonaws.com/media/${bizData?.id}/Terms.pdf`
        setTermsBiz(pdfUrl)
    }, [bizData]);

    const registerSucceeded = () => {
        setTimeout(() => {
            dispatch(setOrder({ step: STEPS.PAYMENT }))
            clearTimeout()
        }, 20)
    }

    const onSubmit = (values) => {
        setIsLoading(true);
        // let tempCounts = {}
        // Object.keys(counts)?.map(key => (tempCounts[key] = counts[key]?.qty));
        let client = {
            fn: values.fn,
            ln: values.ln,
            tel: values.tel,
        }

        /* If client phone didn't change, continue to the next step.
            else send a new sms for phone verification */
        if (values.tel === order?.client?.tel && order?.client?.id && order?.client?.telValid) {
            client.id = order?.client?.id;
            client.telValid = true;
            dispatch(setOrder({
                step: STEPS.PAYMENT,
                client: client,
                notes: notes,
                dinners: dinners
            }))
        }
        else if (oldTel === values.tel) {
            setIsLoading(false);
            setOpenTelModal(true);
        }

        else {
            let data = YpUtils.objectToFormData({
                id: order?.client?.id,
                langCode: selectedLang?.code,
                bizId: bizData?.id,
                fn: values.fn,
                ln: values.ln,
                tel: values.tel,
                sessionToken: order.typeId === ORDER_TYPE_ID.DELIVERY
                    ? order?.address?.sessiontoken
                    : null,
                // isBizAddr: order.typeId === ORDER_TYPE_ID.delivery
                //     ? order?.address?.isBiz
                //     : null,
                placeId: order.typeId === ORDER_TYPE_ID.DELIVERY
                    ? order?.address?.placeId
                    : null,
                apt: order.typeId === ORDER_TYPE_ID.DELIVERY
                    ? order?.address?.apt
                    : null,
                floor: order.typeId === ORDER_TYPE_ID.DELIVERY
                    ? order?.address?.floor
                    : null,
                entry: order.typeId === ORDER_TYPE_ID.DELIVERY
                    ? order?.address?.entry
                    : null,
                addrNote: order.typeId === ORDER_TYPE_ID.DELIVERY
                    ? order?.address?.addrNote
                    : null,
            });

            return axios.post(`client/register`, data).then(res => {
                setIsLoading(false);
                if (res?.data?.id === 1) {
                    client.id = res?.data?.data;
                    dispatch(setOrder({
                        client: client,
                        notes: notes,
                        dinners: bizData?.bizTypeId === BUSINESS_TYPE_ID.RESTAURANT
                            ? dinners
                            : null
                    }))
                    if (bizData?.orderSettings?.hasTelVerify) {
                        setOpenTelModal(true);
                    }
                    else {
                        registerSucceeded()
                    }
                }
                else
                    setErrDialogMsg(t('ERR'))
            })
                .catch(err => {
                    setIsLoading(false);
                    setErrDialogMsg(t('ERR'))
                })
        }
    }

    const setNotesHandler = (e) => {
        const stringLength = JSON.stringify(e.target.value).replace('\\n', '').length;
        const maxLength = e.target.attributes.maxLength.value;
        if (maxLength && parseInt(maxLength) > 0) {
            if (stringLength < parseInt(maxLength))
                setNotes(e.target.value)
            else if (e.keyCode != 8
                && e.keyCode != 46
                && e.keyCode != 37
                && e.keyCode != 38
                && e.keyCode != 39
                && e.keyCode != 40
            )
                e.preventDefault()
        }
    }

    return (
        <>
            <StepHeader
                backBtnClick={() => dispatch(setOrder({ step: STEPS.MENU_LIST }))}
                title={t('DETAILS')}
            />

            {errDialogMsg &&
                <InfoModal
                    onClose={() => setErrDialogMsg(false)}
                    open={errDialogMsg !== null}
                    title={"EROOR"}
                    closeIcon
                >
                    <div className='text-xl font-bold p-6'>{t(errDialogMsg)}</div>
                </InfoModal>
            }
            <form
                className='flex flex-col space-y-2'
                onSubmit={handleSubmit(onSubmit)}
            >
                <Fade timeout={500} in={true}>
                    <div className="flex flex-col w-full gap-4">
                        <Controller
                            name="fn"
                            defaultValue={order?.client?.fn ?? ""}
                            control={control}
                            rules={{
                                required: t('REQ_ERR'),
                                pattern: {
                                    value: lettersOnly,
                                    message: t('LETTERS_ONLY_ERR'),
                                }
                            }}
                            render={({ field }) => <TextField
                                {...field}
                                label={"*" + t('FIRST_NAME')}
                                error={errors?.fn?.message}
                            />
                            } />
                        {errors.fn && <p className='text-red-600 text-sm font-semibold mt-2'>{errors.fn.message}</p>}

                        <Controller
                            name="ln"
                            defaultValue={order?.client?.ln ?? ""}
                            control={control}
                            rules={{
                                required: t('REQ_ERR'),
                                pattern: {
                                    value: lettersOnly,
                                    message: t('LETTERS_ONLY_ERR'),
                                }
                            }}
                            render={({ field }) =>
                                <TextField
                                    {...field}
                                    label={"*" + t('LAST_NAME')}
                                    error={errors?.ln?.message}
                                />
                            } />
                        {errors.ln && <p className='text-red-600 text-sm font-semibold mt-2'>{errors.ln.message}</p>}

                        <Controller
                            name="tel"
                            defaultValue={order?.client?.tel ?? ""}
                            control={control}
                            rules={{
                                required: t('REQ_ERR'),
                                pattern: {
                                    value: celIsraeli,
                                    message: t('TEL_ERR'),
                                }
                            }}
                            render={({ field }) =>
                                <TextField
                                    {...field}
                                    label={"*" + t('TEL')}
                                    error={errors.tel?.message}
                                />
                            } />
                        {errors.tel && <p className='text-red-600 text-sm font-semibold mt-2'>{errors.tel.message} ({t("NUMBERS_ONLY")})</p>}

                        <TextField
                            variant="outlined"
                            className='mt-4'
                            //value={notes}
                            defaultValue={order?.notes}
                            multiline
                            rows={3}
                            label={t('NOTES_FOR_ORDER')}
                            inputProps={{ maxLength: 250 }}
                            color='primary'
                            // sx={{ input: { color: 'cGroup.main' } }}
                            fullWidth
                            onKeyDown={(e) => setNotesHandler(e)}
                        //onChange={e => { setNotes(`${e.target.value}`) }}
                        //onChange={e => { setNotes(e.target.value) }}
                        />
                    </div>
                </Fade>

                {bizData?.bizTypeId === BUSINESS_TYPE_ID.RESTAURANT
                    ? <Box width={210}>
                        <Counter
                            label={
                                <Typography color="primary" variant="h6">
                                    {`${t('QUANTITY_DINNERS')}:`}
                                </Typography>}
                            size='xl'
                            onAdd={() => setDinners(dinner => dinner + 1)}
                            quantity={dinners}
                            onRemove={() => setDinners(dinner => dinner - 1)}
                            disableRemove={dinners === 1}
                        />
                    </Box>
                    : null
                }

                {/* Privacy Policy & Terms Of Use Checkbox */}
                <div className='flex flex-row items-center mt-6'>
                    <Controller
                        name="checkbox"
                        control={control}
                        rules={{
                            required: t('REQ_ERR'),
                        }}
                        render={({ field }) =>
                            <Checkbox
                                sx={[
                                    // { color: 'primary.main' }, 
                                    errors.checkbox && { color: 'red' },
                                    { '& .MuiSvgIcon-root': { color: 'primary.main' } }
                                ]}
                                className='flex justify-start'
                                onChange={(e) => { field.onChange(e.target.checked) }}
                                error={errors.checkbox !== undefined} />
                        }
                    />

                    <Typography className='text-sm space-x-1' color="primary">
                        <span>{t("READ_AND_ACCEPT")} </span>
                        {/* <Link
                            target="_blank"
                            rel='noopener'
                            href={PrivacyPolicy}
                            className='underline font-bold'
                        >
                            {t('PRIVACY_POLICY')}
                        </Link> */}

                        <a
                            href={PrivacyPolicy}
                            rel='noopener'
                            style={{ textDecoration: "underline" }}
                            target="_blank">
                            {t('PRIVACY_POLICY')}
                        </a>

                        <span>{t('AND')}</span>

                        <a
                            href={bizData?.bizTypeId === BUSINESS_TYPE_ID.RESTAURANT
                                ? TermsOfUseRestaurants
                                : TermsOfUseStores
                            }
                            rel='noopener'
                            style={{ textDecoration: "underline" }}
                            target="_blank">
                            {t('TERMS_OF_USE_AGREEMENT')}
                        </a>

                        {idForTermsBiz.includes(bizData?.id)
                            ? <>
                                <span>{t('AND')}</span>
                                <a
                                    href={termsBiz}
                                    rel='noopener'
                                    style={{ textDecoration: "underline" }}
                                    target="_blank">
                                    {t('TERMS_BIZ')}
                                </a>
                            </>
                            : null
                        }

                    </Typography>
                </div>
                {errors.checkbox && <p className='text-red-600 text-sm font-semibold'>{errors.checkbox.message}</p>}

                {/* Do not delete this (future use) */}
                {/* <ExtrasQty counts={counts} setCounts={setCounts} /> */}

                <PaymentButton
                    type="submit"
                    loading={isLoading}
                    sum={currency(order?.total)}
                    label={t('CONTINUE_TO_PAY')}
                />

                {/* <Button
                    disabled={isLoading}
                    type="submit"
                    sx={{
                        mt: 4,
                        width: '100%',
                        height: 55
                    }}
                    size="large"
                >
                    {isLoading
                        ? <Loader size={48} />
                        : <div className="grid grid-cols-2 2xl:grid-cols-3 w-full">
                            <span className="flex items-center text-xl">
                                {`${bizData?.currency}${currency((order?.address?.pr ?? 0) + order?.total)}`}
                            </span>

                            <span>{t('CONTINUE_TO_PAY')}</span>
                        </div>
                    }
                </Button> */}

                <TelApproveModal
                    setOldTel={setOldTel}
                    onSuccess={() => dispatch(setOrder({
                        step: STEPS.PAYMENT,
                        client: {
                            ...order.client,
                            telValid: true
                        }
                    }))}
                    isOpen={openTelModal}
                    setIsOpen={setOpenTelModal}
                />

                {/* Privacy Policy & Terms Of Use Dialog */}
                {/* <CustomModal
                    open={openDialog}
                    onClose={() => { setOpenDialog(false); setDialogContent() }}
                    closeAfterTransition
                    closeIcon
                    onClickOutsideToClose
                >
                    <Fade in={openDialog} onClick={() => { setOpenDialog(false); setDialogContent() }}>
                        <div style={{ width: 450, height: "80vh" }} >
                            <embed
                                src={dialogContent === 'Privacy Policy' ? PrivacyPolicy : TermsOfUse}
                                type="application/pdf"
                                width="100%"
                                height="100%"
                                className='object-contain'
                            >
                            </embed>
                        </div>
                    </Fade>
                </CustomModal> */}
            </form>
        </>
    )
}

export default ClientDetails;