import CustomModal from 'components/utils/CustomModal';
import Loading from 'components/utils/Loading';
import React, { useState, useEffect, useRef } from 'react';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Typography, Button } from '@mui/material';
import { useTheme } from '@emotion/react';
import CountdownTimer from 'components/utils/CountdownTimer';
import YpUtils from 'YpUtils';
import axios from 'axiosConn';

const codeSendingTime = 30;
const inputsCount = 4;

const TelPopup = ({ isOpen, setIsOpen, onSuccess, setOldTel }) => {
    let theme = useTheme();
    const inputsRefs = useRef([]);
    const { t } = useTranslation();
    const bizData = useSelector(state => state.bizData);
    const selectedLang = useSelector(state => state.userSettings.selectedLang);
    const client = useSelector(state => state.userSettings.order.client);
    const [value, setValue] = useState([]);
    const [error, setError] = useState(null);
    const [focusedIndex, setFocusedIndex] = useState(0);
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [codeIsValid, setCodeIsValid] = useState(false);
    const [resendTargetDate, setResendTargetDate] = useState(null);

    useEffect(() => {
        inputsRefs.current = inputsRefs.current.slice(0, inputsCount);
    }, []);

    useEffect(() => {
        setResendTargetDate(new Date().getTime() + codeSendingTime * 1000);
        setDisabled(true);
    }, [isOpen]);

    useEffect(() => {
        if (inputsRefs?.current[focusedIndex])
            inputsRefs?.current[focusedIndex].focus();

    }, [focusedIndex]);

    useEffect(() => {
        if (value.length === inputsCount) {
            setTimeout(() => {
                setLoading(true);

                let formData = YpUtils.objectToFormData({
                    code: value.join(''),
                    bizId: bizData?.id,
                    id: client?.id
                });

                axios
                    .put(`client/verify`, formData)
                    .then(response => {

                        if (response?.data?.id === 1) {
                            setError(false);
                            setCodeIsValid(true);
                            onSuccess && onSuccess();
                            setFocusedIndex(null);
                            setIsOpen(false);
                        }
                        else {
                            setError(true);
                            setLoading(false)
                            resetAll();
                        }
                    })
                    .catch(error => {
                        setError(true);
                        setLoading(false)
                        resetAll();
                    })
            }, 500);
        }
    }, [value]);

    const reSendCode = () => {
        axios
            .put(`client/reSendCode/${client?.id}/${selectedLang?.code}`)
            .then(response => {
                if (response?.data?.id === 1) {
                    setDisabled(true);
                    setResendTargetDate(new Date().getTime() + codeSendingTime * 1000);
                }
            })
    }

    const handleFocus = (index) => {
        if (index < 0 || index >= inputsCount)
            return;

        setFocusedIndex(index);
    };

    const handleChange = (e, index) => {
        setError(false);

        let newVal = [...value];
        newVal[index] = e.target.value;
        setValue(newVal);

        setFocusedIndex(index + 1);
    };

    const resetAll = () => {
        setValue([]);
        setFocusedIndex(0);
    }

    const handleKeyUp = e => {
        if (e.key === "Backspace") {
            setValue(value.slice(0, value.length - 1));
            handleFocus(focusedIndex - 1)
        }
    };

    const DigitInput = React.forwardRef(({ index }, ref) => {

        return (
            <input
                autoFocus={index === 0}
                value={value[index] > -1 ? value[index] : ""}
                ref={ref}
                onChange={(e) => handleChange(e, index)}
                onKeyUp={handleKeyUp}
                onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                maxLength="1"
                onClick={() => handleFocus(index)}
                className="text-center text-2xl lg:text-4xl w-12 h-16"
                style={{
                    backgroundColor: theme.palette.secondary.main,
                    color: theme.palette.primary.main,
                    border: error
                        ? (0 === index ? '3px solid red' : '1px solid red')
                        : (codeIsValid
                            ? '1px solid green'
                            : `solid 2px ${theme.palette.primary.main}`)
                }}
                disabled={codeIsValid || value?.length === 4}
                type="number"
                pattern="[0-9]*"
            />
        );
    });

    return (
        <>
            <CustomModal
                isMessageVisible={!loading}
                open={isOpen}
                onClose={() => {
                    setIsOpen(false)
                    setOldTel(client?.tel)
                }}
                closeIcon={loading ? false : true}
                title={loading ? "" : t('ENTER_CODE')}
            >
                {loading
                    ? <div className='w-full flex  justify-center h-full'>
                        <Loading />
                    </div>
                    : <>
                        <div className='flex flex-col justify-center items-center'
                            style={{ direction: 'ltr' }}>
                            <div className='space-x-2 text-2xl lg:text-4xl font-semibold'>
                                {Array(inputsCount).fill().map((id, idx) =>
                                    <DigitInput
                                        key={idx}
                                        ref={el => inputsRefs.current[idx] = el}
                                        index={idx}
                                    />
                                )}
                            </div>

                            {!codeIsValid &&
                                <div className='flex w-full justify-between'>
                                    <Typography className='mx-2 mt-2 underline cursor-pointer'
                                        color="primary"
                                        onClick={() => resetAll()}
                                    >
                                        {t('CLEAR')}
                                    </Typography>

                                    {error &&
                                        <div className='flex items-center text-red-600 text-sm mt-2'>
                                            <p className='mx-2'>{t('CODE_NOT_VALID')}</p>
                                            <CloseIcon />
                                        </div>
                                    }

                                    {codeIsValid &&
                                        <div className='flex items-center text-green-600 text-sm mt-2'>
                                            <DoneIcon />
                                            <Typography className='mx-2'>
                                                {t('CODE_VALID')}
                                            </Typography>
                                        </div>
                                    }
                                </div>
                            }

                            <div className='flex items-center mt-8'>
                                <Button
                                    disabled={disabled}
                                    variant="text"
                                    onClick={reSendCode}
                                >
                                    <Typography color="primary">
                                        {t('RESEND_CODE')}
                                    </Typography>
                                </Button>

                                {disabled &&
                                    <div className='px-2 text-lg'>
                                        <CountdownTimer
                                            targetDate={resendTargetDate}
                                            callBack={() => setDisabled(false)}
                                        />
                                    </div>
                                }
                            </div>
                        </div>
                    </>
                }
            </CustomModal>
        </>
    )
}

export default TelPopup;