import { Collapse, ListItemText, ListItemButton, Stack, Icon, Button, SvgIcon, useTheme } from '@mui/material';
import { useState } from 'react';
import ListItemIcon from '@mui/material/ListItemIcon';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

const MenuListItem = ({ title, icon, onClick, children, isButton, svg }, props) => {
    const [open, setOpen] = useState(false)
    const theme = useTheme()
    const handleClick = () => {
        setOpen(open => !open)
    }

    return (
        <Stack width='100%'>
            <ListItemButton onClick={children ? handleClick : onClick ? onClick : null}>
                {icon
                    ? <ListItemIcon>
                        <Icon color='primary'>{icon}</Icon>
                    </ListItemIcon>
                    : null}

                {svg
                    ? <ListItemIcon>
                        <SvgIcon
                            sx={{ fill: theme.palette.primary.main }}
                            component={svg}
                            inheritViewBox
                        />
                    </ListItemIcon>
                    : null
                }

                <ListItemText
                    primaryTypographyProps={{ color: 'primary' }}
                    primary={title} />

                {children
                    ? open
                        ? <ExpandLess />
                        : <ExpandMore />
                    : null}

                {isButton &&
                    <Button onClick={() => onClick}>
                        {isButton}
                    </Button>
                }
            </ListItemButton>

            {children ?
                <Collapse
                    in={open}
                    timeout="auto"
                    sx={{ paddingX: 2.5, paddingY: 1, color: 'primary.main' }}
                    unmountOnExit>
                    {children}
                </Collapse>
                : null
            }

        </Stack>
    )
}

export default MenuListItem;