import React, { useState, useEffect } from 'react';
import { Divider, Dialog, Card, Button, Typography, Rating } from "@mui/material";
import Loading from 'components/utils/Loading'
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axiosConn';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';
import CloseIcon from '@mui/icons-material/Close';
import RateInMedia from './RateInMedia';
import { setReviewsStamps } from 'store/userSettingsSlice';
import StarBorderIcon from "@mui/icons-material/StarBorder";

const Review = ({ onClose }) => {
    let theme = useTheme();
    let dispatch = useDispatch();
    const { t } = useTranslation();
    const bizData = useSelector(state => state.bizData);
    const selectedLang = useSelector(state => state.userSettings.selectedLang);
    let [showRateInMedia, setShowRateInMedia] = useState(false);
    const [rateData, setRateData] = useState(null);
    const [open, setOpen] = useState(false);

    const msgTypes = [
        { type: 1, msg: t("RATE_POST_SUCCESS") },
        { type: 2, msg: t("RATE_ALL_FIELDS") },
        { type: 3, msg: t("ERROR") },
    ]
    const [dialogMsg, setDialogMsg] = useState(msgTypes[0])

    useEffect(() => {
        const lCode = selectedLang.code.slice(0, 2);
        const getFeedbacks = (lCode) => {
            return new Promise((resolve, reject) => {
                axios
                    .get(`feeds/${bizData?.guid}/${lCode}`)
                    .then(response => {
                        if (response.data.id === 1) {
                            if (response.data.data.length > 0) setRateData(response.data.data);
                            else {
                                setRateData('noData')
                            }
                        }
                    })
            });
        };

        getFeedbacks(lCode);
    }, []);

    const handleChange = () => setOpen(!open);

    function getAvg(rates) {
        const total = rates.reduce((acc, c) => acc + c, 0);
        return total / rates.length;
    }

    const SetFeedbacks = (data, rates) => {
        return new Promise((resolve, reject) => {
            axios
                .post(`feed/${bizData?.guid}`, { feedbacks: data })
                .then(response => {
                    if (response.data.id === 1) {
                        dispatch(setReviewsStamps({
                            biz: bizData?.id,
                            date: new Date().toJSON()
                        }));

                        if (getAvg(rates) >= 4 && bizData?.reviews.length > 0)
                            setShowRateInMedia(true)
                        else
                            setOpen(true)

                    }
                    else {
                        setOpen(true);
                        setDialogMsg(msgTypes[2])
                    }
                })
                .catch(err => console.error(err))
        });
    };

    function onSubmit(values) {
        let fullData = true;
        const Data = [];
        const rates = [];
        rateData.forEach((rData, index) => {
            if (!rData.val)
                fullData = false;

            if (index === rateData.length - 1 && fullData === true)
                setDialogMsg(msgTypes[0]);

            let data = { 'id': rData.id, 'rate': rData.val };
            rates.push(rData.val);
            Data.push(data);
        });

        if (fullData)
            SetFeedbacks(Data, rates)
        else {
            setDialogMsg(msgTypes[1]);
            setOpen(true)
        }
    }

    const CloseHandle = () => {
        setOpen(!open);
        onClose();
    }

    if (!rateData) {
        return (
            <div className='w-full flex items-center justify-center h-full'>
                <Loading />
            </div>
        )
    }

    return <>
        <Dialog
            open={open}
            onClose={dialogMsg.type === 1 ? CloseHandle : handleChange}
            className='flex self-center justify-center'
        >
            <div className='w-64 h-40 m-auto flex flex-col justify-center'>
                <Typography className=' m-auto font-bold'> {dialogMsg.msg}</Typography>
                <div className='flex justify-center my-6'>
                    <Button
                        className='w-40'
                        onClick={dialogMsg.type === 1 ? CloseHandle : handleChange}>
                        {t('ACCEPT')}
                    </Button>
                </div>
            </div>
        </Dialog>

        <Card
            className='lg:w-2/5 p-6 lg:m-auto  lg:mt-6 lg:mb-24 h-full'
        >
            <div className='flex justify-end'>
                <CloseIcon onClick={onClose} sx={{ cursor: 'pointer' }} />
            </div>

            {showRateInMedia
                ? <RateInMedia onClose={onClose} />
                : rateData
                    ? <>
                        {rateData !== 'noData'
                            ? <>
                                <Typography
                                    className="font-bold text-lg lg:text-2xl text-center mb-6"
                                >
                                    {t('RESTREVIEW')}
                                </Typography>

                                <div className="m-auto">
                                    {rateData?.map((field, index) =>
                                        <div
                                            key={index}
                                            className='p-2'
                                        >
                                            <div className='flex justify-between'>
                                                <Typography
                                                    className="font-bold text-lg lg:text-2xl">
                                                    {field.name}
                                                </Typography>

                                                <Rating
                                                    value={field.val && field.value}
                                                    name={field.name}
                                                    onChange={(event, newValue) => {
                                                        field.val = newValue;
                                                    }}
                                                    emptyIcon={
                                                        <StarBorderIcon
                                                            fontSize="inherit"
                                                            sx={{
                                                                cursor: 'pointer'
                                                            }}
                                                        />
                                                    }
                                                />
                                            </div>

                                            <Typography
                                                className='text-sm md:text-base'
                                            >
                                                {field.description}
                                            </Typography>

                                            <Divider
                                                style={{
                                                    height: 2,
                                                    opacity: 0.5,
                                                    margin: 6
                                                }}
                                            />
                                        </div>)
                                    }
                                </div>
                            </>
                            : null
                        }

                        <div className='flex justify-center mt-12'>
                            <Button
                                className='w-40'
                                onClick={onSubmit}
                            >
                                {t('SEND')}
                            </Button>
                        </div>
                    </>
                    : <Loading />
            }
        </Card>
    </>
}

export default Review;