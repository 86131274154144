import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    list: [],
    products: {},
    activeUpsale: {},
    isOpen: false,
}

export const upsalesSlice = createSlice({
    name: 'upsales',
    initialState: initialState,
    reducers: {
        checkUpsales: (state, action) => {
            // const prod = action.payload
            // state.list.forEach((upsale) => {
            //     if (upsale.items.categoryIds.includes(prod.catId)) {
            //         console.log('prod.catId', prod.catId);
            //     }
            //     else if (upsale.items.menuItemIds.includes(prod.id)) {
            //         console.log('prod.id', prod.id);
            //     }

            // })
        },
        setUpsalesList: (state, action) => { state.list = action.payload },
        setActiveUpsale: (state, action) => {
            state.activeUpsale = action.payload;
            state.isOpen = action.payload !== null;
            return state;
        },
        setProducts: (state, action) => { state.products = action.payload },
        setIsOpen: (state, action) => { state.isOpen = action.payload },
    },
})

export const {
    setUpsalesList,
    setActiveUpsale,
    setProducts,
    setIsOpen,
    checkUpsales
} = upsalesSlice.actions

export default upsalesSlice.reducer