import { createTheme, darken, lighten, ThemeProvider } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { CacheProvider } from '@emotion/react';
import rtlPlugin from 'stylis-plugin-rtl';
import React, { useEffect } from 'react';
import createCache from '@emotion/cache';
import { createBrowserRouter, RouterProvider, Outlet, Navigate } from "react-router-dom";
import Main from './containers/Main';
import OrderSummary from './containers/OrderSummary';
import Error404 from './containers/errors/error404';
import ApiDataProvider from 'components/utils/ApiDataProvider';
import CcPaymentSuccess from 'containers/CcPaymentSuccess';
import MetaPixel from 'components/utils/MetaPixel';
import ReactGA from 'react-ga4';
import SignalRUpdateApp from 'components/SignalRUpdateApp';

const App = () => {
    const bizData = useSelector(state => state.bizData);
    const selectedLang = useSelector(state => state.userSettings.selectedLang);

    useEffect(() => {
        document.oncontextmenu = document.body.oncontextmenu = () => false;
    }, []);

    // googleAnalytics
    useEffect(() => {
        if (bizData?.googleAnalytics) {
            ReactGA.initialize(bizData?.googleAnalytics);
            ReactGA.set({ page: window.location.pathname });
            // ReactGA.pageview(window.location.pathname);
            ReactGA.send({ hitType: "pageview", page: window.location.pathname });
        }
        else {
            return
        }
    }, [window.location.pathname, bizData?.googleAnalytics]);

    const clPrimary = bizData?.theme?.colorD ?? "#fff";
    const clSecondary = bizData?.theme?.colorA ?? "#000";
    const clButtons = bizData?.theme?.colorC ?? "#000";
    const clTextCats = bizData?.theme?.colorE ?? "#000";
    const clBackground = bizData?.theme?.colorB ?? "#000";

    const clC = bizData?.theme?.colorC ?? "#000";
    const clE = bizData?.theme?.colorE ?? "#000";

    const disabledOpacity = 0.6;
    // const { palette } = createTheme();
    // const { augmentColor } = palette;
    // const createColor = (mainColor) => augmentColor({ color: { main: mainColor } });

    // getColorObject(tinycolor.mix(baseLight, hex, 12), '50'),
    // getColorObject(tinycolor.mix(baseLight, hex, 30), '100'),
    // getColorObject(tinycolor.mix(baseLight, hex, 50), '200'),
    // getColorObject(tinycolor.mix(baseLight, hex, 70), '300'),
    // getColorObject(tinycolor.mix(baseLight, hex, 85), '400'),
    // getColorObject(tinycolor.mix(baseLight, hex, 100), '500'),
    // getColorObject(tinycolor.mix(baseDark, hex, 87), '600'),
    // getColorObject(tinycolor.mix(baseDark, hex, 70), '700'),
    // getColorObject(tinycolor.mix(baseDark, hex, 54), '800'),
    // getColorObject(tinycolor.mix(baseDark, hex, 25), '900'),
    // getColorObject(tinycolor.mix(baseDark, baseTriad[3], 15).saturate(80).lighten(48), 'A100'),
    // getColorObject(tinycolor.mix(baseDark, baseTriad[3], 15).saturate(80).lighten(36), 'A200'),
    // getColorObject(tinycolor.mix(baseDark, baseTriad[3], 15).saturate(100).lighten(31), 'A400'),
    // getColorObject(tinycolor.mix(baseDark, baseTriad[3], 15).saturate(100).lighten(28), 'A700')

    const theme = createTheme({
        typography: {
            fontFamily: ['Heebo', 'sans-serif'].join(','),
        },
        palette: {
            primary: {
                main: clPrimary,
                contrastText: clSecondary,
            },
            secondary: {
                main: clSecondary,
                contrastText: clPrimary,
            },
            background: {
                main: clBackground
            },
            clButtons: {
                main: clButtons
            },
            clCatTitle: {
                main: clTextCats
            },
        },

        components: {
            // MuiPaper: {
            //     styleOverrides: {
            //         root: {
            //             backgroundColor: clA,
            //         }
            //     }
            // },
            // MuiMenuItem: {
            //     styleOverrides: {
            //         root: {
            //             color: clD,
            //             "&.Mui-selected": {
            //                 backgroundColor: clD,
            //                 color: clA
            //             },
            //             "&.Mui-selected:hover": {
            //                 backgroundColor: lighten(clD, 0.3),
            //                 color: clA
            //             },
            //             "&:hover": {
            //                 backgroundColor: lighten(clD, 0.3),
            //                 color: clA
            //             }
            //         }
            //     }
            // },
            MuiTextField: {
                styleOverrides: {
                    // input: {
                    //     color: clPrimary,
                    //     '&::before': {
                    //         borderColor: clPrimary
                    //     }
                    // },
                    root: {
                        "& .MuiInputLabel-root": {
                            color: clPrimary
                        },
                        "& .MuiOutlinedInput-root": {
                            "& > fieldset": {
                                borderColor: clPrimary,
                                color: clPrimary,
                            },
                        },
                        "& .MuiOutlinedInput-input": {
                            backgroundColor: clSecondary,
                            color: clPrimary,
                        },

                        "& .MuiOutlinedInput-root:hover": {
                            "& > fieldset": {
                                borderColor: clPrimary
                            }
                        },
                        // "& .MuiOutlinedInput-root.Mui-focused": {
                        //     "& > fieldset": {
                        //         borderColor: clPrimary,
                        //         color: clPrimary
                        //     },
                        // },
                        // "& .MuiInputBase-input": {
                        //     borderColor: clPrimary,
                        //     color: clPrimary
                        // },
                    },
                },
            },
            MuiPaper: {
                styleOverrides: {
                    root: ({ theme }) => ({
                        backgroundColor: clSecondary,
                        color: clPrimary
                    })
                }
            },
            MuiButton: {
                defaultProps: { variant: "contained" },
                styleOverrides: {
                    root: ({ theme }) => ({
                        /** 
                         * Without this style, buttons will be transparent on disabled in certain colors
                         * 
                         * contained button style 
                         * */
                        "&.MuiButton-contained": {
                            color: clSecondary,
                            backgroundColor: clButtons
                        },
                        "&.MuiButton-containedSecondary": {
                            color: clButtons,
                            backgroundColor: clSecondary
                        },
                        "&.MuiButton-contained.Mui-disabled": {
                            opacity: 0.33,
                        },
                        /** outlined button style */
                        "&.MuiButton-outlined": {
                            color: clButtons,
                            borderColor: clButtons
                        },
                        "&.MuiButton-outlined.Mui-disabled": {
                            opacity: disabledOpacity,
                        },
                        borderRadius: 50
                    }),
                },
            },
            MuiIconButton: {
                defaultProps: { variant: "contained" },
                styleOverrides: {
                    root: ({ theme }) => ({
                        color: clButtons,
                    }),
                },
            },
            MuiCheckbox: {
                defaultProps: { variant: "contained" },
                styleOverrides: {
                    root: ({ theme }) => ({
                        '& .MuiSvgIcon-root': {
                            color: clButtons,
                        }
                    }),
                },
            },
            MuiChip: {
                styleOverrides: {
                    root: ({ theme }) => ({
                        backgroundColor: clSecondary,
                        // border: `1px solid ${theme.palette.secondary.main}`,
                        // border: `1px solid ${clButtons}`,
                        color: clPrimary
                    })
                }
            },
            MuiRadio: {
                styleOverrides: {
                    root: ({ theme }) => ({
                        color: clButtons,
                        '&.Mui-checked': {
                            color: clButtons,
                        }
                    })
                }
            },
        },
        disabledOpacity: disabledOpacity,
        select: {
            color: clPrimary,
            '.MuiOutlinedInput-notchedOutline': {
                borderColor: clPrimary,
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: clPrimary,
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: clPrimary,
            },
            '.MuiSvgIcon-root ': {
                fill: clPrimary,
            },
            '&.Mui-disabled .MuiSvgIcon-root ': {
                opacity: disabledOpacity,
            },

            '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
                opacity: disabledOpacity,
                borderColor: clPrimary,
            },
        },
        scrollBar: {
            '&::-webkit-scrollbar': {
                width: '0.5em',
                height: '0.5em',
                borderRadius: '0px',
                backgroundColor: clSecondary,
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: clPrimary,
            },
            '::-webkit-scrollbar-track': {
                backgroundColor: 'rgba(0, 0, 0, 0.18)',
                //boxShadow: 'inset 0 0 5px grey',
            },
        },
        direction: selectedLang?.isRtl ? 'rtl' : 'ltr',
    });

    const emotionCacheOptions = {
        rtl: {
            key: 'muirtl',
            stylisPlugins: [rtlPlugin],
            prepend: true,
        },
        ltr: {
            key: 'muiltr',
            stylisPlugins: [],
            prepend: true,
        },
    };

    const router = createBrowserRouter([
        {
            path: "/",
            element: <Error404 />,
            errorElement: <Error404 />,
        },
        {
            path: "/:guid",
            element: <ApiDataProvider><Outlet /></ApiDataProvider>,
            errorElement: <Error404 />,
            children: [
                {
                    index: true,
                    element: <Main />,
                },
                {
                    path: ":menuId",
                    element: <Main />,
                },
                {
                    path: "payment-success/:orderGuid",
                    element: <CcPaymentSuccess />,
                },

                // {
                //     path: ":menuId/:catId",
                //     element: <Main />,
                // },
                // {
                //     path: ":menuId/:catId/:prodId",
                //     element: <Main />,
                // },
            ],
        },
        // {
        //     path: "/:guid/menu-list",
        //     element: <MenuList />,
        // },
        {
            path: "order-summary/:orderGuid",
            errorElement: <Error404 />,
            element: <OrderSummary />,
        },
    ]);

    return (
        <CacheProvider value={createCache(emotionCacheOptions[selectedLang?.isRtl ? 'rtl' : 'ltr'])}>
            <ThemeProvider theme={theme}>
                <SignalRUpdateApp />
                <MetaPixel />
                <RouterProvider router={router} />
            </ThemeProvider>
        </CacheProvider>
    )
}

export default App;