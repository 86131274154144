import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Loading from 'components/utils/Loading';
import { checkCatsTiming } from 'store/menuSlice';
import { loadUserSettingsFromStorage } from 'store/userSettingsSlice';
import Menu from 'components/Menu/Index';
import MenuList from 'components/Menu/MenusList';
import InfoModal from 'components/utils/CustomModal';
import { useTranslation } from 'react-i18next';
import PreOrder, { STEPS } from 'components/Orders'
import ActionDetector from 'components/utils/ActionDetector';
import CheckBizClosingTime from 'components/CheckBizClosingTime';
import { useParams } from "react-router-dom";
import Overload from 'components/Overload';

const Main = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { menuId } = useParams();
    const bizData = useSelector(state => state.bizData);
    const order = useSelector(state => state.userSettings.order);
    const isIframe = useSelector(state => state.appSettings.isIframe);
    const [openOverload, setOpenOverload] = useState()

    useEffect(() => {
        const checkCatsTimer = setInterval(() => {
            // bizData?.hasOrderMenu !== null && checkBizTiming(bizData, t, dispatch);
            dispatch(checkCatsTiming());
        }, 60000);

        return () => clearInterval(checkCatsTimer);
    }, [])

    useEffect(() => {
        if (bizData && order.step === STEPS.ORDER_TYPE) {
            dispatch(loadUserSettingsFromStorage());
            setOpenOverload(bizData?.orderSettings?.isOverload)
        }
    }, [bizData, order.step])


    if (!bizData)
        return <div className='h-screen w-full flex justify-center items-center'>
            <Loading size={120} />
        </div>

    if (bizData?.selectedLang?.bizName != null)
        document.title = bizData?.selectedLang?.bizName

    /* Biz doesn't have menu feature enabled in the Back Office or in his current plan*/
    if (bizData.hasWebApp == false)
        return <InfoModal
            open={true}
            msg={t('HAS_NO_WEBAPP')}
        />

    /* Todo:// do we need it? */
    // if (isIframe)
    //     return <Menu />

    /* Normal simple menu */
    if (!bizData.hasOrderMenu || isIframe) {
        return menuId ? <Menu /> : <MenuList />
    }
    /* Menu with orders feature */
    else {
        return (
            <>
                <ActionDetector />
                <CheckBizClosingTime />
                <Overload open={openOverload} setOpen={setOpenOverload} />

                {order?.step === STEPS.MENU_LIST
                    ? menuId
                        ? <Menu />
                        : <MenuList />
                    : <PreOrder />
                }
            </>
        )
    }
}

export default Main;