const isStage = false;
export const versionSys = "1.14.10";

export const MOLO_PRIMARY_COLOR = '#32074F'
export const MOLO_SECONDARY_COLOR = '#CA61C3'

//const baseApiUrlLocal = "https://192.168.1.17:45455/";
const baseApiUrlLocal = "https://192.168.1.15:45459/";
// const baseApiUrlLocal = "https://localhost:44391/";
// const baseApiUrlLocal = "https://molo-apiserver-dev.azurewebsites.net/";
// const baseApiUrlLocal = "https://192.168.1.6/";
const baseApiUrl = isStage ? "https://stage.api.menu.molo.services/" : "https://api.menu.molo.services/";

/* Time in minutes to save data in storage */
export const timeInMins = 20;

export const dateFormat = "DD/MM/YY";
export const timeFormat = "HH:mm";
export const dateTimeFormat = "DD/MM/YY | HH:mm";


const settingsConfig = {
  apiUrl: !process.env.NODE_ENV || process.env.NODE_ENV === "development" ? baseApiUrlLocal : baseApiUrl,
  imgApiUrl: baseApiUrl
};

export default settingsConfig;