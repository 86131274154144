import moment from "moment";
import axios from 'axiosConn';
import Lottie from "react-lottie-player";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Loading from 'components/utils/Loading';
import lottieJson from "assets/success.json";
import { useTheme } from "@emotion/react";
import { useParams } from "react-router-dom";
import OrderStausTracker from "components/Orders/OrderStausTracker";
import { MOLO_PRIMARY_COLOR, dateTimeFormat } from "configs/settingsConfig";
import PoweredBy from '../components/utils/PoweredBy'
import { useMenu } from "hooks/useMenu";
import {
    Box,
    List,
    ListItem,
    Divider,
    Button,
    Table,
    TableRow,
    TableBody,
    TableHead,
    TableCell,
    Typography,
    TableContainer,
    Card,
    Stack,
    Alert,
} from "@mui/material";
import {
    ACTIVE_ORDER_STATUS,
    ORDER_STATUS_ID,
    ORDER_WORKFLOW_STATUS_ID,
    PAYMENTS_TYPE_ID,
    PAYMENT_STATUS_ID,
    getKeyByValue
} from "configs/consts";
import useCurrency from "hooks/useCurrency";
import { setUpsalesList } from "store/upsalesSlice";
import { useDispatch, useSelector } from "react-redux";

const OrderSummary = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { currency } = useCurrency();
    const { orderGuid } = useParams();
    const { resetMenu } = useMenu();
    const { list } = useSelector(state => state.upsales);
    const [data, setData] = useState(null);
    const [erorData, setErorData] = useState(false);
    const [isloading, setIsLoading] = useState(true);
    const [totalCartPrice, setTotalCartPrice] = useState(0);

    useEffect(() => {
        if (orderGuid)
            axios.get(`GetOrderInfo/${orderGuid}`)
                .then((res) => {
                    if (res?.data.id === 1) {
                        setData(res?.data.data)
                        if (res?.data?.data?.items)
                            calcCartItemsPrice(res?.data?.data?.items)
                        setIsLoading(false)
                        setErorData(false)
                    }
                    else {
                        setIsLoading(false)
                        setErorData(true)
                    }
                })
                .catch((err) => {
                    if (err.response.status !== 429) {
                        setIsLoading(false)
                        setErorData(true)
                    }
                })
    }, [orderGuid])

    const calcCartItemsPrice = (items) => {
        let pr = 0;
        items?.forEach((item) => {
            pr += calcItemTotalPr(item);
        })
        setTotalCartPrice(pr);
    }

    const calcItemTotalPr = (item) => {
        return (calcItemModifiersTotalPrice(item) * item.qty) + (item.qty * item.pr);
    }

    const calcItemModifiersTotalPrice = (item) => {
        let total = 0;
        item.grps.forEach((grp) => {
            grp.ings.forEach((i) => {
                if (i.pr > 0) {
                    total += (i.qty * i.pr)
                }
            })
        })
        return total
    }

    const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {

        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (
            let offset = 0;
            offset < byteCharacters.length;
            offset += sliceSize
        ) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);
            const byteNumbers = new Array(slice.length);

            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });

        return blob;
    };

    const downloadFile = (url) => {
        const contentType = "data:application/pdf";
        const b64Data = url
        const blob = b64toBlob(b64Data, contentType);
        const blobUrl = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = blobUrl;
        a.download = `Molo - הזמנה מספר ${data.id}.pdf`;
        a.click();
    }

    const backToMenuHandler = () => {
        if (list) {
            const resetUpsale = list.map(upsale => { return { ...upsale, isShow: false } })
            dispatch(setUpsalesList(resetUpsale))
        }
        resetMenu(data?.bizGuid);
    }

    if (isloading && !data)
        return (
            <div className='w-full flex justify-center h-screen items-center'>
                <Loading />
            </div>
        )

    if (erorData)
        return (
            <div className="flex w-full h-screen justify-center items-center">
                <Card className='flex justify-center items-center p-6'>
                    <Typography variant="h4">{t("NO_ORDER_FOUND")}</Typography>
                </Card>
            </div>
        )

    return (
        <div style={{ direction: 'rtl' }}>
            <div className='lg:w-1/4 mx-auto h-full flex flex-col items-center justify-center'>
                <Box
                    className='overflow-scroll hide-scrollbar w-full'
                >
                    <Box className='flex flex-col w-full gap-4 p-4'>

                        <div className="flex w-full h-full items-center bg-gray-200 gap-4 p-4">
                            <img src={data.bizLogoUrl} className="w-16 h-16 object-contain" />

                            {data.bizName &&
                                <Typography className="w-full font-bold text-xl">
                                    {`${t('THANK_YOU_ORDER')} ${data.bizName}`}
                                </Typography>
                            }
                        </div>

                        <div>
                            {data.statusTypeId === ORDER_WORKFLOW_STATUS_ID.CREATED &&
                                data.isApprovalNeeded &&
                                <Typography className="text-center font-semibold text-m">
                                    {t("MSG_WILL_BE_SENT")}
                                </Typography>
                            }

                            <Lottie
                                loop={false}
                                animationData={lottieJson}
                                play
                                style={{ height: 140 }}
                            />

                            <Typography className="text-center font-semibold">
                                {t('ORDER_ID')}: {data.id}
                            </Typography>
                        </div>

                        {data.showOrderTracker &&
                            ACTIVE_ORDER_STATUS.includes(data?.statusTypeId)
                            ? <OrderStausTracker
                                typeId={data.typeId}
                                orderGuid={orderGuid}
                            />
                            : null
                        }

                        {(data?.statusTypeId === PAYMENT_STATUS_ID?.REFUNDED ||
                            data?.statusTypeId === ORDER_STATUS_ID?.CANCELED)
                            ? <Alert sx={{ alignItems: 'center' }} severity="error">
                                <Typography fontWeight='bold'>
                                    {t(data?.statusTypeId === PAYMENT_STATUS_ID?.REFUNDED
                                        ? "ORDER_REFUNDED"
                                        : data?.statusTypeId === ORDER_STATUS_ID?.CANCELED_BY_BIZ
                                            ? "ORDER_CANCELED_BY_BIZ"
                                            : null)}
                                </Typography>
                            </Alert>
                            : null
                        }

                        <Typography
                            className='text-center font-bold text-xl'
                            color={MOLO_PRIMARY_COLOR}
                        >
                            {t('FINISH_ORDER_DETAILS')}
                        </Typography>

                        <TableContainer>
                            <Table size="small" >
                                <TableHead
                                    style={{ backgroundColor: MOLO_PRIMARY_COLOR }}
                                >
                                    <TableRow>
                                        <TableCell
                                            component="th"
                                            scope="row">
                                            <Stack direction={'row'} justifyContent={'space-between'}>
                                                <Typography
                                                    className="font-bold"
                                                    color='white'
                                                >
                                                    {t("ITEM_NAME")}
                                                </Typography>
                                                <Typography
                                                    className="font-bold"
                                                    color='white'
                                                >
                                                    {t("QUANTITY")}
                                                </Typography>
                                            </Stack>
                                        </TableCell>
                                        <TableCell
                                            sx={{ borderLeft: `1px solid ${theme.palette.grey[200]}` }}
                                            className="text-center"
                                        >
                                            <Typography
                                                className="font-bold"
                                                color='white'
                                            >
                                                {t("ITEM_SUM")}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>

                                </TableHead>
                                {data.items?.map((item, index) => {
                                    const itemTotalPr = calcItemTotalPr(item);
                                    return (
                                        <TableBody
                                            key={index}
                                            style={
                                                index % 2
                                                    ? { background: theme.palette.background.paper }
                                                    : { background: theme.palette.background.default }
                                            }
                                        >
                                            <TableRow
                                                sx={{ background: theme.palette.divider }}
                                            >
                                                <TableCell>
                                                    <div className="flex w-full flex-col">
                                                        <div className="flex justify-between font-bold items-center">
                                                            <span style={{ fontSize: 16, fontWeight: 'bold' }}>
                                                                {item?.name}
                                                            </span>
                                                            <span className='justify-end mx-4'>
                                                                {item.qty}
                                                            </span>
                                                        </div>

                                                        {item?.belongsTo
                                                            ? <span style={{ fontSize: 16, fontWeight: 500 }}>
                                                                <b>{t('BELONGS_TO')} </b> {`- ${item.belongsTo} `}
                                                            </span>
                                                            : null
                                                        }

                                                        {item?.remarks
                                                            ? <span style={{ fontSize: 16, fontWeight: 500 }}>
                                                                <b>{t('REMARKS')} </b> {`- ${item.remarks} `}
                                                            </span>
                                                            : null
                                                        }

                                                    </div>

                                                </TableCell>
                                                <TableCell
                                                    className="text-center"
                                                    sx={{
                                                        borderLeft: `1px solid ${theme.palette.grey[200]}`,
                                                    }}
                                                >
                                                    <Typography className="font-bold">
                                                        {currency(item.pr * item.qty)}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>

                                            {/*=============== ing=============== */}
                                            {item?.grps?.map((group, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <TableRow key={group.id}>
                                                            <TableCell colSpan={4}>
                                                                <Typography className=" text-sm font-bold" >
                                                                    {group.name}
                                                                </Typography>
                                                            </TableCell>
                                                        </TableRow>

                                                        {group?.ings?.map((ing) => {
                                                            return (
                                                                <TableRow key={ing.id}>
                                                                    <TableCell>
                                                                        {/* <Typography>{ing.qty} X {ing?.name}</Typography> */}
                                                                        <div className="flex justify-between items-center">
                                                                            <span>{ing?.name}</span>
                                                                            <span className='justify-end font-bold mx-4'>
                                                                                {ing.qty * item.qty}
                                                                            </span>
                                                                        </div>
                                                                    </TableCell>
                                                                    <TableCell
                                                                        className="text-center"
                                                                        sx={{
                                                                            borderLeft: `1px solid ${theme.palette.grey[200]}`,
                                                                        }}
                                                                    >
                                                                        <Typography>
                                                                            {currency(ing.pr * ing.qty * item.qty)}
                                                                        </Typography>
                                                                    </TableCell>
                                                                </TableRow>
                                                            )
                                                        })
                                                        }
                                                    </React.Fragment>
                                                )
                                            })}

                                            {/* Single item total price including modifiers and quantity */}
                                            {item?.grps.length > 0
                                                ? <TableRow>
                                                    <TableCell>
                                                        <Typography fontWeight={'bold'}>
                                                            {t('TOTAL_ITEM_PRICE')}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell
                                                        sx={{
                                                            borderLeft: `1px solid ${theme.palette.grey[200]}`,
                                                            textAlign: 'center'
                                                        }}
                                                    >
                                                        <Typography fontWeight={'bold'}>
                                                            {currency(itemTotalPr)}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                                : null
                                            }

                                        </TableBody>
                                    )
                                })}

                                {/* Cart items total price */}
                                <TableRow sx={{ borderTop: '1px solid black' }}>
                                    <TableCell>
                                        <Typography variant="h6" fontWeight={'bold'}>
                                            {t('ITEM_SUM')}
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        sx={{ textAlign: 'center' }}
                                    >
                                        <Typography variant="h6" fontWeight={'bold'}>
                                            {currency(totalCartPrice)}
                                        </Typography>
                                    </TableCell>
                                </TableRow>

                            </Table>
                        </TableContainer>

                        <List>
                            <ListItem
                                className='grid grid-cols-2 gap-12'
                                style={{ textAlign: 'start' }}
                            >
                                <Typography>{t('ORDER_TYPE')}:</Typography>
                                <Typography className='font-semibold'>
                                    {data.type}
                                </Typography>
                            </ListItem>

                            <Divider />

                            <ListItem
                                className='grid grid-cols-2 gap-12'
                                style={{ textAlign: 'start' }}
                            >
                                <Typography>{t('ORDER_CREATED')}:</Typography>
                                <Typography className='font-semibold'>
                                    {moment(data.created).format(dateTimeFormat)}
                                </Typography>
                            </ListItem>

                            <Divider />

                            {data.tableName &&
                                <>
                                    <Divider />
                                    <ListItem

                                        className='grid grid-cols-2 gap-12'
                                        style={{ textAlign: 'start' }}
                                    >
                                        <Typography>{t('TABLE_NAME')}:</Typography>
                                        <Typography
                                            className='font-semibold'
                                        >
                                            {data.tableName}
                                        </Typography>
                                    </ListItem>
                                </>
                            }
                            <Divider />

                            <ListItem

                                className='grid grid-cols-2 gap-12'
                                style={{ textAlign: 'start' }}
                            >
                                <Typography>{t('ORDER_SUM')}:</Typography>
                                <Typography
                                    className='font-semibold'
                                >
                                    {currency(totalCartPrice)}
                                </Typography>
                            </ListItem>
                            <Divider />

                            {data?.commission
                                ?
                                <>
                                    <ListItem

                                        className='grid grid-cols-2 gap-12'
                                        style={{ textAlign: 'start' }}
                                    >
                                        <Typography>{t('COMM_PR')}:</Typography>
                                        <Typography
                                            className='font-semibold'
                                        >
                                            {currency(data.commission)}
                                        </Typography>
                                    </ListItem>
                                    <Divider />
                                </>
                                : null
                            }
                            {data?.discount
                                ?
                                <>
                                    <ListItem
                                        className='grid grid-cols-2 gap-12'
                                        style={{ textAlign: 'start' }}
                                    >
                                        <Typography>{data.saleName}:</Typography>
                                        <Typography
                                            className='font-semibold'
                                        >
                                            {currency(- data.discount)}
                                        </Typography>
                                    </ListItem>
                                    <Divider />
                                </>
                                : null
                            }

                            {(data.deliveryPr > 0 && data.type === 'משלוח') &&
                                <>
                                    <ListItem
                                        className='grid grid-cols-2 gap-12'
                                        style={{ textAlign: 'start' }}>
                                        <Typography>{t('DELIVERY_COST')}:</Typography>
                                        <Typography
                                            className='font-semibold'>
                                            {currency(data.deliveryPr)}
                                        </Typography>
                                    </ListItem>
                                    <Divider />
                                </>
                            }
                            {data.tip > 0 &&
                                <>
                                    <ListItem
                                        className='grid grid-cols-2 gap-12'
                                        style={{ textAlign: 'start' }}>
                                        <Typography>{t('DELIVERY_TIP')}:</Typography>
                                        <Typography
                                            className='font-semibold'
                                        >
                                            {currency(data.tip)}
                                        </Typography>
                                    </ListItem>
                                    <Divider />
                                </>
                            }

                            {data.discount > 0 &&
                                <>
                                    <ListItem
                                        className='grid grid-cols-2 gap-12'
                                        style={{ textAlign: 'start' }}>
                                        <Typography>{t('DISCOUNT')}:</Typography>
                                        <Typography
                                            className='font-semibold'
                                        >
                                            {`${currency(data.discount)}`}

                                        </Typography>
                                    </ListItem>
                                    <Divider />
                                </>
                            }

                            {(data.tip > 0 || data.ttlPr > 0) &&
                                <>
                                    <ListItem
                                        className='grid grid-cols-2 gap-12'
                                        style={{ textAlign: 'start' }}>
                                        <Typography>{t('TOTAL_PAYMENT')}:</Typography>
                                        <Typography
                                            className='font-semibold'
                                        >
                                            {currency(data?.ttlPr)}
                                        </Typography>
                                    </ListItem>
                                    <Divider />
                                </>
                            }
                            {(data.deliveryEstTime > 0 &&
                                data.type === 'משלוח' &&
                                !data.forFutureDt) &&
                                <>
                                    <ListItem
                                        className='grid grid-cols-2 gap-12'
                                        style={{ textAlign: 'start' }}>
                                        <Typography>{t('DELIVERY_TIME')}:</Typography>
                                        <Typography
                                            className='font-semibold'
                                        >
                                            {data.deliveryEstTime} {t('MINUTES')}
                                        </Typography>
                                    </ListItem>
                                    <Divider />
                                </>
                            }

                            {/* זמן הכנה משוער */}
                            {data.forFutureDt &&
                                <>
                                    <ListItem
                                        className='grid grid-cols-2 gap-12'
                                        style={{ textAlign: 'start' }}>
                                        <Typography>{t('FUTURE_ORDER_TO')}:</Typography>
                                        <Typography
                                            className='font-semibold'>
                                            {moment(data.forFutureDt).format('DD/MM/YY HH:mm')}
                                        </Typography>
                                    </ListItem>
                                    <Divider />
                                </>
                            }

                            {/* הערות להזמנה */}
                            {data.notes &&
                                <>
                                    <ListItem className={`flex flex-col items-start`}>
                                        <Typography className='font-semibold'>
                                            {t('NOTES_FOR_ORDER')}:
                                        </Typography>
                                        <Typography
                                            className='text-right'
                                        >
                                            {data.notes}
                                        </Typography>
                                    </ListItem>
                                    <Divider />
                                </>
                            }

                            {/* כתובת למשלוח */}
                            {data.client?.addrName &&
                                <>
                                    <ListItem
                                        className={`flex flex-col items-start`}>
                                        <Typography
                                            className='font-semibold'>
                                            {t('DELIVERY_ADDR')}
                                        </Typography>

                                        <div className="flex flex-col items-start" >

                                            <Typography>
                                                {data.client?.addrName}, {data.client?.city}
                                            </Typography>

                                            {data.client?.apt && <Typography>
                                                {t('APPT')}: {data.client?.apt}
                                            </Typography>}

                                            {data.client?.floor && <Typography>
                                                {t('FLOOR')}: {data.client?.floor}
                                            </Typography>}

                                            {data.client?.entry && <Typography>
                                                {t('ENTRY')}: {data.client?.entry}
                                            </Typography>}

                                            {data.client?.addrNote && <>
                                                <br />
                                                <span className='font-bold'>{t('ADDR_NOTES')}:</span> {data.client?.addrNote}
                                            </>}
                                        </div>
                                    </ListItem>
                                    <Divider />
                                </>
                            }

                            {data.client?.fn &&
                                <>
                                    <ListItem className={`flex flex-col items-start`}>
                                        <Typography
                                            className='font-semibold'>
                                            {t('PERSONAL_DETAILS')}
                                        </Typography>
                                        <Typography>
                                            {`${t("FULL_NAME")}: ${data.client.fn} ${data.client.ln ? data.client.ln : ""}`}
                                        </Typography>

                                        {data.client?.tel &&
                                            <Typography>
                                                {`${t("TEL")}: ${data.client.tel}`}
                                            </Typography>
                                        }
                                    </ListItem>
                                    <Divider />
                                </>
                            }
                            <Stack padding={2}>
                                <Typography fontWeight='bold'>
                                    {t('RECEIPTS')}
                                </Typography>

                                {data.pmts.map((pmt, index) => {
                                    return (
                                        <>
                                            <ListItem
                                                key={index}
                                                className='grid grid-cols-2 gap-12'
                                                style={{ textAlign: 'start' }}>

                                                <Stack direction='row' spacing={1}>

                                                    <Typography>
                                                        {t(getKeyByValue(PAYMENTS_TYPE_ID, pmt?.typeId))}
                                                    </Typography>

                                                    <Typography>
                                                        {currency(pmt?.amount)}
                                                    </Typography>
                                                </Stack>

                                                {pmt.invoiceUrl
                                                    ? <Button
                                                        size="small"
                                                        className="cursor-pointer"
                                                        variant="outlined"
                                                        onClick={() => downloadFile(pmt.invoiceUrl)}
                                                        color="inherit">
                                                        <Typography className="text-center">
                                                            {t('DOWNLOAD_INVOICE')}
                                                        </Typography>
                                                    </Button>
                                                    : null
                                                }

                                            </ListItem>
                                            <Divider />
                                        </>
                                    )
                                })}
                            </Stack>

                            <Stack spacing={1}>

                                {data.typeId !== 3 &&
                                    <Button
                                        onClick={backToMenuHandler}
                                        variant='outlined'
                                        style={{ backgroundColor: MOLO_PRIMARY_COLOR, color: "#fff" }}
                                    >
                                        {t('BACK_TO_MAIN_MENU')}
                                    </Button>
                                }
                            </Stack>

                        </List>

                    </Box>

                    {/* Footer (Powered by Molo link) */}
                    <PoweredBy />

                </Box>
            </div>
        </div >
    )
};

export default OrderSummary;