import React, { useState, useEffect } from 'react';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import AssignmentIcon from '@mui/icons-material/Assignment';
import Review from './Review';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';
import moment from 'moment';
import { useSelector } from 'react-redux';

const ReviewDrawer = ({ iconSize }) => {
    let theme = useTheme();
    let { t } = useTranslation();
    const bizData = useSelector(state => state.bizData);
    const reviewsStamps = useSelector(state => state.userSettings.reviewsStamps);
    const [showReview, setShowReview] = useState(true);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        /* If biz has feedback feature enabled and user didn't rate it today */
        const bizReview = reviewsStamps?.find(review => review.biz === bizData?.id);
        setShowReview(bizData?.hasFeeds && (!bizReview || !moment(new Date()).isSame(bizReview?.date, 'day')));
    }, [reviewsStamps]);

    return (
        <>
            {showReview &&
                <div
                    sx={{
                        '&:hover,&:focus': { outline: 'none' }
                    }}
                    className='flex flex-col justify-center items-center cursor-pointer space-y-2'
                    onClick={() => setOpen(true)}
                >
                    <AssignmentIcon
                        fontSize={iconSize ?? 'medium'}
                    />
                    <p
                        className='text-xs'
                    >
                        {t('REVIEW')}</p>
                </div>
            }

            <SwipeableDrawer
                anchor='bottom'
                disableSwipeToOpen
                open={open}
                transitionDuration={300}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                PaperProps={{
                    sx: {
                        // background: 'primary.main',
                        height: '100%'
                    },
                }}
            >
                <Review onClose={() => setOpen(false)} />
            </SwipeableDrawer>
        </>
    )
}

export default ReviewDrawer;