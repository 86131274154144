import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CartItem from './CartItem';
import { setCart, setOrder } from 'store/userSettingsSlice';
import { useTheme } from '@emotion/react';
import AdPopUp from 'components/AdPopUp';
import { STEPS } from 'components/Orders';
import { removeCartItem } from 'store/cartSlice'
import CustomModal from 'components/utils/CustomModal';
import { ReactComponent as Recycle } from '../../assets/RECYCLE.svg'
import useCommon from "common/hooks/cartModifiers"
import useCart from "common/hooks/cart"
import CartOrderButton from './CartOrderButton';
import { setCoupon, setSale } from 'store/salesSlice';
import { ORDER_TYPE_ID, SALES_TYPE, UPSALE_TYPE_ID } from 'configs/consts';
import Upsale from 'components/Upsale';
import { setProducts } from 'store/upsalesSlice';
import useCurrency from 'hooks/useCurrency';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    Stack,
    Typography,
    useMediaQuery,
} from "@mui/material";
import { useMenu } from 'hooks/useMenu';

const CartItems = ({ CloseDrawer }) => {
    const theme = useTheme();
    const { calcCommission } = useCart();
    const { currency } = useCurrency();
    const dispatch = useDispatch();
    const { resetMenu } = useMenu();
    const { t } = useTranslation();
    const { deepEqual } = useCommon();
    const cartItemsWrapper = useRef();
    const xl = useMediaQuery(theme.breakpoints.up('lg'));
    const bizData = useSelector(state => state.bizData);
    //const { menu } = useSelector(state => state);
    const categories = useSelector(state => state.menu.activeCats);
    const { products } = useSelector(state => state.upsales);
    const { cartItems, cartTotalPrice } = useSelector(state => state.cart);
    const order = useSelector(state => state.userSettings.order);
    const coupon = useSelector(state => state.sales.coupon);
    const globalSale = useSelector(state => state.sales.sale);
    const menuWithOrder = useSelector(state => state.menu.canOrder);
    const [openDialog, setOpenDialog] = useState(null);
    const [openRemovalDialog, setOpenRemovalDialog] = useState(false);
    const [removeItems, setRemoveItems] = useState([]);
    const [addedCartItem, setAddedCartItem] = useState(null);
    const [prevItems, setPrevItems] = useState([]);
    const [showAdPopups, setShowAdPopups] = useState(false);
    const [totalPr, setTotal] = useState(0)
    const [salePr, setSalePr] = useState(null)
    const [lowPrice, setLowPrice] = useState(null)
    const [commissionPr, setCommissionPr] = useState(0)

    useEffect(() => {
        if (bizData.hasOrderMenu) {
            let items = [];
            cartItems.forEach(ci => {
                const cat = categories.find((category) => category.id === ci.catId);
                if (!cat)
                    items.push(ci);
                else {
                    const prod = cat?.items.find((product) => product.id === ci.id);
                    if (!prod)
                        items.push(ci);
                }
            });

            if (items.length > 0) {
                setRemoveItems(items)
                setOpenRemovalDialog(true);
            }
        }
    }, [categories])

    useEffect(() => {
        cartItems?.forEach((ci) => {
            const item = prevItems?.find((pi) => pi.guid === ci.guid);
            if (item && ci) {
                if (!deepEqual(item, ci)) {
                    setAddedCartItem(item);
                }
            }
            else {
                setAddedCartItem(ci);
            }
        })

        if (addedCartItem) {
            setPrevItems(cartItems)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cartItems]);


    useEffect(() => {
        const pr = checkTotalAffterSales()
        const commission = calcCommission(bizData?.orderSettings?.commissions, pr)
        setCommissionPr(commission)
        setTotal(pr + commission)
    }, [cartTotalPrice, order?.address, coupon, commissionPr]);

    const checkUpsaleBeforeFinished = () => {
        dispatch(setProducts({
            ...cartItems,
            typeId: UPSALE_TYPE_ID.COMPLETE_ORDER,
        }))
    }

    const finishOrder = () => {
        if (coupon?.minOrderPrice > totalPr) {
            setLowPrice(SALES_TYPE.coupon)
        }
        else if (globalSale?.minOrderPrice > totalPr) {
            setLowPrice(SALES_TYPE.globalSale)
        }
        else {

            /** 
             * Check if we have popups with typeId == 2 (Checkout popups),
             * if none skip popup onFinished event else show popups 
             **/
            bizData?.popUps?.filter(pop => pop.typeId === 2)?.length === 0
                ? onAdPopupsFinished()
                : setShowAdPopups(true)
        }
    }

    const checkTotalAffterSales = () => {
        const price = cartTotalPrice + (order?.address?.pr || 0)
        let totalAffterSales = price
        let salePr = 0

        if (globalSale && globalSale?.minOrderPrice <= price) {
            if (globalSale?.discountPrice) {
                totalAffterSales = totalAffterSales - globalSale?.discountPrice
                salePr = salePr + globalSale?.discountPrice
            }
            else if (globalSale?.discountPercent) {
                const Percent = totalAffterSales / 100 * globalSale?.discountPercent
                salePr = salePr + Percent
                totalAffterSales = totalAffterSales - Percent
            }
        }

        if (coupon && coupon?.minOrderPrice <= price) {
            if (coupon.discountPrice) {
                totalAffterSales = totalAffterSales - coupon?.discountPrice
                salePr = salePr + coupon?.discountPrice
            }
            else if (coupon.discountPercent) {
                const Percent = totalAffterSales / 100 * coupon?.discountPercent
                salePr = salePr + Percent
                totalAffterSales = totalAffterSales - Percent
            }
        }

        setSalePr(salePr)
        return totalAffterSales
    }

    const onAdPopupsFinished = () => {
        dispatch(setOrder({
            step: STEPS.CLIENT_DETAILS,
            total: totalPr,
            salePr: salePr
        }));
    }

    function ClearCart() {
        dispatch(setCart(null));
    }

    const RemoveFromFavorite = (itemToRemove) => {
        dispatch(removeCartItem(itemToRemove));
        dispatch(setCart([]));
    }

    const RemoveCopuon = () => {
        if (lowPrice) {
            if (lowPrice === SALES_TYPE.globalSale) {
                dispatch(setSale(null));
            }
            else if (lowPrice === SALES_TYPE.coupon) {
                dispatch(setCoupon(null));
            }

            setLowPrice(false)
            bizData?.popUps?.filter(pop => pop.typeId === 2)?.length === 0
                ? onAdPopupsFinished()
                : setShowAdPopups(true)
        }
    }

    const removeCartItems = () => {
        removeItems?.forEach((item) => {
            dispatch(removeCartItem(item));
        })
        setOpenRemovalDialog(false);
    }

    const cancelOrderTypeChange = () => {
        dispatch(setOrder({ typeId: order.prevTypeId }));
        setOpenRemovalDialog(false);
    }

    return (
        <>
            <Dialog
                title={t('WARNING')}
                open={openRemovalDialog}
                fullWidth
                maxWidth={'sm'}
            >
                <Stack paddingY={1}>
                    <Typography color='primary' variant='h6' textAlign={'center'}>{t('WARNING')}</Typography>
                    <DialogContent>
                        <Stack spacing={2}>
                            <Typography color='primary' variant='body'>{t('CART_REMOVE_UNAVAILABLE_ITEMS')}</Typography>
                            <Stack>
                                {removeItems?.map((prod, idx) => {
                                    return <Typography color='primary'>{`${idx + 1}. ${prod.name}`}</Typography>
                                })}
                            </Stack>
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} spacing={2} width={'100%'}>
                            <Button variant='contained' onClick={removeCartItems}>
                                <Typography>{t('REMOVE_FROM_CART')}</Typography>
                            </Button>
                            <Button variant='contained' onClick={() => resetMenu()}>
                                <Typography>{t('NEW_ORDER')}</Typography>
                            </Button>
                            {/* <Button variant='contained' onClick={() => cancelOrderTypeChange()}>
                                <Typography>{t('CANCEL_CHANGE')}</Typography>
                            </Button> */}
                        </Stack>
                    </DialogActions>
                </Stack>
            </Dialog>
            {/* <Dialog open={openRemovalDialog}>
                <DialogContent>
                    <Stack spacing={2}>
                        <Typography>{t('CART_REMOVE_UNAVAILABLE_ITEMS')}</Typography>
                        <Stack>
                            {removeItems?.map((prod, idx) => {
                                return <Typography>{`${idx + 1}. ${prod.name}`}</Typography>
                            })}
                        </Stack>

                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button variant='contained'>{t('REMOVE')}</Button>
                    <Button variant='contained'>{t('NEW_ORDER')}</Button>
                </DialogActions>
            </Dialog> */}
            {/* Ads popup */}
            {showAdPopups ? <AdPopUp typeId={2} callback={onAdPopupsFinished} /> : null}

            {/* Upsale  */}
            {menuWithOrder && products ? <Upsale finishOrder={finishOrder} /> : null}

            <Stack
                width='100%'
                // justifyContent='center'
                alignItems={xl ? 'start' : 'center'}
                className='h-full w-full px-2 pb-2 md:pb-0 md:px-0 overflow-hidden gap-1'
                sx={[
                    { backgroundColor: 'secondary.main' },
                    xl ? { maxHeight: '50vh' } : {}
                ]}
            >

                {cartItems?.length > 0 ?
                    <>
                        {/* Cart Items */}
                        <Box
                            sx={(theme) => theme.scrollBar}
                            ref={cartItemsWrapper}
                            className='w-full overflow-y-auto flex flex-col my-2'
                        >
                            {cartItems?.map(((item, index) => {
                                return (
                                    <CartItem
                                        key={index}
                                        animate={addedCartItem?.guid === item.guid}
                                        // removeFromFav={(e) => { e.stopPropagation(); setOpenDialog(item) }}
                                        removeFromFav={() => { setOpenDialog(item) }}
                                        product={item}
                                        CloseDrawer={CloseDrawer}
                                    />
                                )
                            }
                            ))}
                        </Box>
                    </>
                    : <div className='w-full h-full flex justify-center items-center p-4'>
                        {/* Cart is Empty */}
                        <Typography
                            className='font-semibold text-xl'
                            color="primary"
                        >
                            {bizData?.hasOrderMenu && menuWithOrder && order.typeId > 0
                                ? t('NO_ITEMS_IN_CART')
                                : t('NO_FAVORITES_YET')}
                        </Typography>
                    </div>
                }

                <Divider sx={{
                    width: '100%',
                    marginY: 1,
                    bgcolor: (theme) => theme.palette.primary.main
                }} />

                {/* Cart summary & finish button */}
                <CartOrderButton
                    commissionPr={commissionPr}
                    salePr={salePr}
                    totlePr={totalPr}
                    onClick={checkUpsaleBeforeFinished} />
            </Stack>

            {/* Delete cart items dialog */}
            <CustomModal
                open={openDialog !== null}
                onClose={() => setOpenDialog(null)}
                focusOnCloseButton
                closeBtn={"NO"}
                svgIcon={{ component: Recycle }}
                actionBtn={{
                    title: "YES",
                    action: (e) => {
                        // e.stopPropagation();
                        openDialog === 'all'
                            ? ClearCart()
                            : RemoveFromFavorite(openDialog);
                        setOpenDialog(null)
                    }
                }}

            >
                <Box className="flex flex-col justify-center items-center" sx={{ minWidth: 220 }} >
                    <Typography variant="h6" color="primary">
                        {t('DEL_ACCEPTION')}
                    </Typography>
                    <Typography variant="subtitle1" color="primary">
                        {openDialog === 'all'
                            ? t('CLEAR_FAVORITE_LIST_DESC')
                            : t('REMOVE_FROM_FAVORITE_DESC')
                        }
                    </Typography>
                </Box>

            </CustomModal>

            {/*  Low Price */}
            <CustomModal
                open={lowPrice ? true : false}
                onClose={() => setLowPrice(null)}
                focusOnCloseButton
                closeBtn={"ADD_PRODUCTS"}
                actionBtn={{
                    title: lowPrice === SALES_TYPE.coupon
                        ? "REMOVE_COUPON"
                        : "REMOVE_GLOBAL_SALE"
                    ,
                    action: (e) => {
                        RemoveCopuon()
                        setLowPrice(false)
                    }
                }}

            >
                <Box className="flex flex-col justify-center items-center" sx={{ minWidth: 320 }} >
                    <Typography variant="h6" color="primary">
                        {lowPrice === SALES_TYPE.coupon
                            ? t('LOW_PRICE_TITLE_COUPON')
                            : t('LOW_PRICE_TITLE_GLOBAL_SALE')
                        }
                    </Typography>
                    <Typography variant="subtitle1" color="primary">
                        {lowPrice === SALES_TYPE.coupon
                            ? `${t('LOW_PRICE_INFO_COUPON')} ${currency(coupon?.minOrderPrice)}`
                            : `${t('LOW_PRICE_INFO_GLOBAL_SALE')} ${currency(globalSale?.minOrderPrice)}`
                        }
                    </Typography>
                </Box>

            </CustomModal>
        </>
    )
}

export default CartItems;