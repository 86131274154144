import React, { useEffect, useRef } from 'react';

const HieghtGetter = (props) => {
    let ref = useRef(null);
    const resize = () => {
        var height = ref.current?.clientHeight;
        props.onHeightReady(height + 5);
    }

    useEffect(() => {
        resize();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.children]);

    return (
        <div className='h-full' ref={ref}>
            {props.children}
        </div>
    )
}

export default HieghtGetter;