import React from 'react';
import err404Img from 'assets/errors/404.jpg';

const ErrorBoundary = ({ error, children }) => {
    return (
        error != null ?
            !process.env.NODE_ENV || process.env.NODE_ENV === "development"
                ? <div>
                    <p>{error}</p>
                </div>
                : <div className="flex h-screen">
                    <img src={err404Img} alt='' className='w-1/2 h-w lg:w-1/3 m-auto' />
                </div>
            : children
    )
}

export default ErrorBoundary;