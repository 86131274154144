import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ErrorPage from '../../ErrorBoundary';
import YpUtils from 'YpUtils';
import { Button } from '@mui/material';
import { useTheme } from '@emotion/react';

function RateInMedia({ onClose }) {
    const theme = useTheme();
    const { t } = useTranslation();
    const reviews = useSelector(state => state?.bizData?.reviews);

    return reviews
        ? <>
            <p className="font-bold text-2xl lg:text-2xl text-center mt-4 md:mt-16">
                {t('THANK_YOU_FOR_RAITING')}
            </p>

            <p className="text-xl lg:text-2xl text-center mt-2 mb-4 md:m-12">
                {t("RATE_IN_MEDIA")}
            </p>

            <div className='flex items-center justify-center flex-wrap gap-y-4'>
                {reviews?.map((mediaIcon, index) =>
                    <a
                        key={index}
                        href={mediaIcon.url?.startsWith('http') ? mediaIcon.url : `https://${mediaIcon.url}`}
                        target='_blank'
                        className='flex items-center justify-center h-32 mx-2 border border-black'
                    >
                        <img
                            className='h-full p-4'
                            src={YpUtils.getImage(mediaIcon.imgUrl)}
                        />
                    </a>
                )}
            </div>

            <div className='pb-24 flex justify-center'>
                <Button
                    className='mt-20 w-40'
                    onClick={() => onClose()}>
                    <p className='m-auto'>
                        {t('FINISH')}
                    </p>
                </Button>
            </div>
        </>
        : <ErrorPage err={404} />
}

export default RateInMedia;