import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const OutOfStock = ({ sx, component }) => {
    const { t } = useTranslation();

    return (
        <Typography
            component={`${component || "span"}`}
            sx={{
                fontSize: '0.75rem !important',
                position: 'absolute',
                padding: '0 8px',
                backgroundColor: 'clButtons.main',
                color: 'secondary.main',
                ...sx
            }}
        >
            {t('OUT_OF_STOCK')}
        </Typography>)

}
export default OutOfStock;