import axios from 'axios';
import settingsConfig from './configs/settingsConfig';

const instance = axios.create({
  baseURL: `${settingsConfig.apiUrl}api/v1/wa/`,
  // onUploadProgress: progressEvent => store.dispatch(setShowLoading(true)),
});

// instance.interceptors.response.use(
//   response => {
//     return response;
//   },    
//   err => {
//     if (err?.response?.status === 404) {
//       // window.location.replace('/error');
//     }
//     return err;
//   }
// );

// const token = localStorage.getItem('jwt_access_token');
// if (token)
//     instance.defaults.headers.common.Authorization = `Bearer ${token}`;

export default instance;