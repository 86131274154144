import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Typography, Box } from "@mui/material";
import { useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';
import Call from '@mui/icons-material/Call';
import CustomModal from 'components/utils/CustomModal';

const CallForOrder = () => {
    const { t } = useTranslation();
    const bizData = useSelector(state => state.bizData);
    let theme = useTheme();
    const [open, setOpen] = useState(false);

    const handleCall = () => {
        var tel = document.createElement("a");
        tel.href = `tel:${bizData?.telOrder}`;
        tel.click();
        handleClose();
    };

    const handleClose = () => {
        setOpen(!open);
    };
    return (
        <>
            <div
                onClick={() => setOpen(true)}
                className='flex items-center cursor-pointer'
            >
                <Call color='primary' style={{ fontSize: 21 }} />
                <Typography color="primary" variant='subtitle2' className='mr-2'>{bizData.telOrder}</Typography>
            </div>

            <CustomModal
                open={open}
                onClose={handleClose}
                actionBtn={{
                    title: "YES",
                    action: handleCall
                }}
                svgIcon={{ component: Call }}
                closeBtn={"NO"}
            >
                <Box className="flex flex-col justify-center items-center gap-6" sx={{ minWidth: 220 }} >
                    <Typography variant="h5" color="primary">
                        {t("ARE_YOU_SURE_YOU_WANT_TO_CALL")}
                    </Typography>
                    <Typography variant="h6" color="primary">
                        {bizData.telOrder}
                    </Typography>
                </Box>

            </CustomModal>
        </>
    )
}

export default CallForOrder;