import { Timing_TYPE } from "configs/consts";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setOrder } from "store/userSettingsSlice";
import FutureOrderTimePicker from "components/Orders/preOrder/FutureOrder";
import moment from "moment";
import { dateTimeFormat } from "configs/settingsConfig";
import {
    Collapse,
    Divider,
    Icon, List,
    ListItemButton,
    Stack,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material"

const TimingOrder = () => {
    const theme = useTheme()
    const dispatch = useDispatch()
    const { t } = useTranslation();
    const xl = useMediaQuery(theme.breakpoints.up('lg'));
    const bizData = useSelector(state => state.bizData);
    const order = useSelector(state => state.userSettings.order);
    const orderSettings = useSelector((state) => state.bizData.orderSettings);
    const [selected, setSelected] = useState(order?.future ? Timing_TYPE.future : Timing_TYPE.new)
    const [openFutureOrder, setOpenFutureOrder] = useState(false)

    const onClickFutureOrder = () => {
        setSelected(Timing_TYPE.future)
        setOpenFutureOrder(d => !d)
    }

    const onClickNewOrder = () => {
        setSelected(Timing_TYPE.new)
        setOpenFutureOrder(false)
        dispatch(setOrder({ future: null }))
    }

    useEffect(() => {
        if (!openFutureOrder && selected === Timing_TYPE.future)
            setSelected(order?.future ? Timing_TYPE.future : Timing_TYPE.new)
    }, [openFutureOrder, order?.future]);

    return (
        bizData?.hasOrderMenu &&
            orderSettings?.futureOrderInDays &&
            !bizData?.isTable
            ? <>
                <Stack width='100%'>
                    {!xl && <Divider sx={{ marginY: 1, bgcolor: (theme) => theme.palette.primary.main }} />}
                    <List sx={{ padding: 0 }}>
                        <ListItemButton
                            onClick={onClickNewOrder}
                            disableGutters >

                            <Stack
                                direction='row'
                                gap={1}
                                color={selected === Timing_TYPE.new ? 'clButtons.main' : 'primary.main'}>

                                <Icon>access_time</Icon>
                                <Typography>
                                    {t('ORDER_FOR_NEW')}
                                </Typography>
                            </Stack>
                        </ListItemButton>

                        <Divider sx={{ marginY: 1, bgcolor: (theme) => theme.palette.primary.main }} />

                        <Stack gap={1}>
                            <ListItemButton onClick={onClickFutureOrder} disableGutters>
                                <Stack
                                    direction='row'
                                    gap={1}
                                    color={selected === Timing_TYPE.future ? 'clButtons.main' : 'primary.main'}>
                                    <Icon>calendar_month</Icon>
                                    <Typography>
                                        {`${t('FUTURE_ORDER')} ${order?.future ? `(${moment(order?.future).format(dateTimeFormat)})` : ''}`}

                                    </Typography>
                                </Stack>
                            </ListItemButton>

                            <Collapse in={openFutureOrder} timeout="auto" unmountOnExit>
                                {openFutureOrder &&
                                    <FutureOrderTimePicker
                                        setOpenFutureOrder={setOpenFutureOrder}
                                        isOrderTypeChange={true} />
                                }
                            </Collapse>
                        </Stack>
                    </List>

                    {/* {!xl && <Divider sx={{ marginY: 1, bgcolor: (theme) => theme.palette.primary.main }} />} */}
                </Stack>


            </>
            : null
    )
}
export default TimingOrder