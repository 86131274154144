import { BrowserMultiFormatReader } from "@zxing/library";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

const DEFAULT_CONSTRAINTS = {
    audio: false,
    video: { facingMode: "environment" },
};

const useBrowserMultiFormatReader = ({ timeBetweenDecodingAttempts = 300, hints }) => {
    return useMemo(() => {
        const instance = new BrowserMultiFormatReader(hints);
        instance.timeBetweenDecodingAttempts = timeBetweenDecodingAttempts;
        return instance;
    }, [hints, timeBetweenDecodingAttempts]);
};

const deepCompareObjects = (a, b) => JSON.stringify(a) === JSON.stringify(b);

const isUseZxingOptionsWithDeviceId = (options) => options.deviceId !== undefined;

export const useQrReader = (options = {}) => {
    const { hints, timeBetweenDecodingAttempts, onResult = () => { }, onError = () => { } } = options;

    const ref = useRef(null);
    const errorHandlerRef = useRef(onError);
    const resultHandlerRef = useRef(onResult);
    const [permissionStatus, setPermissionStatus] = useState(null);
    const [constraints, setConstraints] = useState(
        isUseZxingOptionsWithDeviceId(options) ? undefined : options.constraints
    );

    const reader = useBrowserMultiFormatReader({
        hints,
        timeBetweenDecodingAttempts,
    });

    const deviceId = isUseZxingOptionsWithDeviceId(options)
        ? options.deviceId
        : undefined;

    const decodeCallback = useCallback((result, error) => {
        if (result)
            resultHandlerRef.current(result);

        if (error)
            errorHandlerRef.current(error);
    }, []);

    const startQrReader = useCallback(() => {
        if (!ref.current)
            return;

        if (deviceId) {
            reader
                .decodeFromVideoDevice(deviceId, ref.current, decodeCallback)
                .catch((e) => setPermissionStatus(e));
        }
        else {
            reader
                .decodeFromConstraints(
                    constraints ?? DEFAULT_CONSTRAINTS,
                    ref.current,
                    decodeCallback
                )
                .catch((e) => setPermissionStatus(e));
        }

    }, [reader, deviceId, constraints, decodeCallback]);

    const stopQrReader = useCallback(() => {
        reader.reset();
    }, [reader]);

    useEffect(() => {
        resultHandlerRef.current = onResult;
    }, [onResult]);

    useEffect(() => {
        errorHandlerRef.current = onError;
    }, [onError]);

    useEffect(() => {
        const isConstraintsValueSame = deepCompareObjects(constraints, options.constraints);

        if (!isConstraintsValueSame)
            setConstraints(options.constraints);

    }, [constraints, options, setConstraints]);

    useEffect(() => {
        startQrReader();

        return () => {
            stopQrReader();
        };
    }, [startQrReader, stopQrReader]);

    return { ref, startQrReader, stopQrReader, permissionStatus };
};