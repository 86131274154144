import Loading from "components/utils/Loading";
import ImgPlaceholder from 'assets/img-placeholder.svg';
import { useState } from "react";
import { Box } from "@mui/material";

const MenuItemImg = ({ hasImgs, imgLogo, imgUrl, size }) => {
    const [isLoading, setIsLoading] = useState(true)

    return (
        hasImgs &&
        <Box className={`flex self-start justify-center items-start`}>
            <Box
                width={size ?? '7rem'}
                height={size ?? '7rem'}
                className={`flex items-end object-cover relative`}>
                {isLoading &&
                    <div
                        className="absolute inset w-full h-full flex justify-center items-center">
                        <Loading />
                    </div>
                }
                <img
                    src={imgUrl ?? imgLogo ?? ImgPlaceholder}
                    className='h-full w-full object-cover'
                    loading="lazy"
                    onLoad={() => setIsLoading(false)}
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = imgLogo ?? ImgPlaceholder;
                    }}
                />
            </Box>
        </Box>
    )
}
export default MenuItemImg