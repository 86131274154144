
export const ORDER_TYPE_ID = {
    PICKUP: 1,
    DELIVERY: 2,
    SITTING: 3
}

export const SOCIAL_ID = {
    facebook: 1,
    instagram: 9,
    tripAdvisor: 10,
    zapRest: 11,
    googleReviews: 12
}

export const KOSHER_LIST = [
    { id: 1, name: 'KOSHER' },
    { id: 2, name: 'KOSHER_BADATZ' },
    { id: 3, name: 'KOSHER_GLAT' },
]

export const Timing_TYPE = {
    new: 1,
    future: 2
}

export const SALES_TYPE = {
    globalSale: 1,
    coupon: 2
}

export const UPSALE_TYPE_ID = {
    ADD_TO_CART: 1,
    COMPLETE_ORDER: 2
}

/** * API Order workflow status id */
export const ORDER_WORKFLOW_STATUS_ID = {
    COOKING: 1,
    DELIVERED: 2,
    ONTHEWAY: 3,
    CREATED: 4,
    PENDING: 5,
    READY: 6,
}

/** API Payment status id */
export const PAYMENT_STATUS_ID = {
    NOT_FINISHED_PURCHASE: 1,
    PAID: 2,
    FAILED_AT_CC: 3,
    REFUNDED: 4,
};

/** API order status id */
export const ORDER_STATUS_ID = {
    OPENED: 1,
    LOCKED: 2,
    CLOSED: 3,
    CANCELED: 4,
    UNFINISHED: 5,
};

export const ACTIVE_ORDER_STATUS = [
    ORDER_WORKFLOW_STATUS_ID.CREATED,
    ORDER_WORKFLOW_STATUS_ID.COOKING,
    ORDER_WORKFLOW_STATUS_ID.PENDING,
    ORDER_WORKFLOW_STATUS_ID.ONTHEWAY,
    ORDER_WORKFLOW_STATUS_ID.DELIVERED,

]

/** Payment type id */
export const PAYMENTS_TYPE_ID = {
    CASH: 1,
    CREDIT_CARD: 2,
    FREE: 3,
    MOLO: 4,
    EMV: 5,
    POS: 6
}
export const BUSINESS_TYPE_ID = {
    RESTAURANT: 1,
    PRODUCTS_SHOP: 2
}

export const getKeyByValue = (object, value) => {
    return Object.keys(object).find(key => object[key] === value);
}

