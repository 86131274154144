import { createSlice } from '@reduxjs/toolkit'

export const sliceName = 'appSettings';

export const appSettingsSlice = createSlice({
    name: sliceName,
    initialState: {
        isIframe: false,
        headerHeight: 0,
    },
    reducers: {
        setIsIframe: (state, action) => { state.isIframe = action.payload },
        setHeaderHeight: (state, action) => { state.headerHeight = action.payload },
    },
})

export const { setIsIframe, setHeaderHeight } = appSettingsSlice.actions

export default appSettingsSlice.reducer