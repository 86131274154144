import { useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import { Button, Select, InputLabel, MenuItem, FormControl, Typography, Slide, Fade } from "@mui/material";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useDispatch } from 'react-redux';
import { useTheme } from '@emotion/react';
import { DaysOfWeek } from 'services';
import StepHeader from '../StepHeader';
import { STEPS } from "..";
import { setOrder } from 'store/userSettingsSlice';

const FutureOrderTimePicker = ({ isOrderTypeChange, setOpenFutureOrder }) => {
  const dispatch = useDispatch();
  const orderSettings = useSelector((state) => state.bizData.orderSettings);
  const isOpen = useSelector(state => state?.bizData?.isOpen);
  const [selectedTime, setSelectedTime] = useState("");
  const [selectedDay, setSelectedDay] = useState("");
  const [currTimes, setCurrTimes] = useState([]);
  const [bizOpen, setBizOpen] = useState(true);
  const order = useSelector(state => state.userSettings.order);
  const timing = orderSettings?.timing ?? [];
  const { t } = useTranslation();
  const theme = useTheme();
  const [closeDays, setCloseDays] = useState([])
  const [weekDays, setWeekDays] = useState(["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"])

  useEffect(() => {
    if (orderSettings) {
      let days = []
      orderSettings.closedDt.forEach((item) =>
        days.push(moment(item.date).format('dddd'))
      )
      setCloseDays(days)
    }
  }, [orderSettings?.closedDt])

  useEffect(() => {
    selectedDay && timeList(selectedDay);
    selectedDay && isBizOpen();
  }, [selectedDay]);

  useEffect(() => {
    if (order?.futureSettings?.day) {
      setSelectedDay(order?.futureSettings?.day)
    }

    if (order?.futureSettings?.time) {
      setSelectedTime(order?.futureSettings?.time)
    }

  }, [order?.futureSettings]);

  const setDate = () => {
    if (selectedDay && selectedTime) {
      let today = moment(new Date()).day() + 1;
      let addDaysNum = selectedDay >= today ? selectedDay - today : (selectedDay - today + 7);

      let date = moment(new Date()).add(addDaysNum, 'days');
      let time = moment(selectedTime, 'HH:mm');
      date.set({
        hour: time.get('hour'),
        minute: time.get('minute'),
      });

      let newDate = moment(date).format().split('+')[0]

      dispatch(setOrder({
        step: STEPS.MENU_LIST,
        future: newDate,
        futureSettings: {
          oldFuture: newDate,
          day: selectedDay,
          time: selectedTime
        }
      }))

      if (setOpenFutureOrder) {
        setOpenFutureOrder(false)
      }
    }
  }

  const checkIfOpenInCurrTime = (currDayTiming, time) => {
    let now = moment(time).format('HH:mm');
    let isOpen;

    for (let i = 0; i < currDayTiming.length; i++) {
      if (now >= moment(currDayTiming[i]?.ftm).format('HH:mm') && now <= moment(currDayTiming[i]?.ttm).format('HH:mm')) {
        isOpen = true;
        break;
      }

      else if (now < moment(timing[i]?.ftm).format('HH:mm')) {
        isOpen = false
        break;
      }

      else isOpen = false
    }
    return isOpen;
  }

  const timeList = (day) => {
    const currDay = day ?? moment(new Date()).day() + 1;
    const today = moment(new Date()).day() + 1;
    let helperArray = [];
    const currDayTiming = timing?.filter(tm => currDay === tm.dow);
    const isTiming = timing?.length > 0;

    let lastTimeofTheDay = isTiming
      ? null
      : moment(new Date().setHours(23, 59, 0, 0));

    let time = isTiming && today !== currDay
      ? null
      : (today === currDay
        ? moment(new Date()).add(20, 'minutes') // Add 20 min margin from now
        : moment(new Date().setHours(0, 0, 0, 0)));

    if (currDayTiming?.length > 0) {
      for (let i = 0; i < currDayTiming.length; i++) {
        if (moment(currDayTiming[i].ttm).format('HH:mm') > moment(lastTimeofTheDay).format('HH:mm') || !lastTimeofTheDay)
          lastTimeofTheDay = moment(currDayTiming[i].ttm);

        if (currDay !== today
          && (moment(currDayTiming[i].ftm).format('HH:mm') < moment(time).format('HH:mm') || !time))
          time = moment(currDayTiming[i].ftm);
      }
    }
    else if (currDayTiming?.length === 0 && isTiming)
      return setCurrTimes([]);


    const add15Mins = () => {
      if (isTiming) {
        if (checkIfOpenInCurrTime(currDayTiming, time))
          helperArray.push(time.format('HH:mm'));
      }
      else
        helperArray.push(time.format('HH:mm'));

      time = time.add(15, 'minutes');
      if (time < lastTimeofTheDay)
        add15Mins();
    }

    if (time < lastTimeofTheDay)
      add15Mins();

    setCurrTimes(helperArray);
  };

  const isClosedDate = (date) => {
    const closedDt = orderSettings?.closedDt ?? [];
    if (!closedDt || closedDt?.length === 0)
      return false;

    const searchDt = closedDt?.filter(dt => moment(dt.date).format('DD/MM/YY') === moment(date).format('DD/MM/YY'));

    return searchDt?.length > 0 ? searchDt[0].msg : null;
  }

  const isBizOpen = () => {
    const today = moment(new Date()).day() + 1;
    let addDaysNum = selectedDay >= today ? selectedDay - today : (selectedDay - today + 7);
    const newDate = moment(new Date(), "DD/MM/YY").add(addDaysNum, 'days');
    const currDayTiming = timing?.filter(tm => selectedDay === tm.dow);
    let closedToday = isClosedDate(newDate);

    if (timing.length === 0 && !closedToday)
      return setBizOpen(true);

    setBizOpen(currDayTiming?.length > 0 && !closedToday);
  }

  return (
    <>
      {!isOrderTypeChange &&
        <StepHeader
          backBtnClick={() => dispatch(setOrder({ step: !isOpen ? STEPS.ORDER_TYPE : STEPS.ORDER_TIME }))}
          title={t('FUTURE_ORDER')}
        />
      }

      <Fade timeout={700} in={true}>
        <div className="flex flex-col space-y-4">
          <FormControl>
            <InputLabel sx={{ color: 'primary.main' }}>{t("CHOOSE_DAY")}</InputLabel>
            <Select
              label={t("CHOOSE_DAY")}
              value={selectedDay}
              sx={(theme) => theme.select}
              onChange={(e) => setSelectedDay(e.target.value)}
            >
              {Array(orderSettings?.futureOrderInDays ?? 7).fill().map((day, idx) => idx).map((index) => {
                let day = weekDays[moment(new Date()).add(index, 'days').day()]
                if (closeDays.includes(day))
                  return

                return index === 0
                  ? <MenuItem value={moment(new Date()).add(index, 'days').day() + 1}>
                    {t("TODAY")}
                  </MenuItem>
                  : index === 1
                    ? <MenuItem value={moment(new Date()).add(index, 'days').day() + 1}>
                      {t("TOMORROW") + " - " + moment(new Date()).add(index, 'days').format('DD/MM/YYYY')}
                    </MenuItem>
                    : index === 2
                      ? <MenuItem value={moment(new Date()).add(index, 'days').day() + 1}>
                        {t("DAY_AFTER_TOMORROW") + " - " + moment(new Date()).add(index, 'days').format('DD/MM/YYYY')}
                      </MenuItem>
                      :
                      <MenuItem value={moment(new Date()).add(index, 'days').day() + 1}>
                        {t("DAY")} {t(DaysOfWeek[moment(new Date()).add(index, 'days').day()]) + " - " + moment(new Date()).add(index, 'days').format('DD/MM/YYYY')}
                      </MenuItem>
              })}
            </Select>
          </FormControl>

          <FormControl>
            <InputLabel
              sx={{
                color: 'primary.main',
                opacity: (!bizOpen || !selectedDay) ? theme.disabledOpacity : 1
              }}
            >
              {bizOpen ? t("CHOOSE_TIME") : t("BIZ_IS_CLOSED")}
            </InputLabel>
            <Select
              sx={(theme) => theme.select}
              label={bizOpen ? t("CHOOSE_TIME") : t("BIZ_IS_CLOSED")}
              disabled={!bizOpen || !selectedDay}
              value={selectedTime}
              defaultValue={order?.futureSettings?.time}
              onChange={(e) => setSelectedTime(e.target.value)}
            >
              {currTimes?.map((time, index) => {
                return <MenuItem
                  value={time}
                  key={index}
                  sx={(theme) => theme.scrollbar}>
                  {time}
                </MenuItem>;
              }
              )}
            </Select>
          </FormControl>

          <Button
            disabled={!selectedTime || !selectedDay}
            size={isOrderTypeChange ? 'small' : "large"}
            onClick={setDate}>
            <Typography
              variant="h6">
              {isOrderTypeChange ? t('ACCEPT') : t('NEXT')}
            </Typography>
          </Button>
        </div>
      </Fade>
    </>
  );
};
export default FutureOrderTimePicker;