import React, { useState, useEffect } from 'react';
import { Typography, Radio, RadioGroup, Button, FormControl, FormControlLabel, Stack, SvgIcon, Box, Fade, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';
import { useSelector } from 'react-redux';
import Tips from './Tips';
// import Lottie from 'react-lottie-player';
// import ccAnimation from './cc.json';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import axios from 'axiosConn';
import Loading from 'components/utils/Loading';
import YpUtils from 'YpUtils';
import InfoModal from 'components/utils/CustomModal';
import StepHeader from '../StepHeader';
import { useDispatch } from 'react-redux';
import { setCart, setOrder } from 'store/userSettingsSlice';
import { STEPS } from '..';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import CcAmericanExpress from 'assets/ccProviders/AmericanExpress-dark.png';
import CcDiners from 'assets/ccProviders/DinersClub-dark.png';
import CcMasterCard from 'assets/ccProviders/MasterCard-dark.png';
import CcVisa from 'assets/ccProviders/Visa-card-dark.png';
import CcGoogle from 'assets/ccProviders/GoogleWallet-card-light.png';
import CcApple from 'assets/ccProviders/Apple-card-light.png';
import FoodBankIcon from '@mui/icons-material/FoodBank';
import useCommon from 'common/hooks/cartModifiers';
import CreditCardIcon from './CreditCardIcon';
import PaymentButton from './PaymentButton';
import useCurrency from 'hooks/useCurrency';

// const ccStyle = {
//     width: 56,
//     border: 'solid 1px #000',
//     borderRadius: '5px',
//     margin: '6px'
// }

const Payment = ({ setCcIframeUrl }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const theme = useTheme();
    const { prepareOrderItems } = useCommon();
    const { currency } = useCurrency();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const bizData = useSelector(state => state.bizData);
    const order = useSelector(state => state.userSettings.order);
    const coupon = useSelector(state => state.sales.coupon);
    const { cartTotalPrice } = useSelector(state => state.cart);
    const selectedLang = useSelector(state => state.userSettings.selectedLang);
    const cart = useSelector(state => state.cart);
    const [isLoading, setIsLoading] = useState(false);
    const [errDialogMsg, setErrDialogMsg] = useState(null);
    const [selectedPayment, setSelectedPayment] = useState(2);
    const [showTip, setShowTip] = useState(false);
    const [tip, setTip] = useState(0);
    const hasCashAndCard = (bizData?.orderSettings?.hasOrdCash && bizData?.orderSettings?.hasOrdCc)

    useEffect(() => {
        if (!bizData?.orderSettings?.hasOrdCc && bizData?.orderSettings?.hasOrdCash)
            setSelectedPayment(1)
        else if (bizData?.orderSettings?.hasOrdCc && !bizData?.orderSettings?.hasOrdCash)
            setSelectedPayment(2)
    }, [])

    // function isMobFun() {
    //     return ((window.innerWidth <= 800) && (window.innerHeight <= 600));
    // }

    const sendOrder = () => {
        setIsLoading(true);
        let values = {
            bizId: bizData.id,
            tableGuid: bizData?.isTable ? bizData.guid : null,
            clientId: order.client.id,
            typeId: order.typeId,
            discount: order?.salePr?.toFixed(2),
            saleCode: coupon?.code,
            dinners: order.dinners,
            langId: selectedLang?.id,
            fromTypeId: isMobile ? 4 : 3,
            futureOrd: order.future,
            items: prepareOrderItems(cart.cartItems),
            notes: order.notes,
            tip: tip,
            deliveryPr: order?.address?.pr,
            // ttlPr: cartTotalPrice,
            ttlPr: order?.total + (tip || 0),
            orderGuid: order?.guid
        };
        console.log('values', values);
        let data = YpUtils.objectToFormData(values);

        return axios.post(`order`, data).then(res => {

            if (res?.data?.id === 1) {
                const payData = {
                    bizId: bizData.id,
                    orderGuid: res?.data.data.guid,
                    typeId: selectedPayment,
                    amount: values.ttlPr,
                }

                axios.post(`pay`, (YpUtils.objectToFormData(payData, {}, false)))
                    .then((res) => {
                        if (selectedPayment === 2) {
                            dispatch(setOrder({
                                step: STEPS.CC_PAYMENT,
                                guid: payData.orderGuid,
                                ccIframeUrl: res?.data?.data,
                            }))
                        }
                        else {
                            dispatch(setOrder());
                            dispatch(setCart(null));
                            navigate(`/order-summary/${payData.orderGuid}`);
                        }
                    })
            }

            else if (res?.data?.id === -11) {
                setErrDialogMsg(t('BUSINESS_CLOSED'))
            }
            else if (res?.data?.id === -24) {
                setErrDialogMsg(t('ERR'))
            }
            else if (res?.data?.id === -30) {
                setErrDialogMsg(t('ERR'))
            }
            else
                return setErrDialogMsg(t('ERR'))
            setIsLoading(false);

        })
            .catch(err => {
                setIsLoading(false);

                switch (err.response?.data?.id) {
                    case -11: return setErrDialogMsg(t('BUSINESS_CLOSED'))
                    case -15: return setErrDialogMsg(t('BUSINESS_CLOSED_FUTURE_ORDER'))
                    default: return setErrDialogMsg(t('ERR'))
                }

            })
    }

    return (
        <>
            <StepHeader
                backBtnClick={() => dispatch(setOrder({ step: STEPS.CLIENT_DETAILS }))}
                title={t('CHOOSE_PAYMENT_METHOD')}
            />
            {errDialogMsg &&
                <InfoModal
                    onClose={() => setErrDialogMsg(false)}
                    open={errDialogMsg !== null}
                    title={"EROOR"}
                    closeIcon
                >
                    <Typography color='primary' variant='h5'>
                        {t(errDialogMsg)}
                    </Typography>
                </InfoModal>
            }
            <Fade timeout={500} in={true}>
                <div className='flex flex-col p-4'>
                    {bizData?.orderSettings?.hasDeliveryTips &&
                        (order?.typeId === 2 || order?.typeId === 3) &&
                        <>
                            <div className='flex items-center'>
                                {bizData.isTable
                                    ? <FoodBankIcon fontSize='large' sx={{ color: 'primary.main' }} />
                                    : <DeliveryDiningIcon fontSize='large' sx={{ color: 'primary.main' }} />
                                }

                                <Typography color='primary' className='xl:text-lg text-md font-bold mx-1'>
                                    {bizData.isTable ? t('TIP_QUESTION_WAITER') : t('TIP_QUESTION_DELIVERY')}
                                </Typography>
                            </div>

                            <FormControl>
                                <RadioGroup
                                    defaultValue={0}
                                    sx={{ my: 1 }}
                                    row
                                >
                                    <FormControlLabel
                                        value={0}
                                        control={
                                            <Radio
                                                sx={{ m: 0, p: 0, mx: 1 }}
                                                onChange={() => {
                                                    setShowTip(false)
                                                    setTip(0)
                                                }}
                                            />
                                        }
                                        label={
                                            <Typography color='primary'>
                                                {t("NO")}
                                            </Typography>
                                        }

                                    />
                                    <FormControlLabel
                                        value={1}
                                        control={
                                            <Radio
                                                sx={{ m: 0, p: 0, mx: 1 }}
                                                onChange={() => setShowTip(true)}
                                            />
                                        }
                                        label={
                                            <Typography color='primary'>
                                                {t("YES")}
                                            </Typography>
                                        }
                                        sx={{ mx: 2 }}
                                    />
                                </RadioGroup>
                            </FormControl>
                        </>
                    }

                    {showTip &&
                        <Tips setTip={setTip} total={order?.total} theme={theme} tip={tip} />
                    }

                    {/* {hasCashAndCard &&
                    <Typography
                        sx={{
                            fontSize: 18,
                            mt: 3,
                            fontWeight: '600',
                            color: 'primary.main'
                        }}
                    // color="primary"
                    >
                        {t('CHOOSE_PAYMENT_METHOD')}
                    </Typography>
                } */}
                    <Stack spacing={1} paddingTop={1}>
                        {bizData?.orderSettings?.hasOrdCc &&
                            <Button
                                variant="outlined"
                                onClick={() => setSelectedPayment(2)}
                            >
                                <Stack
                                    width='100%'
                                    justifyItems={'center'}
                                    alignItems={'start'}
                                    py={.5}
                                >
                                    <Stack
                                        direction={'row'}
                                        alignItems={'center'}
                                        justifyItems={'start'}
                                        spacing={1}
                                    >
                                        <Radio
                                            checked={selectedPayment === 2 ||
                                                (bizData?.orderSettings?.hasOrdCc &&
                                                    !bizData?.orderSettings?.hasOrdCash)}
                                            onChange={() => setSelectedPayment(2)}
                                        />
                                        <Typography variant='subtitle1'>{t('PAY_BY_CARD')}</Typography>
                                    </Stack>

                                    {bizData?.orderSettings?.ccProviders &&
                                        <Stack direction='row' flexWrap='wrap' sx={{ mt: -1, px: 1 }}>
                                            {bizData?.orderSettings?.ccProviders?.hasCardAmex &&
                                                <CreditCardIcon src={CcAmericanExpress} />
                                            }

                                            {bizData?.orderSettings?.ccProviders?.hasCardDiners &&
                                                <CreditCardIcon src={CcDiners} />
                                            }

                                            {bizData?.orderSettings?.ccProviders?.hasCardMasterCard &&
                                                <CreditCardIcon src={CcMasterCard} />
                                            }

                                            {bizData?.orderSettings?.ccProviders?.hasCardVisa &&
                                                <CreditCardIcon src={CcVisa} />
                                            }

                                            {bizData?.orderSettings?.ccProviders?.hasCardGoogle &&
                                                <CreditCardIcon src={CcGoogle} />
                                            }

                                            {bizData?.orderSettings?.ccProviders?.hasCardApple &&
                                                <CreditCardIcon src={CcApple} />
                                            }
                                        </Stack>
                                    }

                                </Stack>
                            </Button>
                        }

                        {bizData?.orderSettings?.hasOrdCash && !bizData.isTable &&
                            <Button
                                variant="outlined"
                                onClick={() => setSelectedPayment(1)}
                            >
                                <Stack className='w-full' direction={'row'} alignItems={'center'} justifyContent={'start'}>
                                    <Radio
                                        checked={selectedPayment === 1 || (!bizData?.orderSettings?.hasOrdCc && bizData?.orderSettings?.hasOrdCash)}
                                        onChange={() => setSelectedPayment(1)}
                                    />
                                    <Typography variant='subtitle1'>{t('PAY_BY_CASH')}</Typography>
                                </Stack>
                            </Button>
                        }

                        {bizData?.orderSettings?.hasOrdPOS && bizData.isTable &&
                            <Button
                                size="large"
                                variant="outlined"
                                onClick={() => setSelectedPayment(6)}
                            >
                                <div className='flex items-center w-full'>
                                    <Radio
                                        checked={selectedPayment === 6 || (!bizData?.orderSettings?.hasOrdPOS)}
                                        onChange={() => setSelectedPayment(6)}
                                    />
                                    <Typography variant='subtitle1'>{t('PAY_BY_POS')}</Typography>
                                </div>
                            </Button>
                        }
                    </Stack>

                    <PaymentButton
                        onClick={sendOrder}
                        sx={{ mt: 3 }}
                        loading={isLoading}
                        sum={currency(order?.total + tip)}
                        label={
                            selectedPayment === 2
                                ? t('CONTINUE_TO_PAY')
                                : t('SEND_ORDER')
                        }
                    />
                    {/* <Button
                    onClick={sendOrder}
                    variant="contained"
                    style={{ backgroundColor: theme.palette.primary.main }}
                    sx={{
                        mt: 3,
                        height: 55
                    }}
                    size="large"
                    disabled={isLoading}
                >
                    {isLoading
                        ? <Loader size={48} />
                        : <>
                            <Stack className="w-full" direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                <Typography variant='subtitle1'>
                                    {selectedPayment === 2
                                        ? t('CONTINUE_TO_PAY')
                                        : t('SEND_ORDER')}
                                </Typography>
                                <Typography variant='h6'>
                                    {currency((order?.address?.pr ?? 0) + order?.total + tip)}
                                </Typography>
                            </Stack>
                        </>
                    }
                </Button> */}
                </div>
            </Fade>
        </>
    )
}

export default Payment;