import React, { useState, useEffect } from 'react';
import { Dialog, Button, Card, Box, Typography, IconButton } from "@mui/material";
import Loading from '../../utils/Loading'
import { useSelector } from 'react-redux';
import axios from 'axiosConn';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';
import CloseIcon from '@mui/icons-material/Close';
import QRCodeScanner from 'components/QRCodeScanner';

// requestType == 2-bill 1-waiter
const Service = ({ requestType, onClose }) => {
    let theme = useTheme();
    const { t } = useTranslation();
    const bizData = useSelector(state => state?.bizData);
    const [permInfoOpen, setPermInfoOpen] = useState(true); //permission info msg dialog
    const [response, setResponse] = useState(null);
    const [loadingScanner, setLoadingScanner] = useState(false);
    const [showScanner, setShowScanner] = useState(false);
    const [allowSend, setAllowSend] = useState(true);
    const [qrData, setQrData] = useState('');
    const [permissionStatus, setPermissionStatus] = useState(null);

    function handlePermission() {
        OpenScanner(true)

        /* Todo: make it work!
            Will work properly on HTTPS only!
           (Won't work properly on local dev, unless we have HTTPS environment) */

        // navigator.getUserMedia({ video: true }, function (stream) {
        //     stream.getTracks().forEach(x => x.stop());
        //     if (stream) {
        //         // setCameraPermissionsState(null);
        //         OpenScanner(true)
        //     }
        // }, err => setPermissionStatus(t('CAMERA_SETTINGS')));

        // navigator.permissions.query({ name: 'camera' })
        //     .then((permissionObj) => {
        //         if (permissionObj.state === 'granted') {
        //             setCameraPermissionsState(null);
        //             OpenScanner(true)
        //         }

        //         else if (permissionObj.state === 'prompt')
        //             setCameraPermissionsState(t('PERMISSION_ERR'));

        //         else if (permissionObj.state === 'denied')
        //             setCameraPermissionsState(t('CAMERA_SETTINGS'));
        //     })
        //     .catch((error) => {
        //         console.error('Got error :', error);
        //     })
    }

    const stopQrScanner = async () => {

        // qrScannerRef?.current?.stopCamera();

        // const stream = await navigator.mediaDevices.getUserMedia({
        //     audio: true,
        //     video: true,
        //     stopCamera: true,
        // });
        // stream.getTracks().forEach(function (track) {
        //     track.stop();
        //     track.enabled = false;
        // });
    };

    useEffect(() => {//camera permissions
        // function isIOS() {
        //     return [
        //         'iPad Simulator',
        //         'iPhone Simulator',
        //         'iPod Simulator',
        //         'iPad',
        //         'iPhone',
        //         'iPod'
        //     ].includes(navigator.platform)
        //         // iPad on iOS 13 detection
        //         || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
        // }
        // return isIOS() ? setHasCameraPermissions(null) : handlePermission();

        return () => stopQrScanner();
    }, []);

    useEffect(() => {
        if (qrData) {
            let scannedGuid = qrData.split('/');
            if (scannedGuid?.length > 0) {
                scannedGuid = scannedGuid[scannedGuid.length - 1];
                if (scannedGuid)
                    setService(scannedGuid);
            }
        }
    }, [qrData]);

    const OpenScanner = () => {
        setShowScanner(true);
        setPermInfoOpen(false);
        setLoadingScanner(true);
        setTimeout(() => {
            setLoadingScanner(false)
        }, 1500);
    }

    function setService(scannedGuid) {
        if (!allowSend)
            return;

        setAllowSend(false);
        axios
            .post('service', {
                guid: scannedGuid,
                mainGuid: bizData?.guid,
                servTyp: requestType
            })
            .then(response => {
                if (response.data.id === 1) {
                    setAllowSend(false);
                    stopQrScanner();
                    setResponse(t('SERVICE_POST_SUCCESS'));
                    setShowScanner(false);

                }
                else {
                    setAllowSend(true);
                    setShowScanner(false);
                    setResponse(t('ERR'));
                }
            })
            .catch(err => {
                setResponse(t('ERR'));
                setShowScanner(false);
                setAllowSend(false);
            });
    };

    // const handleScan = (data) => {
    //     if (showScanner && data) {
    //         setShowScanner(false);
    //         let scannedGuid = data.split('/');
    //         if (scannedGuid?.length > 0) {
    //             scannedGuid = scannedGuid[scannedGuid.length - 1];
    //             if (scannedGuid)
    //                 setService(scannedGuid);
    //         }
    //     }
    // }

    return (
        <div className='h-full '>
            <Dialog
                open={permInfoOpen}
                className='flex self-center justify-center'
            >
                <Box className='my-auto flex flex-col justify-center p-6'
                    sx={{
                        // bgcolor: 'paper.main', 
                        width: 300
                    }}
                >
                    <Typography
                        className='mt-2 text-center'
                    // sx={{ color: "cGroup.main" }}
                    >
                        {t('NEED_CAMERA_PERMISSIONS')}
                    </Typography>
                    <Box className='flex justify-center'>
                        <Button
                            className='mt-6 w-40 mx-2'
                            variant='contained'
                            onClick={() => {
                                handlePermission();
                                setPermInfoOpen(false);
                            }}
                        >
                            {t('ACCEPT')}
                        </Button>
                        <Button
                            className='mt-6 w-40 mx-2'
                            variant='contained'
                            onClick={onClose}
                        >
                            {t('CANCEL')}
                        </Button>
                    </Box>
                </Box>
            </Dialog>

            <Card className='lg:w-2/5 p-2  lg:m-auto h-full'
                sx={{ backgroundColor: 'primary.main' }}
            >
                <div className='flex justify-end'>
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </div>

                {permissionStatus != null
                    ? <div className='flex flex-col h-full justify-center items-center px-8 text-center'>
                        <Typography className='font-bold'>
                            {t('CAMERA_SETTINGS')}
                        </Typography>
                    </div>
                    : response
                        ? <div className='flex flex-col h-full justify-center items-center px-8'>
                            <Typography className='font-bold'>
                                {response}
                            </Typography>
                            <Button
                                className='mt-4 w-full mx-auto'
                                variant='contained'
                                onClick={onClose}
                                style={{
                                    maxWidth: '300px',
                                    textTransform: 'none',
                                }}
                            >
                                {t('ACCEPT')}
                            </Button>
                        </div>
                        : showScanner &&
                        <div className='p-4 relative flex justify-center items-center h-full'
                            style={{ width: '100%', overflow: 'hidden' }}
                        >
                            <div className='mx-auto'>
                                {!loadingScanner &&
                                    <Typography
                                        className='text-center font-bold text-xl'>
                                        {t('SCAN_TABLE_QR')}
                                    </Typography>
                                }
                                {loadingScanner &&
                                    <div
                                        className='flex flex-col justify-center items-center w-full h-full p-4'>
                                        <Loading />
                                    </div>
                                }

                                {showScanner &&
                                    <QRCodeScanner
                                        style={{
                                            width: '100%',
                                            height: '100%'
                                        }}
                                        setPermissionStatus={setPermissionStatus}
                                        onScanSuccess={(decodedText) => setQrData(decodedText)}
                                    />
                                }

                                {!loadingScanner &&
                                    <div>
                                        <Button
                                            className='mt-4 w-full mx-auto'
                                            variant='contained'
                                            onClick={onClose}
                                        >
                                            {t('CANCEL')}
                                        </Button>
                                    </div>
                                }
                            </div>
                        </div>
                }
            </Card>
        </div>
    )
}

export default Service;