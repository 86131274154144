import useCommon from "common/hooks/cartModifiers";
import { useSelector } from "react-redux";

const useCurrency = () => {
    const { currencyFormat } = useCommon();
    const bizData = useSelector(state => state.bizData);
    const selectedLang = useSelector(state => state.userSettings.selectedLang);
    const IncorrectCode = [5]

    const langCode = !selectedLang?.code || IncorrectCode.includes(selectedLang?.id)
        ? "he-IL"
        : selectedLang?.code

    const locale = { code: langCode, currency: bizData?.currency ?? "ILS" }

    const getCurrencySymbol = () =>
        (0).toLocaleString(
            locale.code,
            {
                style: 'currency',
                currency: locale.currency,
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
            }
        ).replace(/\d/g, '').trim()

    const currency = (num, maximumFractionDigits = 2) => {
        const langCode = !selectedLang?.code || IncorrectCode.includes(selectedLang?.id)
            ? "he-IL"
            : selectedLang?.code

        const locale = { code: langCode, currency: bizData?.currency ?? "ILS" }
        return currencyFormat(num, maximumFractionDigits, locale)
    }
    return { currency, getCurrencySymbol }
}
export default useCurrency