import { createSlice } from '@reduxjs/toolkit'

export const sliceName = 'searchProdSlice';

export const appSettingsSlice = createSlice({
    name: sliceName,
    initialState: {
        selectedProd: null,
        selectedBadge: [],
        openSearch: false
    },
    reducers: {
        setSelectedProd: (state, action) => { state.selectedProd = action.payload },
        setSelectedBadge: (state, action) => { state.selectedBadge = action.payload },
        setOpenSearch: (state, action) => { state.openSearch = action.payload },
    },
})

export const { setSelectedProd, setSelectedBadge, setOpenSearch } = appSettingsSlice.actions

export default appSettingsSlice.reducer