import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Stack, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@emotion/react';
import { setSelectedCat } from 'store/userSettingsSlice';
import ButtonForIframe from 'components/Menu/Categories/ButtonForIframe';
import ImgPlaceholder from 'assets/img-placeholder.svg';
import CategoryListItem from './CategoryListItem';
import SearchProd from '../SearchProd';

// Todo: create a util hook?
/**
 * Native scrollTo with callback
 * @param topOffset - top offset to scroll to
 * @param callback - callback function
 * @param element - element to scroll
 */
// function scrollTo(topOffset, callback, element = window) {
//     if (!element)
//         return;

//     const fixedOffset = topOffset.toFixed();
//     const onScroll = function () {
//         // if (window.pageYOffset.toFixed() === fixedOffset) {
//         if (element.offsetTop.toFixed() === fixedOffset) {
//             element.removeEventListener('scroll', onScroll)
//             callback()
//         }
//     }

//     element.addEventListener('scroll', onScroll)
//     onScroll()
//     element.scrollTo({
//         top: topOffset,
//         behavior: 'smooth'
//     })
// }

function scrollIntoView(container, element, callback) {
    if (!element)
        return;

    /* A litlte delay so it will scroll properly */
    const scrollDelay = setTimeout(function () {
        element.scrollIntoView(true);
        clearTimeout(scrollDelay);
    }, 100);

    var scrollTimeout;
    const onScroll = container.addEventListener('scroll', function (e) {
        clearTimeout(scrollTimeout);
        /* If no scroll event for 100 ms, scroll is finished */
        scrollTimeout = setTimeout(function () {
            element.removeEventListener('scroll', onScroll)
            callback();
        }, 100);
    });
}

const CategoryListNav = ({ categories, setAutoScroll, checkBadges, catContainerRef }) => {

    const dispatch = useDispatch();
    const theme = useTheme();
    const catRef = useRef(null);
    const catWraperRef = useRef(null);
    // const bizData = useSelector(state => state.bizData);
    //const menuData = useSelector(state => state.menu);
    const selectedProd = useSelector(state => state.searchProd.selectedProd);
    const selectedBadge = useSelector(state => state.searchProd.selectedBadge);
    const smScreen = useMediaQuery(theme.breakpoints.down('lg'));
    const mdScreen = useMediaQuery(theme.breakpoints.down('md'));
    const headerHeight = useSelector(state => state.appSettings.headerHeight);
    const selectedCat = useSelector(state => state.userSettings.selectedCat);

    /* Scroll the category list wraper so we can always see
       the selected category when the user scroll the page */
    useEffect(() => {
        // const catsWraper = document.getElementById('cats-wraper');
        const catsWraper = catWraperRef?.current;

        /* Selected category */
        const catElement = catRef?.current;

        if (!catElement || !catsWraper)
            return;

        const rect = catElement.getBoundingClientRect()

        /* Calculate if category is visible, so we can know if we need to scroll */
        const visible = {
            top: rect.top >= headerHeight && rect.top < catsWraper.clientHeight,
            bottom: rect.bottom >= headerHeight && rect.bottom < catsWraper.clientHeight,
            right: rect.right >= 0 && rect.right < window.innerWidth,
            left: rect.left >= 0 && rect.left < window.innerWidth
        };

        /* If category is not visible scroll it into view (mobile) */
        if (smScreen) {
            if (!visible.right || !visible.left) {
                catsWraper.scrollTo({
                    left: catElement?.offsetLeft,
                    behavior: "smooth"
                });
            }
        }
        // else
        //     catElement.scrollIntoView(true);

        /* If category is not visible scroll it into view (bigger screen) */
        else if (!smScreen && (!visible.top || !visible.bottom)) {
            catsWraper.scrollTo({
                top: catElement?.offsetTop - headerHeight - 164,
                behavior: "smooth"
            });
        }
    }, [selectedCat]);

    const navigateToCat = (cat) => {
        let el = document.getElementById(`cat-${cat.id}`);
        if (!el || !catContainerRef?.current)
            return

        /* When user click the category, we set AutoScrolling to true so scroll event will be disabled.
           until we reached the position, then we set Autoscrolling back to false */
        setAutoScroll(true);
        dispatch(setSelectedCat(cat));

        // const y = el.getBoundingClientRect().top + window.pageYOffset - headerHeight - 10;
        scrollIntoView(catContainerRef.current, el, () => setAutoScroll(false));
        // catContainerRef.current.scrollTo({
        //     top: y,
        //     behavior: 'smooth'
        // })
        // scrollTo(y, () => setAutoScroll(false), catContainerRef.current)
    }

    return (
        <>
            <ButtonForIframe />

            <Stack
                className='lg:mt-4'
                height={mdScreen ? 'auto' : `calc(100vh - ${headerHeight}px - 30px)`}
            >
                <div className='hidden lg:block'>
                    <SearchProd />
                </div>

                {categories?.length > 1 &&
                    <Stack
                        ref={catWraperRef}
                        overflow={'scroll'}
                        className='hide-scrollbar'
                        direction={{ xs: 'row', md: 'column' }}
                        spacing={{ xs: 0.5, md: 1 }}
                    // className='flex lg:flex-col h-full w-full overflow-scroll hide-scrollbar lg:space-y-2 lg:space-x-0 lg:pt-4'
                    // sx={{
                    //     // top: !smScreen && headerHeight + 20,
                    //     height: `calc(100vh - 30px)`,
                    //     scrollBehavior: 'smooth',
                    // }}
                    >
                        {categories?.map((cat, index) => {
                            // Skip sub-categories or if should be hidden because of timing
                            if (cat.pid || !cat.isTimingVisible)
                                return;

                            //let currCategory = selectedCat && (selectedCat.id === cat.id || selectedCat.pid === cat.id);
                            const selected = selectedCat && (selectedCat.id === cat.id || selectedCat.pid === cat.id);
                            const findBadges = cat?.items?.filter(item => checkBadges(item?.badges))
                            return (
                                (!selectedProd || cat?.items?.includes(selectedProd)) &&
                                (selectedBadge?.length === 0 || findBadges?.length > 0) &&
                                <CategoryListItem
                                    key={index}
                                    {...cat}
                                    ref={selected ? catRef : null}
                                    selected={selected}
                                    onClick={() => navigateToCat(cat)}
                                />
                            )
                        })}
                    </Stack>
                }

            </Stack >
        </>
    )
}

export default CategoryListNav;