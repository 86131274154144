import React from "react";
import { useSelector } from 'react-redux';
import { useTheme } from "@emotion/react";
import { useMediaQuery, Slide, Dialog } from '@mui/material';
import Product from "../Product/Product";

// import { ModifiersGroup } from "common/src/Components/modifiers"
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="right" ref={ref} {...props} />;
});

const ProdDetail = ({ favorite, currentMenuId }) => {
    const selectedProd = useSelector(state => state.cart.openedProduct);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <Dialog
            fullWidth={true}
            fullScreen={fullScreen}
            open={selectedProd?.id != null}
            TransitionComponent={Transition}
            maxWidth='md'
            scroll="paper"
            className="dialog-custom"
            sx={{
                '.MuiPaper-root': {
                    padding: 0.5,
                    backgroundColor: theme.palette.secondary.main,
                    border: `1px solid ${theme.palette.primary.main}`
                },
            }}
        >
            {selectedProd?.id && <Product />}
        </Dialog>
    );
}

export default ProdDetail;