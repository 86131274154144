import settingsConfig from "./configs/settingsConfig";

// Todo: transfrom this file to hooks? 

class YpUtils {
  static getImage(imgUrl) {
    if (imgUrl?.startsWith('http')) return imgUrl;
    return imgUrl ? settingsConfig.imgApiUrl + imgUrl.replace("\\", "/") : null;
  }

  static getImageThumb(imgUrl) {
    let url = this.getImage(imgUrl);
    let path = url?.match(/(.*)[\/\\]/)[1] || '';
    var filename = url?.replace(/^.*[\\\/]/, '');
    return path + '/thumb_' + filename;
  }

  // static imageLoader = ({ src, width, quality }) => {
  //   if (src?.includes('img-placeholder.svg')) return `${src}`
  //   else return `${this.getImage(src)}?w=${width}&q=${quality || 75}`
  // }

  // static imageThumbLoader = ({ src, width, quality }) => {
  //   if (src?.includes('img-placeholder.svg')) return `${src}`
  //   else return `${this.getImageThumb(src)}?w=${width}&q=${quality || 75}`
  // }

  static objectToFormData(data, extraData, logData) {
    if (!data) console.error("data is null or empty!");
    let formData = new FormData();
    function convertToFormData(data) {
      for (const key in data) {
        if (Object.prototype.toString.call(data[key]) === "[object Array]") {
          if (data[key]) formData.append(key, JSON.stringify(data[key]));
        } else if (
          Object.prototype.toString.call(data[key]) === "[object FileList]"
        ) {
          for (let index = 0; index < data[key].length; index++)
            if (data[key][index]) formData.append(key, data[key][index]);
        } else {
          if (data[key]) {
            formData.append(key, data[key]);
          }
        }
      }
    }

    convertToFormData(data);

    if (extraData) convertToFormData(extraData);

    if (logData) {
      for (var pair of formData.entries())
        console.info(pair[0] + ": " + pair[1]);
    }

    return formData;
  }
}

export default YpUtils;
