import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import { setCart } from "store/userSettingsSlice";
import { useTheme } from "@emotion/react";
import { getProduct } from 'store/productSlice';
import { addCartItem } from "store/cartSlice";
import OutOfStock from "components/utils/OutOfStock";
import { Box, Stack, Typography, useMediaQuery } from "@mui/material";
import MenuItemImg from "./components/MenuItemImg";
import Badges from "components/Badges";
import { setProducts } from "store/upsalesSlice";
import useCurrency from "hooks/useCurrency";
import { setLoadingProduct } from "store/menuSlice";

const MenuItem = ({ product, catId, CloseDrawer }) => {
    let theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    let { t } = useTranslation();
    const dispatch = useDispatch();
    const { currency } = useCurrency();
    const bizData = useSelector(state => state.bizData);
    const selectedLang = useSelector(state => state.userSettings.selectedLang);
    const cartItems = useSelector(state => state.cart.cartItems);
    const [quantityInCart, setQuantityInCart] = useState(0);
    const [DrawerProd, setDrawerProd] = useState({ open: false, height: 4 });
    const [oldXProd, setOldXProd] = useState(null);
    const newOrPromo = product?.isPromo || product?.isNew || product?.isPopular;

    useEffect(() => {
        let qty = 0;
        cartItems?.forEach((ci) => {
            if (ci.id === product.id && ci.catId === product.pid)
                qty += ci.qty;
        })
        setQuantityInCart(qty);
    }, [cartItems])

    const getPrice = () => {
        let groups = product?.selectedIngGrps;
        let ttl = product?.pr;
        groups?.map(group => {
            group?.ings?.map(ing => {
                if (ing?.qty > ing?.freeQty)
                    ttl += (ing?.qty - ing?.freeQty) * ing?.pr
            })
        })

        return ttl;
    }

    const slideProd = (e) => {
        const clientX = e.targetTouches[0].clientX
        setOldXProd(clientX)
        if (clientX > oldXProd) {
            setDrawerProd(data => { return { open: true, height: data.height - 4 } })
        }
        else if (DrawerProd?.height > 15) {
            setDrawerProd(data => { return { open: true, height: data.height } })
        }
        else if (clientX < oldXProd) {
            setDrawerProd(data => { return { open: true, height: data.height + 4 } })
        }

    }

    const AddToCart = (e) => {
        e.stopPropagation();
        if (product?.hasIng || (bizData?.hasImgs && product?.desc))
            return openItemDetails();
        if (!product.isOutOfStock) {
            const cartItem = {
                guid: null,
                name: product.name,
                desc: product.desc,
                price: product.pr,
                totalPrice: product.pr,
                id: product.id,
                catId: catId,
                qty: 1,
                modifierGroups: [],
                isSimple: !product.hasBadge && !product.hasIngs,
                imgUrl: product.imgUrl
            };

            dispatch(addCartItem(cartItem))
            dispatch(setProducts(cartItem))
            dispatch(setCart([]));
        }
    }

    const openItemDetails = () => {
        window.history.pushState('product', null, window.location.href);
        dispatch(setLoadingProduct(true));

        dispatch(getProduct({
            bizId: bizData?.id,
            itemId: product?.id,
            langId: selectedLang?.id,
            guid: bizData?.guid,
            catId: catId
        })).then((res) => {
            dispatch(setLoadingProduct(false));
        }).catch(() => {
            dispatch(setLoadingProduct(false));
        });
        //dispatch(setSelectedProd(product));
        CloseDrawer && CloseDrawer();
    }

    return (
        <Box
            sx={{ border: (theme) => `1px solid ${theme.palette.primary.main}` }}
            className={`relative w-full mt-2 p-2 overflow-hidden flex menu-item ${quantityInCart > 0 && 'in-cart'}`}
        >

            {quantityInCart > 0 &&
                <Box
                    sx={{
                        position: 'absolute',
                        height: '28px',
                        width: '28px',
                        borderBottomLeftRadius: '50%',
                        top: 0,
                        right: 0,
                        backgroundColor: 'clButtons.main'
                    }}
                    color="secondary.main"
                    className="flex justify-center items-center font-bold"
                >
                    {quantityInCart}
                </Box>
            }

            {DrawerProd.open
                ? <Stack
                    height='7rem'
                    width={`${DrawerProd.height}px`}
                // bgcolor='primary.main'
                >
                </Stack>
                : null
            }

            <Stack
                width='100%'
                spacing={1}
                direction='row'
                className="cursor-pointer"
                onTouchMove={(e) => slideProd(e)}
                onTouchEnd={(e) => {
                    if (DrawerProd?.height > 15) {
                        setDrawerProd({ open: false, height: 1 })
                        AddToCart(e)
                    }
                    else {
                        setDrawerProd({ open: false, height: 1 })
                    }
                }
                }
                onClick={AddToCart}>

                {/* Menu-item image */}
                <MenuItemImg
                    hasImgs={bizData?.hasImgs}
                    imgLogo={bizData?.imgLogo}
                    imgUrl={product.imgUrl}
                />

                {/* Menu-item details */}
                <Box className={`flex flex-col w-full`}>
                    <Stack direction={'row'} spacing={1} alignItems={'center'}>
                        {/* Title & Catalog number */}
                        {product?.numInMenu != null
                            ? <Typography>{product?.numInMenu}</Typography>
                            : null
                        }
                        <Typography
                            variant="subtitle1"
                            fontWeight={isMobile ? 'bold' : 900}
                            sx={{ marginTop: -1 }}
                        >
                            {product?.name}
                        </Typography>
                    </Stack>

                    {/* Description */}
                    <Typography
                        variant="subtitle2"
                        dangerouslySetInnerHTML={{ __html: product?.desc }} />

                    {/* product badges */}
                    <Badges product={product} />


                    <div className="flex w-full h-full items-end justify-end gap-2">
                        {product?.isOutOfStock &&
                            <OutOfStock sx={{ position: 'relative', fontSize: '1rem' }} />
                        }

                        {product?.pr > 0 &&
                            <div className="flex text-base min-w-fit gap-1">

                                {product?.oldPr > 0 && !product?.guid &&
                                    <span
                                        className='line-through opacity-60'>
                                        {currency(product?.oldPr)}
                                    </span>
                                }
                                {product?.guid
                                    ? currency(getPrice())
                                    : currency(product?.pr)
                                }
                            </div>
                        }
                    </div>
                </Box>
            </Stack >

            {newOrPromo &&
                <Box bgcolor={'clButtons.main'}
                    sx={{
                        width: 80,
                        height: 80,
                        transform: selectedLang?.isRtl ? 'rotate(-38deg)' : 'rotate(38deg)',
                        position: 'absolute',
                        bottom: -47,
                        left: -38,
                    }}
                    className="flex justify-center"
                >
                    <Typography variant="subtitle2" component='span' color='secondary'>

                        {product.isNew
                            ? t('IS_NEW')
                            : product.isPromo
                                ? t('IS_PROMO')
                                : product.isPopular
                                    ? t('IS_POPULAR')
                                    : ''
                        }
                    </Typography>
                </Box>
            }
        </Box >
    )
}

export default MenuItem;