// export const email = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const email = /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const numberOnly = /^[0-9]*$/;
export const count = /^\d+$/;
export const numbersNotAllowed = /^([^0-9]*)$/;
export const lettersOnly = /^[\u0590-\u05FF A-Za-z\/\s/.'\-]+$/
export const telIsraeli = /^[0-9]{9,10}$/;
export const telIsraeliWithAsterisk = /^[*]*[0-9]{4,10}$/;
export const celIsraeli = /^05\d{8}$/;
export const password = /^(?=.*[A-Za-z])(?=.*\\d)[A-Za-z\\d]{6,50}$/;
export const personName = /^([a-zA-Z]+\s)*[a-zA-Z]+$/;
export const url = /[Hh][Tt][Tt][Pp][Ss]?:\/\/(?:(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:\.(?:[a-zA-Z\u00a1-\uffff]{2,}))(?::\d{2,5})?(?:\/[^\s]*)?/;
export const ssid = /^[0-9]{9}$/;
export const onlyHebrew = /^[\u0590-\u05FF ,.'-]+$/;
export const onlyEnglish = /^[a-zA-Z ,.'-]+$/;