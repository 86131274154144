import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import ModifiersGroup from "../../modifiers/ModifiersGroup";
import {
    addCartItem,
    updateCartItem,
    setTempCartItem,
    updateTempCartItemGroup,
    updateTempCartItemQty,
    resetTempCartItem,
    setOpenedProductGroup,
} from "../../../store/cartSlice";
// import BackArrowIcon from "../../Components/BackArrowIcon/BackArrowIcon";
//import LanguageButton from "../../Components/LanguageButton/LanguageButton";
import useCommon from "common/hooks/cartModifiers";
import { Close } from "@mui/icons-material";
import { setCart } from "store/userSettingsSlice";
import { useTheme } from "@emotion/react";
import ImageSwiper from "../MenuItem/components/Swiper";
import { ReactComponent as WarningIcon } from '../../../assets/errors/warning.svg';
import CustomModal from "components/utils/CustomModal";
import Counter from "components/utils/Counter";
import Badges from "components/Badges";
import { setProducts } from 'store/upsalesSlice'
import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Stack,
    TextField,
    Typography,
    useMediaQuery
} from "@mui/material";
import { BUSINESS_TYPE_ID, UPSALE_TYPE_ID } from "configs/consts";
import useCurrency from "hooks/useCurrency";

const Product = () => {
    const theme = useTheme();
    const cat = useSelector((state) => state.cart.openCat);
    //const product = useSelector(state => state.userSettings.selectedProd);
    const product = useSelector((state) => state.cart.openedProduct);
    const bizData = useSelector((state) => state.bizData);
    const orderSettings = useSelector(state => state.bizData?.orderSettings);
    const isIframe = useSelector(state => state.appSettings.isIframe);

    const dispatch = useDispatch();
    let { t } = useTranslation();
    const { currency } = useCurrency();
    const { modifyTempCartItemGroup, getProductDefaultGroups, validateBeforeAddToCart } = useCommon();
    // productInfo - an object containing all displayed information according to current langId
    const [productInfo, setProductInfo] = useState({ name: '', desc: '' });
    const [isInvalid, setIsInvalid] = useState([]);
    const [hasTriedToAddToCart, setHasTriedToAddToCart] = useState(false);
    const [alert, setAlert] = useState({ open: false, content: {} });
    /** 
     * tempCartItem - a temporary cart item which eventually will be added to the real cart. 
     * editing existing cartItem 
     * */
    const tempCartItem = useSelector((state) => state.cart.tempCartItem);

    const smScreen = useMediaQuery(theme.breakpoints.down('lg'));
    const mdScreen = useMediaQuery(theme.breakpoints.down('md'));

    //window.history.pushState(null, null, window.location.href);
    const popStateHandler = () => {
        onBackToMenuHandler();
    }
    window.addEventListener('popstate', popStateHandler, true);

    /** initializing tempCartItem with selected product basic properties. */
    useEffect(() => {
        if (!tempCartItem.guid) {
            dispatch(setTempCartItem({
                ...tempCartItem,
                name: product.name,
                price: product.pr,
                id: product.id,
                catId: cat.id,
                imgUrl: product.imgUrls[0] || null,
                modifierGroups: getProductDefaultGroups(product)
            }))
        }
    }, [])

    useEffect(() => {
        setProductInfo({
            name: product?.name || '',//product.langs[0]?.name,
            desc: product?.desc || '',//product.langs[0]?.desc,
        })
    }, [])

    useEffect(() => {
        if (hasTriedToAddToCart) {
            validateBeforeAddToCart(product, tempCartItem.modifierGroups).then((res) => {
                if (res)
                    setIsInvalid(res[0]);
            });
        }
    }, [tempCartItem.modifierGroups]);

    const onAddCartItemHandler = () => {
        setHasTriedToAddToCart(true);
        setIsInvalid(null)
        validateBeforeAddToCart(product, tempCartItem.modifierGroups, true).then((res) => {
            if (!res || res.length === 0) {
                if (!tempCartItem.guid)
                    dispatch(addCartItem(tempCartItem))
                else
                    dispatch(updateCartItem(tempCartItem))
                // Set last added product on upsale slice to check if there's an upsale for display 
                dispatch(setProducts({ ...tempCartItem, typeId: UPSALE_TYPE_ID.ADD_TO_CART }))
                //dispatch(checkUpsales(tempCartItem))

                dispatch(resetTempCartItem());
                dispatch(setCart([]));
            }
            else {
                setAlert({
                    open: true,
                    content: res[0],
                });
            }
        });
    }

    const onModifyGroup = (group, modifier, action) => {
        const tci = modifyTempCartItemGroup(tempCartItem, { group: group, modifier: modifier, actionType: action })
        dispatch(setOpenedProductGroup({ ...group, touched: true }))
        dispatch(updateTempCartItemGroup(tci))
        //dispatch(updateTempCartItemGroup({ group: group, modifier: modifier, actionType: action }))
    }

    const onBackToMenuHandler = () => {
        // if (window.history.length > 3 && window.history.state === 'product')
        //     window.history.go(-1);
        dispatch(resetTempCartItem())
        //navigate(`/menu/${guid}`);
    }

    const onCloseAlertHandler = () => {
        setAlert({ ...alert, open: false });
        setIsInvalid(alert.content);
    }
    return (
        <>
            <DialogTitle className="flex px-2">
                <Typography
                    className="w-full text-center"
                    color="primary.main"
                    variant="h5"
                >
                    {productInfo.name}
                </Typography>
                <IconButton size="small" color="primary">
                    <Close onClick={() => onBackToMenuHandler()} />
                </IconButton>
            </DialogTitle>
            <DialogContent className="p-2" sx={(theme) => theme.scrollBar}>

                {/** Validation alert dialog */}
                <CustomModal
                    open={alert.open}
                    onClose={onCloseAlertHandler}
                    svgIcon={{ component: WarningIcon }}
                    actionBtn={{
                        title: t('CONFIRM'),
                        action: onCloseAlertHandler
                    }}

                >
                    <Box className="flex flex-col justify-center items-center" sx={{ minWidth: 220 }} >
                        <Typography variant="h6" color="primary">
                            {alert?.content?.langs && alert?.content?.langs[0].name}
                        </Typography>
                        <Typography variant="subtitle1" color="primary">
                            {`${t(alert?.content?.error?.text, alert?.content?.error?.params)}`}
                        </Typography>
                    </Box>
                </CustomModal>

                {bizData?.hasImgs &&
                    <div className="flex flex-col">
                        <ImageSwiper
                            imgUrls={product?.imgUrls || [bizData.imgLogo]}
                            theme={theme}
                            bizData={bizData}
                            smScreen={smScreen}
                        />
                    </div>
                }
                {/* <img
                    className="w-full object-cover h-52 lg:h-96"
                    src={product?.imgUrls?.length === 0 ? bizData?.imgLogoUrl : product?.imgUrls[0]}
                /> */}
                {/* <LanguageButton top='480px' /> */}

                {productInfo?.desc
                    ? <Typography
                        className="mt-2"
                        color="primary.main"
                        variant='subtitle1'
                        dangerouslySetInnerHTML={{ __html: productInfo.desc }}
                    />
                    : null
                }

                {/* badges */}
                <Badges product={product} />

                {product?.pr > 0
                    ? <Typography
                        className="font-bold mt-3"
                        color="primary"
                        variant="h6"
                    >
                        {`${t('PRICE')}: ${currency(product?.pr)}`}
                    </Typography>
                    : null
                }

                <Box
                    className="w-full mt-6 flex flex-col"
                    sx={[(theme) => theme.scrollBar, { maxHeight: '54.5%' }]}
                >
                    {product && product.modifierGroups?.map((group, idx) => {
                        return (
                            <ModifiersGroup
                                {...group}
                                isInvalid={isInvalid?.id === group.id}
                                key={idx}
                                onModifyGroup={onModifyGroup}
                            >
                            </ModifiersGroup>
                        )
                    })
                    }
                </Box>
            </DialogContent>

            {<DialogActions
                className="flex flex-col spacing-2"
                sx={{ borderColor: 'primary.main' }}>

                {!isIframe && bizData?.hasOrderMenu
                    ? <Stack
                        width='100%'
                        direction={{ lg: "row" }}
                        gap={1}
                        paddingY={1}
                    >
                        {orderSettings?.showItemBelongTo && bizData.bizTypeId === BUSINESS_TYPE_ID.RESTAURANT
                            ? <TextField
                                size="small"
                                inputProps={{ maxLength: 50 }}
                                defaultValue={tempCartItem?.belongsTo}
                                fullWidth
                                onChange={(e) => {
                                    dispatch(setTempCartItem({ ...tempCartItem, belongsTo: e.target.value }))
                                }}
                                label={t('BELONGS_TO')}
                            />
                            : null
                        }

                        {true
                            ? <TextField
                                size="small"
                                inputProps={{ maxLength: 50 }}
                                defaultValue={tempCartItem?.remarks}
                                fullWidth
                                onChange={(e) => {
                                    dispatch(setTempCartItem({ ...tempCartItem, remarks: e.target.value }))
                                }}
                                label={bizData.bizTypeId === BUSINESS_TYPE_ID.RESTAURANT ? t('REMARKS') : t('PRODUCT_REMARKS')}
                            />
                            : null
                        }
                    </Stack>
                    : null
                }


                <Stack
                    direction='row'
                    width="100%"
                    justifyContent='space-between'
                    gap={3}
                >
                    <Counter
                        label={`${t('QUANTITY')}:`}
                        size={mdScreen ? 'lg' : 'xl'}
                        reversed
                        onAdd={() => { dispatch(updateTempCartItemQty(1)) }}
                        quantity={tempCartItem?.qty}
                        onRemove={() => {
                            if (tempCartItem.qty > 1) {
                                dispatch(updateTempCartItemQty(-1))
                            }
                        }}
                        disableRemove={tempCartItem.qty === 1}
                    />
                    {
                        isIframe ?
                            <Typography color="primary" variant={mdScreen ? "h6" : "h5"} className="min-w-fit">
                                {`${t('TOTAL')}: ${currency(tempCartItem?.totalPrice)}`}
                            </Typography>
                            :
                            <Button
                                size="large"
                                className="flex gap-6 w-full rounded-md justify-start"
                                disabled={product.isOutOfStock}
                                // color="primary"
                                variant="contained"
                                onClick={onAddCartItemHandler}
                            >
                                <Typography variant={mdScreen ? "h6" : "h5"} className="w-full">
                                    {product.isOutOfStock ? t("OUT_OF_STOCK")
                                        : bizData?.hasOrderMenu ? t("ADD_TO_CART")
                                            : t("ADD_TO_FAVORITES")}
                                </Typography>
                                <Typography variant={mdScreen ? "h6" : "h5"} className="min-w-fit">
                                    {currency(tempCartItem?.totalPrice)}
                                </Typography>
                            </Button>
                    }
                </Stack>
            </DialogActions>}
        </>
    )
}
export default Product;