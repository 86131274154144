import { useTheme } from "@emotion/react";
import { Box } from "@mui/material";

const CreditCardIcon = ({ src }) => {
    const theme = useTheme();
    return (
        <Box className="border border-solid border-black"
            sx={{
                borderRadius: 1,
                mr: theme.direction === "rtl" ? 1 : 0,
                ml: theme.direction === "ltr" ? 1 : 0,
                width: 76,
                mt: 1,
                height: 43
            }}
        >
            <img
                width={'100%'}
                height={'100%'}
                src={src}
            />
        </Box>
    )
}
export default CreditCardIcon;