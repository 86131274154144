import bizDataSlice from './bizDataSlice';
import menuSlice from './menuSlice';
import productSlice from './productSlice';
import userSettingsSlice from './userSettingsSlice';
import appSettingsSlice from './appSettingsSlice';
import cartSlice from './cartSlice';
// import bizCloseTimeReducer from './bizCloseTime';
// import bizOpenTimeReducer from './bizOpenTime';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import searchProdSlice from './searchProdSlice';
import updateAppSlice from './updateAppSlice';
import salesSlice from './salesSlice';
import upsalesSlice from './upsalesSlice';

const combinedStore = combineReducers({

    bizData: bizDataSlice,
    menu: menuSlice,
    product: productSlice,
    userSettings: userSettingsSlice,
    appSettings: appSettingsSlice,
    cart: cartSlice,
    searchProd: searchProdSlice,
    updateApp: updateAppSlice,
    sales: salesSlice,
    upsales: upsalesSlice
    // bizCloseTime: closeTime,
    // bizOpenTime: openTime,

    //...commonStore,
});

const store = configureStore({ reducer: combinedStore });

export default store;