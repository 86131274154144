import { useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { Badge, Box, Fade, IconButton, SwipeableDrawer, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import CartItems from './CartItems';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import useCommon from "common/hooks/cartModifiers"
import Cart from './Cart';
import OrderTypes from '../OrderTypes';
import useCurrency from 'hooks/useCurrency';

const CartMobile = () => {
    let theme = useTheme();
    const { t } = useTranslation();
    const { deepEqual } = useCommon();
    const { currency } = useCurrency();
    const hasOrderMenu = useSelector(state => state.bizData.hasOrderMenu);
    const menuWithOrder = useSelector(state => state.menu.canOrder);
    const { cartItems, cartTotalPrice } = useSelector(state => state.cart);
    const bizData = useSelector(state => state.bizData);
    const order = useSelector(state => state.userSettings.order);
    const [open, setOpen] = useState(false);
    const [prevLength, setPrevLength] = useState(null);
    const [prevItems, setPrevItems] = useState([]);
    const [addedCartItem, setAddedCartItem] = useState(null);
    const [animate, setAnimate] = useState(false);

    const onCloseHandler = () => {
        setOpen(false);
    }

    const onOpenHandler = () => {
        window.history.pushState('cart', null, window.location.href);
        setOpen(true);
    }

    const popStateHandler = () => {
        onCloseHandler();
    }
    window.addEventListener('popstate', popStateHandler, true);

    useEffect(() => {
        if (addedCartItem) {
            setAnimate(true);
            const timer = setTimeout(() => {
                setAnimate(false)
            }, 1500);
            return () => clearTimeout(timer);
        }
    }, [addedCartItem])

    useEffect(() => {
        let qty = 0;
        cartItems?.forEach((ci) => {
            qty += ci.qty;
            const item = prevItems?.find((pi) => pi.guid === ci.guid);
            if (item && ci) {
                if (!deepEqual(item, ci)) {
                    setAddedCartItem(item);
                }
            }
            else {
                setAddedCartItem(ci);
            }
        })
        setPrevItems(cartItems)
        setPrevLength(qty);
    }, [cartItems]);

    return (
        <Box className='w-full absolute bottom-0 flex flex-col gap-2'>
            {<Fade in={animate}>
                <Box
                    className={`rounded-sm mx-2 p-4 shadow-md flex justify-between ${animate ? 'block' : 'hidden'}`}
                    sx={{ backgroundColor: 'primary.main' }}
                >
                    <Typography variant="h6" color="secondary">{addedCartItem?.name}</Typography>
                    {addedCartItem?.totalPrice > 0 &&
                        <Typography variant="h6" color="secondary">
                            {`${currency(addedCartItem?.totalPrice)}`}
                        </Typography>
                    }
                </Box>
            </Fade>
            }
            <Box onClick={onOpenHandler} className='w-full flex justify-between items-center shadow-md pl-4 pr-2 py-1'
                sx={{ backgroundColor: 'primary.main' }}
            >
                <div className='flex gap-4 items-center'>
                    <IconButton className='rounded-none'>
                        <Badge badgeContent={prevLength} size="small"
                            sx={{
                                '& .MuiBadge-badge': {
                                    top: 5,
                                    padding: '2px 4px',
                                    border: `solid 1px ${theme.palette.primary.main}`,
                                    backgroundColor: 'secondary.main',
                                    color: 'primary.main',
                                    fontWeight: 'bold'
                                }
                            }}>

                            {bizData?.hasOrderMenu ?
                                animate ?
                                    <ShoppingBagIcon
                                        color='secondary'
                                        className='cursor-pointer flex justify-end h-7 animate_badge' />
                                    :
                                    <ShoppingBagOutlinedIcon
                                        id="icon"
                                        color='secondary'
                                        className='cursor-pointer flex justify-end h-7'
                                    />
                                :
                                animate ?
                                    <FavoriteIcon
                                        color='secondary'
                                        className='cursor-pointer flex justify-end h-7 animate_badge' />
                                    :
                                    <FavoriteBorderIcon
                                        id="icon"
                                        color='secondary'
                                        className='cursor-pointer flex justify-end h-7'
                                    />
                            }

                        </Badge>
                    </IconButton>
                    <Typography variant="h6" color="secondary">
                        {hasOrderMenu && menuWithOrder && order.typeId > 0
                            ? t('CART')
                            : t('FAVORITES_LIST')
                        }
                    </Typography>
                </div>
                <Typography variant="h6" color="secondary">
                    {`${currency((order?.address?.pr || 0) + cartTotalPrice)}`}
                </Typography>
            </Box>

            <SwipeableDrawer
                anchor='bottom'
                disableSwipeToOpen
                open={open}
                // onOpen={() => setOpen(true)}
                onClose={onCloseHandler}
                transitionDuration={400}
                PaperProps={{
                    sx: {
                        // backgroundColor: 'primary.main',
                        height: '100vh'
                    },
                }}
            >
                <div className='flex flex-col items-center h-full w-full overflow-hidden'>
                    <Box
                        bgcolor='primary.main'
                        className='w-full flex items-center justify-between p-2'
                    >
                        <Typography
                            className='font-semibold text-lg px-6 w-full text-center mr-8'
                            color="secondary"
                        >
                            {hasOrderMenu && menuWithOrder && order.typeId > 0
                                ? t('CART')
                                : t('FAVORITES_LIST')
                            }
                        </Typography>
                        <IconButton>
                            <CloseIcon onClick={(onCloseHandler)} className="cursor-pointer"
                                color='secondary'
                            />
                        </IconButton>
                    </Box>

                    <OrderTypes />

                    <CartItems CloseDrawer={onCloseHandler} />
                </div>
            </SwipeableDrawer>
        </Box>
    )
}

export default CartMobile;