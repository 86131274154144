import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Button, Stack, Typography } from '@mui/material';
import YpUtils from 'YpUtils';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const StepHeader = ({ backBtnClick, title, subTitle }) => {
    const { t } = useTranslation();
    const selectedLang = useSelector(state => state.userSettings.selectedLang);
    const bizData = useSelector(state => state.bizData);

    return (
        <Stack marginBottom={2}>
            <img
                className="w-full max-h-52 xl:max-h-60 mb-2 p-2 object-contain"
                src={YpUtils.getImage(bizData?.imgLogo)}
                alt='Business logo'
            />
            <Stack
                className='flex w-full p-2'
                bgcolor="secondary.main"
                direction='row'
                justifyContent='space-between'
                alignItems='center'
            >

                {/* Back button */}
                {backBtnClick
                    ? <Button
                        onClick={backBtnClick}
                        variant='outlined'
                        startIcon={
                            <ArrowBackIosIcon
                                sx={{ transform: `${selectedLang?.isRtl ? 'rotate(180deg)' : ''}` }}
                            />
                        }>
                        <Typography>{t('BACK')}</Typography>
                    </Button>
                    : null
                }

                {/* Title */}
                <Typography color="primary" className='flex flex-col w-4/6 items-center'>
                    <span className='text-xl xl:text-3xl'>
                        {title}
                    </span>
                    <span className='text-center'>
                        {subTitle}
                    </span>
                </Typography>

                <div className='flex flex-col w-1/6'></div>
            </Stack>
        </Stack>

    )
}

export default StepHeader;