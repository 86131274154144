import { Button, Stack, Typography } from "@mui/material"
import CustomModal from "components/utils/CustomModal";
import { ORDER_TYPE_ID } from "configs/consts";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setOrder } from "store/userSettingsSlice";
import AddressDetails from "components/Orders/preOrder/AddressDetails";
import EditIcon from '@mui/icons-material/Edit';

const OrderTypeChange = () => {
    const dispatch = useDispatch()
    const { t } = useTranslation();
    const bizData = useSelector(state => state.bizData);
    const order = useSelector(state => state.userSettings.order);
    const selectedLang = useSelector(state => state.userSettings.selectedLang);
    const [selected, setSelected] = useState(order?.typeId)
    const [openAddr, setOpenAddr] = useState(false)

    const onClick = (type) => {
        setSelected(type)
        if (type === ORDER_TYPE_ID.PICKUP) {
            dispatch(setOrder({
                // address: {
                //     addrNote: order?.address?.addrNote,
                //     isBiz: order?.address?.isBiz,
                //     apt: order?.address?.apt,
                //     placeId: order?.address?.placeId,
                //     entry: order?.address?.entry,
                //     floor: order?.address?.floor,
                //     fullAddr: order?.address?.fullAddr,
                //     sessiontoken: order?.address?.sessiontoken,
                //     streetNumber: order?.address?.streetNumber,
                // },
                address: { ...order?.address },
                typeId: ORDER_TYPE_ID.PICKUP,
            }))
        }
        else if (type === ORDER_TYPE_ID.DELIVERY) {
            setOpenAddr(true)
        }
    }

    const orderTypeChange = (orderTypeId) => {
        dispatch(setOrder({
            typeId: orderTypeId,
        }))
    }

    const onCloseDialog = () => {
        if ((order?.typeId === ORDER_TYPE_ID.PICKUP)) {
            dispatch(setOrder({
                // address: {
                //     addrNote: order?.address?.addrNote,
                //     isBiz: order?.address?.isBiz,
                //     apt: order?.address?.apt,
                //     placeId: order?.address?.placeId,
                //     entry: order?.address?.entry,
                //     floor: order?.address?.floor,
                //     fullAddr: order?.address?.fullAddr,
                //     sessiontoken: order?.address?.sessiontoken,
                //     streetNumber: order?.address?.streetNumber,
                // },
                address: { ...order?.address },
                typeId: ORDER_TYPE_ID.PICKUP,
            }))
            setSelected(ORDER_TYPE_ID.PICKUP)
        }
        setOpenAddr(false)
    }

    return (
        bizData.hasOrderMenu &&
            order.typeId &&
            (bizData?.orderSettings?.hasDelivery && bizData?.orderSettings?.hasTakeAway) &&
            !bizData?.isTable ?
            <>
                <Stack direction='row' gap={1}>
                    <Button
                        size="small"
                        variant={selected === ORDER_TYPE_ID.PICKUP ? 'contained' : 'outlined'}
                        onClick={() => onClick(ORDER_TYPE_ID.PICKUP)}>
                        {`${t('TAKE_AWAY')}
                          ${selected === ORDER_TYPE_ID.PICKUP ? `- ${selectedLang?.addr}` : ''}`}
                    </Button>

                    <Button
                        size="small"
                        variant={selected === ORDER_TYPE_ID.DELIVERY ? 'contained' : 'outlined'}
                        onClick={() => onClick(ORDER_TYPE_ID.DELIVERY)}>
                        <Stack direction='row' justifyContent='center' gap={1}>
                            {order?.typeId === ORDER_TYPE_ID.DELIVERY
                                ? <EditIcon fontSize="small" />
                                : null
                            }
                            {order?.typeId === ORDER_TYPE_ID.DELIVERY
                                ? `${t('DELIVERY')} ${order?.address?.fullAddr ? `(${order?.address?.fullAddr.split(',')[0]})` : ''}`
                                // ? `${t('DELIVERY')} ${order?.address?.fullAddr ? `${t('DELIVER_TO', { adders: order?.address?.fullAddr.split(',')[0] })} ` : ''}`
                                : t('DELIVERY')
                            }
                        </Stack>
                    </Button>
                </Stack>

                <CustomModal
                    onClose={onCloseDialog}
                    open={openAddr}
                    title={"ADR_DETAILS"}
                    closeIcon>
                    <Stack width={300}>
                        <AddressDetails
                            setOpenAddr={setOpenAddr}
                            isOrderTypeChange={true} />
                    </Stack>
                </CustomModal>
            </>
            :
            bizData?.isTable && bizData.table.hasPickup && bizData.table.hasSitting ?
                <Stack direction='row' gap={1}>
                    <Button
                        size="small"
                        variant={order?.typeId === ORDER_TYPE_ID.PICKUP ? 'contained' : 'outlined'}
                        onClick={() => orderTypeChange(ORDER_TYPE_ID.PICKUP)}
                    >
                        <Typography variant="subtitle2">
                            {t('PICKUP')}
                        </Typography>

                    </Button>

                    <Button
                        size="small"
                        variant={order?.typeId === ORDER_TYPE_ID.SITTING ? 'contained' : 'outlined'}
                        onClick={() => orderTypeChange(ORDER_TYPE_ID.SITTING)}
                    >
                        <Typography variant="subtitle2">{t('SITTING')}</Typography>
                    </Button>
                </Stack>
                :
                null
    )
}
export default OrderTypeChange