import { Box, Chip, Stack, Typography } from "@mui/material";

const Badges = ({ product }) => {

    if (!product) {
        return
    }

    return (
        product?.badges?.length > 0
            ? <Stack
                direction='row'
                flexWrap='wrap'
                alignItems='center'
                gap={0}
                paddingY={1}
            >
                {product && product.badges?.map((badge, idx) => {
                    return (
                        <>
                            <Chip
                                key={idx}
                                variant="contained"
                                className="font-bold"
                                label={badge.name}
                                icon={
                                    <Box
                                        sx={[{
                                            '& svg': {
                                                fill: (theme) => theme.palette.clButtons.main,
                                            },
                                        },]}
                                        dangerouslySetInnerHTML={{ __html: badge.imgUrl }}
                                    />
                                }
                            />
                            {/* <Typography color='clButtons.main'>|</Typography> */}
                        </>
                    )
                })
                }
            </Stack>
            : null
    )
}

export default Badges;