import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import CustomModal from 'components/utils/CustomModal';
import axios from 'axiosConn';
import { useDispatch, useSelector } from 'react-redux';
import { setCoupon } from 'store/salesSlice';
import moment from 'moment';
import { timeFormat } from 'configs/settingsConfig';
import {
    Box,
    Button,
    Divider,
    Icon,
    ListItemButton,
    Stack,
    TextField,
    Typography,
    useMediaQuery
} from '@mui/material';
import useCurrency from 'hooks/useCurrency';
import { useTheme } from '@emotion/react';

const Item = ({ title, value, icon }) => {
    const { t } = useTranslation();
    const coupon = useSelector(state => state.sales.coupon);

    return (
        <Stack
            gap={1}
            direction='row'
            color={coupon ? 'clButtons.main' : 'primary.main'}>

            <Icon>{icon}</Icon>
            <Typography>
                {`${t(title)} ${value ? `: ${value}` : ''}`}
            </Typography>
        </Stack>
    )
}

const Coupon = () => {
    const theme = useTheme()
    const dispatch = useDispatch()
    const { t } = useTranslation();
    const { currency } = useCurrency();
    const xl = useMediaQuery(theme.breakpoints.up('lg'));
    const bizData = useSelector(state => state.bizData);
    const coupon = useSelector(state => state.sales.coupon);
    const selectedLang = useSelector(state => state.userSettings.selectedLang);
    const [open, setOpen] = useState(false)
    const [code, setCode] = useState(null)
    const [errAddrMsg, setErrAddrMsg] = useState(null);
    const [timerEndCoupon, setTimerEndCoupon] = useState('')
    const [openEndCoipon, setOpenEndCoipon] = useState(false)

    /** Timer For End Coupon */
    useEffect(() => {
        if (coupon) {
            const toExactMinute = 60000 - (new Date().getTime() % 60000);
            const tillDateCoupon = moment(coupon.tillDate)
            const timeOpenMsg = moment(coupon.tillDate).subtract(20, "minutes")
            const newTime = moment(new Date())

            if (moment(newTime).isBefore(timeOpenMsg)) {
                return
            }

            const timerEndCoupon = setInterval(() => {
                if (moment(newTime).isSameOrAfter(tillDateCoupon)) {
                    setOpenEndCoipon(true)
                    dispatch(setCoupon(null));
                }
                else {
                    setTimerEndCoupon(`${moment(tillDateCoupon).format(timeFormat)}`)
                }

            }, toExactMinute)

            return () => clearInterval(timerEndCoupon);
        }
    }, [coupon]);

    useEffect(() => {
        if (errAddrMsg) {
            const timer = setTimeout(() => {
                setErrAddrMsg(null)
            }, 2000);
            return () => clearTimeout(timer);
        }
    }, [errAddrMsg])

    useEffect(() => {
        setCode(null)
    }, [open]);

    const checkCode = () => {
        if (code) {
            axios.get(`CheckSale/${bizData.id}/${selectedLang?.id}/${code}`)
                .then(res => {
                    if (res?.data?.id === 1) {
                        dispatch(setCoupon({
                            id: res?.data?.data?.id,
                            name: res?.data?.data?.name,
                            tillDate: res?.data?.data?.tillDate,
                            code: code,
                            minOrderPrice: res?.data?.data?.minOrderPrice,
                            discountPercent: res?.data?.data?.discountPercent,
                            discountPrice: res?.data?.data?.discountPrice
                        }));
                        setOpen(false)
                    }
                    else if (res?.data?.id === -404)
                        setErrAddrMsg(t('COUPON_NOT_FOUND'))
                })
        }
    }

    return (
        bizData?.hasSales &&
        <>
            <Divider sx={{ marginY: 1, bgcolor: (theme) => theme.palette.primary.main }} />

            <ListItemButton
                onClick={() => coupon ? null : setOpen(true)}
                disableGutters >
                <Stack
                    width='100%'
                    direction='row'
                    justifyContent='space-between'>

                    <Stack gap={1} direction='row'>

                        {coupon
                            ? <Stack spacing={1}>

                                <Item
                                    title={"COUPON"}
                                    value={coupon?.name}
                                    icon={"receipt"} />

                                {coupon?.discountPercent || coupon?.discountPrice
                                    ? <Item
                                        title={"DISCOUNT"}
                                        icon={"trending_down"}
                                        value={coupon.discountPercent
                                            ? `${coupon.discountPercent}%`
                                            : `${currency(coupon?.discountPrice)}`
                                        } />

                                    : null
                                }


                                {timerEndCoupon
                                    ? <Item
                                        title={"TIME_END"}
                                        value={timerEndCoupon}
                                        icon={"access_alarms"} />
                                    : null
                                }

                            </Stack>

                            : <Item
                                title={"HAVE_COUPON"}
                                icon={"receipt"} />
                        }
                    </Stack>

                    {coupon
                        ? <Button
                            sx={{ maxHeight: 30 }}
                            size='small'
                            onClick={() => dispatch(setCoupon(null))}
                            variant='contained'>
                            {t('DELETE_COUPON')}
                        </Button>
                        : <Button size='small' variant='contained'>
                            {t('CLICK_HERE')}
                        </Button>
                    }
                </Stack>
            </ListItemButton>

            {/* Error Copuon */}
            <CustomModal
                open={open}
                onClose={() => { setOpen(false) }}
                closeIcon
                title={t('ENTER_COUPON')}
                actionBtn={{ title: t('ADD_COUPON'), action: () => checkCode() }}
            >

                <TextField
                    sx={{ marginTop: 3, paddingBottom: errAddrMsg ? 0 : 3 }}
                    onChange={(e) => setCode(e.target.value)}
                    value={code}
                    label={t('COUPON')}
                />
                {errAddrMsg &&
                    <Typography
                        color='error'>
                        {errAddrMsg}
                    </Typography>
                }

            </CustomModal>

            {openEndCoipon &&
                <CustomModal
                    open={openEndCoipon}
                    onClose={() => setOpenEndCoipon(false)}
                    focusOnCloseButton
                    closeBtn={"ACCEPT"}
                >
                    <Box className="flex flex-col justify-center items-center" sx={{ minWidth: 220 }} >
                        <Typography variant="h6" color="primary">
                            {t('THE_COUPON_FINISH_TITILE')}
                        </Typography>
                        <Typography variant="subtitle1" color="primary">
                            {t('THE_COUPON_FINISH')}
                        </Typography>
                    </Box>
                </CustomModal>
            }
        </>
    )
}

export default Coupon;