import ModifierButton from './ModifierButton';
import ModifierCounter from './ModifierCounter';

const Modifier = (props) => {
    return (
        <>
            {
                props.type === 'COUNTER' &&
                <ModifierCounter {...props} />
            }
            {
                ((props.type === 'CHECKBOX') || (props.type === 'RADIO')) &&
                <ModifierButton {...props} />
            }
        </>
    )
}
export default Modifier