import { createSlice } from '@reduxjs/toolkit'

// cartItem base object for adding products to cart
const cartItem = {
    guid: null,
    name: null,
    price: 0,
    // totalPrice includs selected modifiers prices and qty (quantity)                    
    totalPrice: 0,
    id: null,
    catId: null,
    qty: 1,
    modifierGroups: [],
    isSimple: false,
    imgUrl: null,
}

const initialCart = {
    cartTotalPrice: 0,
    openedProduct: {},
    openCat: null,
    clientDetails: [],
    productsInBasket: [],
    productToEdit: {},

    cartItems: [],
    tempCartItem: cartItem,
    history: [],
}

const calcCartTotalPrice = (cartItems) => {
    let sum = 0;
    cartItems.forEach((ci) => sum = sum + ci.totalPrice);
    return sum;
}

const calcTempCartItemPrice = (ci) => {
    let sum = 0;
    sum = ci.price;
    ci.modifierGroups.forEach((g) => {
        g.modifiers.forEach((m) => {
            if (m.qty > m.freeQty) {
                sum = sum + m.pr * (m.qty - m.freeQty);
            }
        })
    })
    sum = sum * ci.qty;
    return sum;
}

export const cartSlice = createSlice({
    name: 'costumerData',
    initialState: initialCart,
    reducers: {
        setOpenedProduct: (state, payload) => {
            state = { ...state, openedProduct: payload.payload }
            return state
        },
        setOpenedProductGroup: (state, action) => {
            /** Setting the group touched property for live validation on each modifier click */
            const idx = state.openedProduct.modifierGroups.findIndex((mg) => mg.id === action.payload.id);
            let updatedItems = [...state.openedProduct.modifierGroups];
            updatedItems.splice(idx, 1,
                {
                    ...state.openedProduct.modifierGroups[idx],
                    touched: action.payload.touched
                });
            state.openedProduct.modifierGroups = [...updatedItems];
            return state
        },

        setOpenCat: (state, payload) => {
            state = { ...state, openCat: payload.payload }
            return state
        },

        setClientDetails: (state, payload) => {
            state.clientDetails = [...state.clientDetails, payload.payload]
        },

        setHistory: (state, action) => {
            state.history = action.payload;
            return state;
        },
        setCartItems: (state, action) => {
            return {
                ...state,
                cartItems: action.payload,
                cartTotalPrice: calcCartTotalPrice(action.payload),
            }
        },
        setTempCartItem: (state, action) => {
            return {
                ...state,
                tempCartItem: {
                    ...action.payload,
                    totalPrice: calcTempCartItemPrice(action.payload)
                },
            }
        },
        updateTempCartItemQty: (state, action) => {
            let outTempCartItem = state.tempCartItem;
            outTempCartItem = { ...outTempCartItem, qty: outTempCartItem.qty + action.payload }
            return {
                ...state,
                tempCartItem: {
                    ...outTempCartItem,
                    totalPrice: calcTempCartItemPrice(outTempCartItem)
                },
            }
        },
        updateTempCartItemGroup: (state, action) => {
            return {
                ...state,
                tempCartItem: action.payload
            }
        },

        addCartItem: (state, action) => {
            /** 
             * isSimple - product has no modifiers nor badges. 
             * therefor, we can update only the quantity (qty) 
             * and totalPrice if the product exists in cart
             * */
            const idx = state.cartItems.findIndex((ci) => ci.id === action.payload.id);
            if (action.payload.isSimple && idx > -1) {
                let updatedItems = [...state.cartItems];
                updatedItems.splice(idx, 1,
                    {
                        //...action.payload,
                        ...state.cartItems[idx],
                        qty: state.cartItems[idx].qty + 1,
                        totalPrice: (state.cartItems[idx].qty + 1) * state.cartItems[idx].price
                    });
                state.cartItems = [...updatedItems];
            }
            else {
                /** Adding a new cart item */
                const guid = Math.floor(Date.now());
                const newCartItem = { ...action.payload, guid: guid };
                state.cartItems = [...state.cartItems, newCartItem];
            }
            /** Updateing cart total price */
            state.cartTotalPrice = calcCartTotalPrice(state.cartItems);
            /** Reseting tempCartItem */
            state.tempCartItem = cartItem;
            return state
        },
        removeCartItem: (state, action) => {
            const newCartItems = state.cartItems.filter((cartItem) => cartItem.guid !== action.payload.guid);
            state.cartTotalPrice = state.cartTotalPrice - action.payload.totalPrice;
            state.cartItems = newCartItems;
            return state;
        },
        updateCartItem: (state, action) => {
            /** Updating existing cart item */
            const updatedCartItem = { ...action.payload, totalPrice: calcTempCartItemPrice(action.payload) }
            const idx = state.cartItems.findIndex((ci) => ci.guid === action.payload.guid);
            let updatedItems = [...state.cartItems];
            updatedItems.splice(idx, 1, updatedCartItem);
            state.cartItems = [...updatedItems];
            state.cartTotalPrice = calcCartTotalPrice(state.cartItems);
            /** Reseting tempCartItem */
            state.tempCartItem = cartItem;
            return state
        },
        /** Setting the selected cart item for edit - NOT the actual update of the cart item */
        editCartItem: (state, action) => {
            state = {
                ...state,
                //openedProduct: action.payload.product,
                tempCartItem: action.payload.cartItem,
            }
            return state;
        },
        resetTempCartItem: (state) => {
            state.tempCartItem = cartItem;
            state.openedProduct = {};
            return state;
        },
        clearCart: (state, action) => {
            state.cartItems = [];
            state.tempCartItem = cartItem;
            state.cartTotalPrice = 0;
            return state;
        },
        resetCart: (state, action) => {
            state = initialCart;
            return state;
        }
    },
})

export const {
    setOpenedProduct,
    setOpenedProductGroup,
    setOpenCat,
    setDefaultValues,

    // new added actions to replace old code
    setHistory,

    addCartItem,
    removeCartItem,
    updateCartItem,
    //copyCartItem,

    setTempCartItem,
    updateTempCartItemQty,
    updateTempCartItemGroup,
    editCartItem,
    resetTempCartItem,
    clearCart,
    resetCart,
    setCartItems,

    setClientDetails,
    changeProductsInBasket,
} = cartSlice.actions


// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched

// export const incrementAsync = (amount) => (dispatch) => {
//   setTimeout(() => {
//     dispatch(incrementByAmount(amount))
//   }, 1000)
// }

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
// export const bizData = (state) => state.bizData.value

export default cartSlice.reducer
