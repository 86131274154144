import React, { useEffect } from 'react';
import Loading from 'components/utils/Loading';
import { useParams } from "react-router-dom";
import { clearCart } from 'store/cartSlice';
import { useDispatch } from 'react-redux';
import { setCart, setOrder } from 'store/userSettingsSlice';

const CcPaymentSuccess = () => {
    const { orderGuid } = useParams();
    const dispatch = useDispatch();
    useEffect(() => {
        if (!orderGuid)
            return;

        dispatch(setOrder());
        dispatch(setCart(null));
        window.parent.postMessage({ orderGuid });
    }, [orderGuid]);

    return (
        <div className='w-full flex justify-center h-screen items-center'>
            <Loading />
        </div>
    )
}
export default CcPaymentSuccess;