import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import settingsConfig, { MOLO_PRIMARY_COLOR } from "configs/settingsConfig";
import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { ORDER_WORKFLOW_STATUS_ID } from "configs/consts";

const signalR = require("@microsoft/signalr");

const arrowBoxPosition = {
  First: 0,
  Middle: 1,
  Last: 2,
}

const ArrowBox = ({
  position,
  zIndex,
  bgColor,
  // isSelected, 
  children
}) => {
  const selectedLang = useSelector(state => state.userSettings.selectedLang);

  return (
    <Box
      className="flex w-full items-center justify-center text-white"
      sx={{
        zIndex: { zIndex },
        padding: `0 10px 0 ${position === arrowBoxPosition.First ? '10px' : '27px'}`,
        lineHeight: '1rem',
        height: "40px",
        position: "relative",
        background: bgColor,
        ":after": position === arrowBoxPosition.Middle || position === arrowBoxPosition.Last
          ? {
            content: "\"\"",
            position: "absolute",
            left: selectedLang.isRtl ? '0' : null,
            right: selectedLang.isRtl ? null : '0',
            bottom: "0",
            width: "0",
            height: "0",
            borderLeft: selectedLang.isRtl ? '20px solid #d1d5db' : null,
            borderRight: selectedLang.isRtl ? null : '20px solid #d1d5db',
            borderTop: "20px solid transparent",
            borderBottom: "20px solid transparent"
          }
          : {},
        ":before": position === arrowBoxPosition.Middle || position === arrowBoxPosition.First
          ? {
            content: "\"\"",
            position: "absolute",
            right: selectedLang.isRtl ? '-20px' : null,
            left: selectedLang.isRtl ? null : '-20px',
            bottom: "0",
            width: "0",
            height: "0",
            borderLeft: selectedLang.isRtl ? `20px solid ${bgColor}` : null,
            borderRight: selectedLang.isRtl ? null : `20px solid ${bgColor}`,
            borderTop: "20px solid transparent",
            borderBottom: "20px solid transparent"
          }
          : {}
      }}>
      <Typography
        fontSize={{ xs: 12, md: 14 }}
        textAlign={selectedLang.isRtl ? 'start' : 'end'}>
        {children}
      </Typography>
    </Box>)
}

const OrderStausTracker = ({ orderGuid, typeId }) => {
  const { t } = useTranslation()
  const [hubConn, setHubConn] = useState(null);
  const [satusId, setSatusId] = useState(null);

  const status = [
    { id: ORDER_WORKFLOW_STATUS_ID.CREATED, name: t('WAITING') },
    { id: ORDER_WORKFLOW_STATUS_ID.COOKING, name: t('IN_PREPARATION') },
    { id: ORDER_WORKFLOW_STATUS_ID.READY, name: t('PREPARED') },
    { id: ORDER_WORKFLOW_STATUS_ID.DELIVERED, name: t(typeId === 2 ? 'SENT' : 'COLLECTED') }
  ]

  useEffect(() => {
    createHub();
    return () => closeConn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeConn = async () => {
    if (hubConn)
      hubConn.close();
    setHubConn(null);
  }

  const createHub = async () => {
    try {
      const conn = new signalR.HubConnectionBuilder()
        // .configureLogging(signalR.LogLevel.None)
        // .withUrl(hubUrl)
        .withUrl(settingsConfig.apiUrl + "orderSumMenuHub")
        .withAutomaticReconnect()
        .build();

      setHubConn(conn);

      await conn.start();

      conn?.on("menuOrderSetWfSt", async (stId) => {
        setSatusId(stId)
      });

      conn?.send('subscribe', orderGuid)
    }
    catch (e) {
      console.error('SignalR Hub Connections Error', e);
    }
  }

  return (
    <div className="flex flex-col items-center justify-center w-full bg-gray-200 p-2 gap-2">
      <Typography
        className='font-bold text-xl'
        color={MOLO_PRIMARY_COLOR}
      >
        {t('ORDER_STAUS_TRACKER_TITLE')}
      </Typography>

      <div className="flex w-full justify-center items-center gap-1">
        {status?.map((st, idx) => {
          return (
            <ArrowBox
              key={st.id}
              isSelected={st.id === satusId}
              bgColor={
                st.id === satusId
                  ? MOLO_PRIMARY_COLOR
                  : '#999'
              }
              zIndex={status.length - idx}
              position={
                idx === 0
                  ? arrowBoxPosition.First
                  : idx === status.length - 1
                    ? arrowBoxPosition.Last
                    : arrowBoxPosition.Middle
              }
            >
              {st.name}
            </ArrowBox>
          )
        })}
      </div>
    </div>
  )
};

export default OrderStausTracker;