import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setOrder } from 'store/userSettingsSlice';
import RoundedBtn from 'components/RoundedBtn';
import { ReactComponent as OrderNowIcon } from 'assets/icon-order-now.svg';
import { ReactComponent as OrderLaterIcon } from 'assets/icon-order-later.svg';
import StepHeader from '../StepHeader';
import { STEPS } from '..';
import { Box, Fade, Slide, Zoom } from '@mui/material';

const Index = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const order = useSelector(state => state.userSettings.order);

    return (
        <>
            <StepHeader
                title={t('TIMING_ORDER')}
                backBtnClick={() => dispatch(setOrder({ step: STEPS.ORDER_TYPE }))} />

            <Fade timeout={700} in={true}>
                <Box
                    className='flex flex-col sm:flex-row gap-8 w-full items-center justify-center p-8'
                    sx={{ backgroundColor: 'secondary.main' }}
                >
                    <RoundedBtn
                        title={t("HUNGRY_NOW")}
                        size="xxl"
                        component={OrderNowIcon}
                        onClick={() => {
                            dispatch(setOrder({
                                step: STEPS.MENU_LIST,
                                future: null,
                            }))
                        }}
                    />

                    <RoundedBtn
                        title={t("LATER")}
                        size="xxl"
                        color="primary"
                        component={OrderLaterIcon}
                        onClick={() => {
                            dispatch(setOrder({
                                step: STEPS.FUTURE_ORDER,
                            }))
                        }}
                    />
                </Box>
            </Fade>
        </>
    )
}

export default Index;